import React, { useMemo, useEffect, useState } from "react";
import Card from "components/card";
import { useNavigation, useNavigate } from "react-router-dom";
import { columnsClientData } from "../variables/columnsData";
import { clientdata } from "../variables/clientData";
import ClientCard from "./ClientCard";
// import CardMenu from "./CardMenu";
import Pagination from "components/pagination"; 
import RegisterClient from "./RegisterClient";
import ClientDetails from "./ClientDetails";
import { ApiManager } from "app/ApiManager";



const ClientPage = () => {
  const columns = useMemo(() => columnsClientData, [columnsClientData]);
  const data = useMemo(() => clientdata, [clientdata]);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate(); 
  const [clients, setClients] = useState([]);
  
  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    navigate('/admin/client-details', { state: rowData });
  };

  useEffect(() => {
    const fetchClients = async () => {
      const response = await ApiManager.get(`/Client/GetByBusinessId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setClients(response.data);
    }
    fetchClients();
  }, []);

  

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700">{data.name}</div>
          <ClientCard /> 
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table
            className="w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
                <tr >
                  <th className="border-b border-gray-200 pr-16 pb-[10px] text-start py-4">
                    <div
                      className="text-xs font-medium tracking-wide text-gray-800">
                      NR.
                    </div>      
                  </th>
                  {columns.map((column, index) => (
                  <th
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start py-4"
                    >
                    <div className="text-xs font-medium tracking-wide text-gray-800">
                      {column.Header}
                    </div>
                  </th>
                ))}
                                     
                </tr>
            </thead>
            <tbody >
                {clients && clients.length >= 1 ? clients.map((client, index) => (
                <tr 
                key={index} 
                onClick={() => handleRowClick(client)}
                className="cursor-pointer hover:bg-blue-200">  
                  <td className="border-b border-gray-200 pr-16 pb-[10px] text-start py-4">
                    <div className="text-xs font-medium tracking-wide text-gray-800 font-bold">
                      {++index}
                    </div>
                  </td>

                  <td className="border-b border-gray-200 pr-16 pb-[10px] text-start">
                    <div className="text-xs font-medium tracking-wide text-gray-800 font-bold">
                      {client.name}
                    </div>
                  </td>

                  <td className="border-b border-gray-200 pr-16 pb-[10px] text-start">
                    <div className="text-xs font-medium tracking-wide text-gray-800 font-bold">
                      {client.address}
                    </div>
                  </td>

                  <td className="border-b border-gray-200 pr-16 pb-[10px] text-start">
                    <div className="text-xs font-medium tracking-wide text-gray-800 font-bold">
                      {client.phoneNumber}
                    </div>
                  </td>

                  <td className="border-b border-gray-200 pr-16 pb-[10px] text-start">
                    <div className="text-xs font-medium tracking-wide text-gray-800 font-bold">
                      {client.email}
                    </div>
                  </td>

                  <td className="border-b border-gray-200 pr-16 pb-[10px] text-start">
                    <div className="text-xs font-medium tracking-wide text-gray-800 font-bold">
                      {client.accountName}
                    </div>
                  </td>

                  <td className="border-b border-gray-200 pr-16 pb-[10px] text-start">
                    <div className="text-xs font-medium tracking-wide text-gray-800 font-bold">
                      {client.accountNumber}
                    </div>
                  </td>
                  
                </tr>
                )) : (
                  <tr>
                    <td colSpan="7" className="font-bold text-center">Nuk ka të dhëna</td>
                  </tr>
                )}                                     
                      

            </tbody>
          </table>
        </div>
        {/* <Pagination
          currentPage={pageIndex + 1}
          totalPages={pageCount}
          handlePrevious={() => previousPage()}
          handleNext={() => nextPage()}
          handlePageClick={(page) => gotoPage(page - 1)}
          canPrevious={canPreviousPage}
          canNext={canNextPage}
        /> */}
      </Card>
      {/* {selectedRow && <ClientDetails client={selectedRow} />} */}
    </>
  );
};

export default ClientPage;
