import React, { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import Card from "components/card";
import ErrorLabel from "components/error/ErrorLabel";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import { useNavigate, useLocation } from "react-router-dom";
import { validate } from "./variables/ValidateForm";
import { jobPositionOptions } from "./variables/Options";
import SelectList from "../menu/components/SelectList";
import SpinnerButton from "components/buttons/SpinnerButton";
import Swal from "sweetalert2";

const UpsertEmployee = () => {
  const navigation = useNavigate();
  const [selectedJobPosition, setSelectedJobPosition] = useState(
    jobPositionOptions[0]
  );
  const [headerText, setHeaderText] = useState("Regjistrim");
  const location = useLocation();
  const [employee, setEmployee] = useState({});
  const [employeeId, setEmployeeId] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);



  const handleButtonClick = (isEdit) => {
    setIsModalOpen(true);
    Swal.fire({
      icon: "success",
      title: isEdit ? "Stafi u përditësua me sukses!" : "Stafi u shtua me sukses!",
      timer: 2000,
      showConfirmButton: false
    }).then((result) => {
        navigation("/admin/employee-list");
    });
  };

  useEffect(() => {
    if (location.state) {
      setEmployeeId(location.state.employeeId);
      if (location.state.employeeId) {
        setHeaderText("Përditësim");
        let endpoint = `/User/EmployeeById/${location.state.employeeId}`;

        if (endpoint) {
          const fetchData = async () => {
            try {
              const response = await ApiManager.get(endpoint);
              setEmployee(response.data);
              setSelectedJobPosition({name: response.data.jobPosition});
            } catch (error) {
              console.log(error);
            }
          };
          fetchData();
        }
      } else {
        setHeaderText("Regjistrim");
      }
    }
  }, [location.state]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      jobPosition: selectedJobPosition.name,
      id: "",
    },
    validate: (values) => validate(values),
    onSubmit: (values) => {
      if (isCanceled) {
        setIsCanceled(false); 
        return;
      }
      setIsWaiting(true);
      const jwtToken = localStorage.getItem("token");
      values.jobPosition = selectedJobPosition.name;
      let endpoint;
      if (employeeId !== 0) {
        endpoint = "/User/EditEmployee";
      } else {
        endpoint = "/User/UpsertEmployee";
      }
      ApiManager.post(endpoint, values, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          jwtToken: jwtToken,
        },
      })
        .then((response) => {
          setIsWaiting(false);
          handleButtonClick(employeeId !== 0);
        })
        .catch((error) => {
          setIsWaiting(false);
        });
    },
  });

  const handleAnulo = () =>{
    setIsCanceled(true);
    navigation("/admin/employee-list");
  }

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      firstName: employee?.firstName || "",
      lastName: employee?.lastName || "",
      email: employee?.email || "",
      phoneNumber: employee?.phoneNumber || "",
      jobPosition: selectedJobPosition?.name || "",
      id: employee?.id || "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const { touched, errors, handleSubmit } = formik;
  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <h3 className=" my-2 mb-4 text-xl font-bold text-navy-700">
          {headerText} i punëtorit
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="md:flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Emri*"
                id="firstName"
                name="firstName"
                type="text"
                {...formik.getFieldProps("firstName")}
                state={errors.firstName && touched.firstName ? "error" : null}
              />
              {errors.firstName && touched.firstName ? (
                <ErrorLabel error={errors.firstName} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Mbiemri*"
                type="text"
                id="lastName"
                name="lastName"
                {...formik.getFieldProps("lastName")}
                state={errors.lastName && touched.lastName ? "error" : null}
              />
              {errors.lastName && touched.lastName ? (
                <ErrorLabel error={errors.lastName} />
              ) : null}
            </div>
          </div>
          <div className="w-full mb-3">
            <SelectList
              label="Pozita e punës"
              selected={selectedJobPosition}
              setSelected={setSelectedJobPosition}
              options={jobPositionOptions}
            />
          </div>
          <div className="md:flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Email*"
                type="email"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
                state={errors.email && touched.email ? "error" : null}
              />
              {errors.email && touched.email ? (
                <ErrorLabel error={errors.email} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Numri i telefonit"
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                {...formik.getFieldProps("phoneNumber")}
              />
            </div>
          </div>
          <div className="md:flex justify-around">
          <SpinnerButton title={"Ruaj të dhënat"} isWaiting={isWaiting}/>
            <SpinnerButton title={"Anulo të dhënat"} isWaiting={isWaiting} onClick={handleAnulo}/>
          </div>
        </form>
      </Card>
    </>
  );
};

export default UpsertEmployee;
