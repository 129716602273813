import React, { useState, useEffect } from "react";
import Card from "components/card";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiManager } from "app/ApiManager";
import { MdOutlineEdit } from "react-icons/md";

const AmbientDetails = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [ambient, setAmbient] = useState({});
  const [ambientId, setAmbientId] = useState(0);

  useEffect(() => {
    if (location.state) {
      setAmbientId(location.state.ambientId);
      if (location.state.ambientId) {
        ApiManager.get(`/Ambient/GetAmbientById/${location.state.ambientId}`)
          .then((response) => {
            setAmbient(response.data);
          })
          .catch((error) => {
            console.error("Error fetching ambient:", error);
          });
      } 
    }
    else{
      console.error("No state found");
    }
    
  }, [location.state]);

  const handleEdit = () => {
    navigation("/admin/upsert-enviroment", {
      state: {
        ambientId: ambientId,
      },
    });
  };

  return (
    <>
      <Card extra={"w-full h-full p-3 mt-5"}>
        <div className="mt-2 mb-8 flex w-full justify-between">
          <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
            {ambient.name}
          </h4>

          <button
            onClick={handleEdit}
            className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
            bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
            hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-600 dark:text-white dark:hover:bg-brand-600 dark:active:bg-white/10"
          >
            Përditëso
            <MdOutlineEdit className="h-5 w-5" />
          </button>
        </div>
        <div className="grid grid-cols-2 gap-4 px-2">
          {/* Details of the ambient can be listed here similarly to EmployeeDetails */}
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p className="text-sm text-gray-600">Emri i Ambientit</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {ambient.name}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p className="text-sm text-gray-600">Përshkrimi</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {ambient.description || "Nuk është dhënë"}
            </p>
          </div>
          {/* Additional ambient details can be added here */}
        </div>
      </Card>
    </>
  );
};

export default AmbientDetails;
