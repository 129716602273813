import React, { useState, useEffect,useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorLabel from "components/error/ErrorLabel";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { validate } from "./variables/ValidateOffer";
import Multiselect from "./components/MultiSelect";
import formatDate from "./variables/FormatDate";
import SpinnerButton from "components/buttons/SpinnerButton";
import Swal from "sweetalert2";
import Cropper from "react-easy-crop";
import getCroppedImg from "../stock/variables/cropImage";

const UpsertOffer = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { offerId } = location.state || "";
  const [headerText, setHeaderText] = useState("");
  const [offerItem, setOfferItem] = useState({});
  const [file, setFile] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  // FOODS
  const [foods, setFoods] = useState();
  const [selectedFoods, setSelectedFoods] = useState([]);
  const [prevSelectedFoods, setPrevSelectedFoods] = useState([]);
  // DRINKS
  const [drinks, setDrinks] = useState();
  const [prevSelectedDrinks, setPrevSelectedDrinks] = useState([]);
  const [selectedDrinks, setSelectedDrinks] = useState([]);
  // SWEETS
  const [desserts, setDesserts] = useState();
  const [selectedDesserts, setSelectedDesserts] = useState([]);
  const [prevSelectedSweets, setPrevSelectedSweets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage]= useState("");
  const [showCroppedImage, setShowCroppedImage] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const handleButtonClick = (isEdit) => {
    setIsModalOpen(true);
    Swal.fire({
      icon: "success",
      title: "Sukses",
      text: isEdit ? "Oferta u përditësua me sukses!" : "Oferta u shtua me sukses!",
      showConfirmButton: false,
      timer: 2500,
    }).then((result) => {
        navigation("/admin/offers-list");
    });
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    // Set the image preview URL for newly selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    if (selectedFile) {
      setIsOpen(true);
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    if (offerId) {
      setHeaderText("Përditësim");
      ApiManager.get(`/Offer/GetOffer/${offerId}?requireImg=true`)
        .then((response) => {
          response.data.offerStart = formatDate(response.data.offerStart);
          response.data.offerEnd = formatDate(response.data.offerEnd);
          setOfferItem(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setHeaderText("Regjistrim");
    }

    var jwtToken = localStorage.getItem("token");
    // FOODS
    ApiManager.get(`/Food/GetFoodByBusinessId`, {
      params: {
        jwtToken: jwtToken,
      },
    }).then((response) => {
      setFoods(response.data);
    });
    // DRINKS
    ApiManager.get(`/Drink/GetDrinksByBusinessId`, {
      params: {
        jwtToken: jwtToken,
      },
    }).then((response) => {
      setDrinks(response.data);
    });
    // DESSERTS
    ApiManager.get(`/Dessert/GetDessertsByBusinessId`, {
      params: {
        jwtToken: jwtToken,
      },
    }).then((response) => {
      setDesserts(response.data);
    });
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = useCallback(async () => {

    try {
      const croppedImage = await getCroppedImg(imagePreviewUrl, croppedAreaPixels);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result; // Access result inside onloadend
        // Update state with cropped image data
        setCroppedImage(base64data);
        setIsOpen(false);
      };
      reader.readAsDataURL(croppedImage); // Start reading cropped image as data URL
    } catch (e) {
      console.error(e);
    }
  }, [imagePreviewUrl, croppedAreaPixels]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: "",
      offerStart: "",
      offerEnd: "",
      offerId: 0,
    },
    validate,
    onSubmit: async (values) => {
      setIsWaiting(true); // Set isWaiting to true when the form is submitted
      try {
        console.log(values);
        const jwtToken = localStorage.getItem("token");

        const formData = new FormData();
        
        if (croppedImage) {
          formData.append("base64Image", croppedImage);
          formData.append("fileName", file.name);
        }
        // if (file) {
        //   formData.append("ImageFile", file);
        //   formData.append("fileName", file.name);
        // }
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });

        // Append food, drinks, desserts, selection to formData
        formData.append("FoodsInJSON", JSON.stringify(selectedFoods));
        formData.append("DrinksInJSON", JSON.stringify(selectedDrinks));
        formData.append("DessertsInJSON", JSON.stringify(selectedDesserts));

        let endpoint;
        if (offerId) {
          endpoint = "/Offer/EditOffer";
        } else {
          endpoint = "/Offer/CreateOffer";
        }

        await ApiManager.post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            jwtToken: jwtToken,
          },
        });

        setIsWaiting(false); // Set isWaiting to false after successful submission
        handleButtonClick(!!offerId);
      } catch (error) {
        console.error("Error occurred:", error);
        setIsWaiting(false); // Set isWaiting to false if an error occurs
      }
    },
  });
  
  const setPrevSelectedOptions = (items, idKey, setState) => {
    const selectedOptions = items
      ? items.map((item) => ({
          value: item[idKey],
          label: item.name,
        }))
      : [];
    setState(selectedOptions);
  };

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      name: offerItem.name || "",
      description: offerItem.description || "",
      price: offerItem.price || "",
      offerStart: offerItem.offerStart || "",
      offerEnd: offerItem.offerEnd || "",
      offerId: offerId || 0,
    }));

    setPrevSelectedOptions(offerItem.foods, "foodId", setPrevSelectedFoods);
    setPrevSelectedOptions(offerItem.drinks, "drinkId", setPrevSelectedDrinks);
    setPrevSelectedOptions(
      offerItem.desserts,
      "dessertId",
      setPrevSelectedSweets
    );
  }, [offerItem]);

  const handleAnulo = () =>{
    navigation("/admin/offers-list");
 }
  const { touched, errors, handleSubmit, handleBlur } = formik;

  return (
    <Card extra={"w-full sm:overflow-auto p-3 mt-5"}>
      <h3 className="mb-5 text-xl font-bold text-navy-700">
        {headerText} i ofertës
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col justify-between gap-x-3 md:flex-row">
          <div className="mb-3 w-full">
            <InputField
              variant="auth"
              extra="mb-1"
              label="Emërtimi*"
              id="name"
              name="name"
              type="text"
              {...formik.getFieldProps("name")}
              state={errors.name && touched.name ? "error" : null}
            />
            {errors.name && touched.name ? (
              <ErrorLabel error={errors.name} />
            ) : null}
          </div>
          <div className="mb-3 w-full">
            <InputField
              variant="auth"
              extra="mb-1"
              label="Çmimi* (EUR)"
              id="price"
              name="price"
              type="number"
              {...formik.getFieldProps("price")}
              state={errors.price && touched.price ? "error" : null}
            />
            {errors.price && touched.price ? (
              <ErrorLabel error={errors.price} />
            ) : null}
          </div>
        </div>

        <InputField
          variant="auth"
          extra="mb-1"
          label="Përshkrimi*"
          id="description"
          name="description"
          type="text"
          {...formik.getFieldProps("description")}
          state={errors.description && touched.description ? "error" : null}
        />
        {errors.description && touched.description ? (
          <ErrorLabel error={errors.description} />
        ) : null}
        <div className="mt-5 flex flex-col justify-between gap-4 md:flex-row">
          <div className="w-full items-center">
            <label className={`ml-1.5 text-sm text-navy-700 `}>Ushqimet</label>
            <Multiselect
              options={foods}
              setSelectedProducts={setSelectedFoods}
              productType="food"
              defaultValue={prevSelectedFoods}
            />
          </div>

          <div className="w-full items-center">
            <label className={`ml-1.5 text-sm text-navy-700 `}>Pijet</label>
            <Multiselect
              options={drinks}
              setSelectedProducts={setSelectedDrinks}
              productType="drink"
              defaultValue={prevSelectedDrinks}
            />
          </div>
          <div className="w-full items-center">
            <label className={`ml-1.5 text-sm text-navy-700 `}>
              Ëmbëlsirat
            </label>
            <Multiselect
              options={desserts}
              setSelectedProducts={setSelectedDesserts}
              productType="dessert"
              defaultValue={prevSelectedSweets}
            />
          </div>
        </div>
        <div className="mt-5 flex flex-col justify-between md:flex-row md:gap-4">
          <div className={`w-full`}>
            <InputField
              variant="auth"
              label="Data e fillimit të ofertës*"
              type="date"
              id="offerStart"
              name="offerStart"
              state={errors.offerStart && touched.offerStart ? "error" : null}
              {...formik.getFieldProps("offerStart")}
            />
            {errors.offerStart && touched.offerStart ? (
              <ErrorLabel error={errors.offerStart} />
            ) : null}
          </div>
          <div className={`w-full`}>
            <InputField
              variant="auth"
              label="Data e mbarimit të ofertës*"
              type="date"
              id="offerEnd"
              name="offerEnd"
              state={errors.offerEnd && touched.offerEnd ? "error" : null}
              {...formik.getFieldProps("offerEnd")}
            />
            {errors.offerEnd && touched.offerEnd ? (
              <ErrorLabel error={errors.offerEnd} />
            ) : null}
          </div>
        </div>
        <div className="mt-5 flex flex flex-col md:flex-row md:gap-4">
          <div className="mb-3 w-full">
            <InputField
              label="Foto e ofertës"
              type="file"
              name="file"
              id="file"
              onChange={handleFileUpload}
            />
          </div>
          <div className="flex w-full items-center justify-center">
                  {/* Show the Cropper component */}
                  {imagePreviewUrl && isOpen &&(
                    <div>
                   <Cropper
                    image={imagePreviewUrl}
                      crop={crop}
                      zoom={zoom}
                      aspect={4 / 3} // Set the aspect ratio as needed
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      style={{ zIndex: 1 }}
                    />
                    {file && imagePreviewUrl &&  (
                      <button type="button" onClick={handleCropSave} className="absolute z-10 border-2 rounded-lg bg-red-300 p-2">Save Cropped Image</button>
                    )}
                  </div>
                 )}
                </div>
          <div className="w-full flex justify-center items-center">
            {/* Show image preview if a new image is selected */}
            {file && (
              <img
                src={croppedImage}
                alt="Product Preview"
                className="h-8 w-8 cursor-pointer rounded-full object-cover object-center md:h-20 md:w-20"
              />
            )}
            {/* Show current image path if product is being edited */}
            {!file && offerItem?.base64Image && (
              <img
                src={`data:image/jpeg;base64,${offerItem.base64Image}`}
                alt="Current Product Image"
                className="h-8 w-8 cursor-pointer rounded-full object-cover object-center md:h-20 md:w-20"
              />
            )}
          </div>
        </div>
        <div className="flex justify-around">
            <SpinnerButton title={"Ruaj të dhënat"} isWaiting={isWaiting}/>
            <SpinnerButton title={"Anulo të dhënat"} isWaiting={isWaiting} secondary={true} onClick={()=>handleAnulo()} />
        </div>
      </form>
    </Card>
  );
};

export default UpsertOffer;
