export const columnsData = [
  {
    Header: "EMRI I PLOTË",
    accessor: "fullName",
  },
  {
    Header: "POZITA E PUNËS",
    accessor: "jobPosition",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "NR. TEL",
    accessor: "phoneNumber",
  },
  {
    Header: "STATUSI",
    accessor: "isActive",
    Cell: ({ value }) => (value ? "Aktiv" : "Jo aktiv"), 
  }
];
