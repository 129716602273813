import React from 'react'
import StockFiskalTable from './StockFiskalTable';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";


const StockFiskalList = () => {

  const exportToPdf = () => {
    const element = document.getElementById("fiskal-stock");
    const logoImgHeight = 0;
    const logoImgY = 10;
    const padding = 10;
    const textMarginTop = 2;
    const textUnderLogo = "STOKU FISKAL";
    const textFontSize = 12;
    const tableMarginTop = 10;
    const tableFontSize = 24; 

    html2canvas(element, {
      removeContainer: true, 
      scale: 1.4 ,
      onclone: (document) => {
        document.getElementById("fiskal-stock").style.overflow = "visible";
        document.getElementById("fiskal-stock").style.boxShadow="none"

      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const imgWidth = 210 - 2 * padding;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pdf = new jsPDF("p", "mm");

      // pdf.addImage(logoImageURL, "PNG", logoImgX, logoImgY, logoImgWidth, logoImgHeight);

      pdf.setFontSize(textFontSize);
      pdf.setFont("Monsterrat", "bold");

      const textWidth = pdf.getStringUnitWidth(textUnderLogo) * textFontSize / 25.4;
      const textX = (pdf.internal.pageSize.width - textWidth) / 2.3;

      pdf.text(textX, logoImgY + logoImgHeight + padding + textMarginTop, textUnderLogo);
      // Set font size for the table content
      pdf.setFontSize(tableFontSize);

      pdf.addImage(
        imgData,
        "JPEG",
        padding,
        logoImgY + logoImgHeight + padding + textMarginTop + tableMarginTop,
        imgWidth,
        imgHeight
      );

    // Footer text
    const footerText = "Tirana str. No. 91, 10 000 Prishtina | www.rectec.ac | info.rekas@gmail.com";
    const footerTextWidth = pdf.getStringUnitWidth(footerText) * 10 / 25.4; 
    const footerX = (pdf.internal.pageSize.width - footerTextWidth) / 4;

    const footerY = pdf.internal.pageSize.height - 15;

    // Set font size for the footer text
    pdf.setFontSize(10);
    pdf.text(footerText, footerX, footerY);

    pdf.save("FiskalStock.pdf");

    document.getElementById("fiskal-stock").style.boxShadow = "none";
    });
};
    return (
        <>
         <div className="mt-5 flex flex-col justify-between md:flex-row md:items-center">
             <div>
          <input
            type="text"
            placeholder="Kërko..."
            // value={searchQuery}
            // onChange={handleSearchChange}
            className="w-full rounded border bg-white p-2"
          />
        </div>
        <div className="my-5 flex justify-between md:gap-5">
          <button
            // onClick={openModal}
            onClick={exportToPdf}
            className="linear px-2 flex items-center justify-center gap-2 rounded-lg bg-red-500 text-white transition duration-200 hover:cursor-pointer hover:bg-red-400  "
          >
            <span className="px-4 py-2 text-sm font-medium text-white">Print PDF</span>
          </button>
          </div>
        </div>
           <StockFiskalTable/>
        </>
       
    )
}
export default StockFiskalList;