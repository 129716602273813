import React, { useState, useEffect } from "react";
import Card from "components/card";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiManager } from "app/ApiManager";
import { MdOutlineEdit } from "react-icons/md";
import Radio from "components/radio";

const EmployeeDetails = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const {employeeId } = location.state;
  const [employee, setEmployee] = useState({});
  const [selectedActive, setSelectedActive] = useState();
  const [isEligible, setIsEligible] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const changeActiveStatus = (elem) => {
    if (elem.id === "option1") {
      ApiManager.put(
        `/User/ChangeActiveStatus?id=${employeeId}&active=${false}`
      )
        .then((response) => {
          if (response) {
            setSelectedActive("Jo Aktiv");
          }
        })
        .catch((err) => {});
    } else {
      ApiManager.put(`/User/ChangeActiveStatus?id=${employeeId}&active=${true}`)
        .then((response) => {
          if (response) {
            setSelectedActive("Aktiv");
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    const fetchCanEdit = async () => {
      const response = await ApiManager.get(
        `/User/CanEdit?jwtToken=${localStorage.getItem(
          "token"
        )}&editingUserId=${employeeId}`
      );
      setCanEdit(response.data);
    };
    fetchCanEdit();
    ApiManager.get(`/User/EmployeeById/${employeeId}`)
      .then((response) => {
        setEmployee(response.data);
        setSelectedActive(response.data.isActive ? "Aktiv" : "Jo Aktiv");
      })
      .catch((error) => {
        console.log(error);
      });
    ApiManager.get(`/User/CheckIfValid?id=${employeeId}&`, {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setIsEligible(response.data);
      })
      .catch((err) => {});
  }, [employeeId]);

  const handleEdit = () => {
    navigation("/admin/employee-upsert", {
      state: {
        employeeId: employeeId,
      },
    });
  };

  return (
    <>
      <Card extra={"w-full h-full p-3 mt-5"}>
        {/* Header */}
        <div className="mt-2 mb-8 w-full md:flex justify-between">
          <h4 className="px-2 text-xl pb-8 font-bold text-navy-700 md:pb-0">
            {employee.fullName}
          </h4>

          <div className="flex flex-col md:flex-row md:items-center gap-8">
            {isEligible ? (
              <div className="flex justify-around md:items-center gap-8">
                <label
                  htmlFor="option1"
                  className="flex items-center space-x-2"
                >
                  <Radio
                    id="option1"
                    name="options"
                    color="red"
                    change={changeActiveStatus}
                    checked={selectedActive === "Jo Aktiv"}
                  />
                  <span>Jo Aktiv</span>
                </label>
                <label
                  htmlFor="option2"
                  className="flex items-center space-x-2"
                >
                  <Radio
                    id="option2"
                    name="options"
                    color="blue"
                    change={changeActiveStatus}
                    checked={selectedActive === "Aktiv"}
                  />
                  <span>Aktiv</span>
                </label>
              </div>
            ) : null}
            {canEdit ? (
              <button
                onClick={handleEdit}
                className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
            bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
            hover:bg-gray-100 active:bg-gray-200 "
              >
                Përditëso
                <MdOutlineEdit className="h-5 w-5 " />
              </button>
            ) : null}
          </div>
        </div>
        <div className="grid grid-cols-1  gap-2 md:grid-cols-2 md:gap-4 md:px-2">
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 overflow-x-auto">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Emri i plotë</p>
            <p className="text-base font-medium text-navy-700  ">
              {employee.fullName}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 overflow-x-auto ">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Email</p>
            <p className="text-base font-medium text-navy-700 ">
              {employee.email}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  overflow-x-auto">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Pozita e punës</p>
            <p className="text-base font-medium text-navy-700 ">
              {employee.jobPosition || "Nuk është dhënë"}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  overflow-x-auto">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Nr. telefonit</p>
            <p className="text-base font-medium text-navy-700 ">
              {employee.phoneNumber || "Nuk është dhënë"}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
};

export default EmployeeDetails;
