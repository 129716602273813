import React from "react";
import MainDashboard from "views/admin/default";
import StockList from "./views/admin/stock/components/StockList";
import {
  MdHome,
  MdImportantDevices,
  MdShoppingCart,
  MdLock,
  MdPeople,
  MdFastfood,
  MdOutlineTabletMac,
  MdEuroSymbol,
  MdReviews, 
  MdInventory,
  MdPerson
} from "react-icons/md";
import ProductList from "views/admin/menu/ProductList";
import UpsertProduct from "views/admin/menu/UpsertProduct";
import ProductDetails from "views/admin/menu/ProductDetails";
import SignIn from "views/auth/SignIn";
import StockItemDetails from "views/admin/stock/components/StockItemDetails";
import Upsert from "views/admin/stock/components/UpsertStock";
import Profile from "views/admin/profile/components/Profile";
import UpsertEmployee from "views/admin/employee/UpsertEmployee";
import EmployeeList from "views/admin/employee/EmployeeList";
import EmployeeDetails from "views/admin/employee/EmployeeDetails";
import OfferList from "views/admin/offer/OffersList";
import UpsertOffer from "views/admin/offer/UpsertOffer";
import OfferDetails from "views/admin/offer/OfferDetails";
import ExtraProductList from "views/admin/extraproducts/ExtraProductList";
import ExtraProductDetails from "views/admin/extraproducts/ExtraProductDetails";
import UpsertExtraProduct from "views/admin/extraproducts/UpsertExtraProduct";
import WaiterDashboard from "views/waiter/Dashboard";
import ChefDashboard from "views/chef/Dashboard";
import TableList from "views/admin/devices/TableList";
import UpsertTable from "views/admin/devices/UpsertTable";
import BusinessList from "views/admin/business/BusinessList";
import UpsertBusiness from "views/admin/business/UpsertBusiness";
import BusinessDetails from "views/admin/business/BusinessDetails";
import OwnerList from "views/admin/owner/OwnerList";
import UpsertOwner from "views/admin/owner/OwnerUpsert";
import OwnerDetails from "views/admin/owner/OwnerDetails";
import OwnerUpsertBusiness from "views/admin/business/OwnerUpsertBusiness";
import ManagerList from "views/admin/manager/ManagerList";
import UpsertManager from "views/admin/manager/UpsertManager";
import ManagerDetails from "views/admin/manager/ManagerDetails";
import AddEnviroment from "views/admin/devices/Enviroment";
import EnviromentList from "views/admin/devices/EnviromentList";
import AmbientDetails from "views/admin/devices/EnviromentDetails";
import Finance from "views/admin/finance/Finance";
import RatingList from "views/admin/ratings/RatingList";
import StockFifoList from "views/admin/stockFIFO/components/StockFifoList";
import StockFiskalList from "views/admin/fiskalStock/components/StockFiskalList";
import Receipt from "views/admin/receipt/components/Receipt";
import ClientPage from "views/admin/client/components/clientPage";
import ClientDetails from "views/admin/client/components/ClientDetails";
import Supplier from "views/admin/supplier/components/Supplier";
import RegisterClient from "views/admin/client/components/RegisterClient";
import RegisterSupplier from "views/admin/supplier/components/RegisterSupplier";
import SupplierDetails from "views/admin/supplier/components/SupplierDetails";


const routes = [
  {
    name: "Paneli",
    layout: "/admin",
    path: "panel",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Meny",
    layout: "/admin",
    icon: <MdImportantDevices className="h-6 w-6" />,
    path: "product-list",
    component: <ProductList />,
  },
  {
    name: "Ofertat",
    layout: "/admin",
    icon: <MdFastfood className="h-6 w-6" />,
    path: "offers-list",
    component: <OfferList />,
  },
  {
    name: "Menaxhim i ofertave",
    layout: "/admin",
    icon: <MdFastfood className="h-6 w-6" />,
    path: "offer-upsert",
    component: <UpsertOffer />,
  },
  {
    name: "Detajet e ofertës",
    layout: "/admin",
    icon: <MdFastfood className="h-6 w-6" />,
    path: "offer-details",
    component: <OfferDetails />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Menaxhim i produkteve",
    layout: "/admin",
    path: "product-upsert",
    icon: <MdLock className="h-6 w-6" />,
    component: <UpsertProduct />,
  },
  {
    name: "Detajet e produktit",
    layout: "/admin",
    path: "product-details",
    icon: <MdLock className="h-6 w-6" />,
    component: <ProductDetails />,
  },
  {
    name: "Detajet e artikullit në stok",
    layout: "/admin",
    path: "stock-details",
    icon: <MdLock className="h-6 w-6" />,
    component: <StockItemDetails />,
  },
  {
    name: "Stoku",
    layout: "/admin",
    path: "stock-list",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <StockList />,
  },
  {
    name: "Menaxhimi i stokut",
    layout: "/admin",
    path: "stock-upsert",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <Upsert />,
  },
  {
    name: "Profili",
    layout: "/admin",
    path: "profile",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Menaxhim i stafit",
    layout: "/admin",
    path: "employee-upsert",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <UpsertEmployee />,
  },
  {
    name: "Stafi",
    layout: "/admin",
    path: "employee-list",
    icon: <MdPeople className="h-6 w-6" />,
    component: <EmployeeList />,
  },
  {
    name: "Klientet",
    layout: "/admin",
    path: "client-page",
    icon: <MdPerson className="h-6 w-6" />,
    component: <ClientPage />,
  },
  {
    name: "Regjistro Klientë",
    layout: "/admin",
    path: "register-client",
    component: <RegisterClient />,
  },
  {
    name: "Detajet e klientit", 
    layout: "/admin",
    path: "client-details", 
    component: <ClientDetails/> , 
  },
  {
    name: "Furnitoret",
    layout: "/admin",
    path: "supplier",
    icon: <MdInventory className="h-6 w-6" />,
    component: <Supplier />,
  },
  {
    name: "Regjistro Furnitorë",
    layout: "/admin",
    path: "register-supplier",
    component: <RegisterSupplier />,
  },
  {
    name: "Detajet e Furnitorit", 
    layout: "/admin",
    path: "supplier-details", 
    component: <SupplierDetails/> , 
  },
  {
    name: "Detajet e punëtorit",
    layout: "/admin",
    path: "employee-details",
    icon: <MdPeople className="h-6 w-6" />,
    component: <EmployeeDetails />,
  },
  {
    name: "Produktet shtesë",
    layout: "/admin",
    path: "extraProduct-list",
    icon: <MdPeople className="h-6 w-6" />,
    component: <ExtraProductList />,
  },
  {
    name: "Pajisjet",
    layout: "/admin",
    path: "table-list",
    icon: <MdOutlineTabletMac className="h-6 w-6" />,
    component: <TableList />,
  },
  {
    name: "Menaxhimi i paisjeve",
    layout: "/admin",
    path: "table-upsert",
    icon: <MdOutlineTabletMac className="h-6 w-6" />,
    component: <UpsertTable />,
  },
  {
    name: "Menaxhimi i ambientit",
    layout: "/admin",
    path: "upsert-enviroment",
    icon: <MdOutlineTabletMac className="h-6 w-6" />,
    component: <AddEnviroment />,
  },
  {
    name: "Detajet e ambientit",
    layout: "/admin",
    path: "ambient-details",
    icon: <MdOutlineTabletMac className="h-6 w-6" />,
    component: <AmbientDetails />,
  },
  {
    name: "Ambienti",
    layout: "/admin",
    path: "list-enviroment",
    icon: <MdImportantDevices className="h-6 w-6" />,
    component: <EnviromentList/>,
  },
  {
    name: "Detajet e produktit",
    layout: "/admin",
    path: "extraProduct-details",
    icon: <MdPeople className="h-6 w-6" />,
    component: <ExtraProductDetails />,
  },
  {
    name: "Produkt shtesë",
    layout: "/admin",
    path: "extraProduct-upsert",
    icon: <MdPeople className="h-6 w-6" />,
    component: <UpsertExtraProduct />,
  },
  {
    name: "Paneli për kamarier",
    layout: "/waiter",
    path: "panel",
    icon: <MdPeople className="h-6 w-6" />,
    component: <WaiterDashboard />,
  },
  {
    name: "Paneli për kuzhinier",
    layout: "/chef",
    path: "panel",
    icon: <MdPeople className="h-6 w-6" />,
    component: <ChefDashboard />,
  },
  {
    name: "Financat",
    layout: "/admin",
    path: "monthly-finance",
    icon: <MdEuroSymbol className="h-6 w-6" />,
    component: <Finance/>,
  },
  {
    name: "Vlerësimet",
    layout: "/admin",
    path: "list-ratings",
    icon: <MdReviews className="h-6 w-6" />,
    component: <RatingList/>,
  }
]; 




//Admin Routes

export const adminRoutes = [
  {
    name: "Paneli",
    layout: "/admin",
    path: "panel",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Profili",
    layout: "/admin",
    path: "profile",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Bizneset",
    layout: "/admin",
    path: "business-list",
    icon: <MdPeople className="h-6 w-6" />,
    component: <BusinessList />,
  },
  {
    name: "Menaxhimi i bizneseve",
    layout: "/admin",
    path: "business-upsert",
    icon: <MdPeople className="h-6 w-6" />,
    component: <UpsertBusiness />,
  },
  {
    name: "Përdoruesit",
    layout: "/admin",
    path: "manager-list",
    icon: <MdPeople className="h-6 w-6" />,
    component: <OwnerList />,
  },
  {
    name: "Detajet e biznesit",
    layout: "/admin",
    path: "business-details",
    icon: <MdPeople className="h-6 w-6" />,
    component: <BusinessDetails />,
  },
  {
    name: "Menaxhimi i pronarëve",
    layout: "/admin",
    path: "owner-upsert",
    icon: <MdPeople className="h-6 w-6" />,
    component: <UpsertOwner />,
  },
  {
    name: "Detajet e drejtorit",
    layout: "/admin",
    path: "owner-details",
    icon: <MdPeople className="h-6 w-6" />,
    component: <OwnerDetails />,
  },
 
]

//Owner Routes

export const ownerRoutes = [
  {
    name: "Paneli",
    layout: "/admin",
    path: "panel",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Profili",
    layout: "/admin",
    path: "profile",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Bizneset",
    layout: "/admin",
    path: "business-list",
    icon: <MdPeople className="h-6 w-6" />,
    component: <BusinessList />,
  },
  {
    name: "Përdoruesit",
    layout: "/admin",
    path: "manager-list",
    icon: <MdPeople className="h-6 w-6" />,
    component: <ManagerList />,
  },
  {
    name: "Menaxhimi i bizneseve",
    layout: "/admin",
    path: "owner-business-upsert",
    icon: <MdPeople className="h-6 w-6" />,
    component: <OwnerUpsertBusiness />,
  },
  {
    name: "Menaxhimi i menaxherëve",
    layout: "/admin",
    path: "manager-upsert",
    icon: <MdPeople className="h-6 w-6" />,
    component: <UpsertManager />,
  },
  {
    name: "Detajet e menaxherit",
    layout: "/admin",
    path: "manager-details",
    icon: <MdPeople className="h-6 w-6" />,
    component: <ManagerDetails />,
  },
  {
    name: "Detajet e biznesit",
    layout: "/admin",
    path: "business-details",
    icon: <MdPeople className="h-6 w-6" />,
    component: <BusinessDetails />,
  },
  {
    name: "Vlersimet",
    layout: "/admin",
    path: "list-ratings",
    icon: <MdReviews className="h-6 w-6" />,
    component: <RatingList/>,
  }
]

export const printerRoutes = [
  {
    name: "Stoku i përgjithshëm",
    layout: "/admin",
    path: "stock-list",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <StockList />,
  },
  {
    name: "Stoku FIFO",
    layout: "/admin",
    path: "stock-fifo",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <StockFifoList />,
  },
  {
    name: "Stoku Fiskal",
    layout: "/admin",
    path: "stock-fiskal",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <StockFiskalList />,
  },
  {
    name: "Printo Faturen",
    layout: "/admin",
    path: "receipt-bill",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <Receipt />
  },
  {
    name: "Detajet e artikullit në stok",
    layout: "/admin",
    path: "stock-details",
    icon: <MdLock className="h-6 w-6" />,
    component: <StockItemDetails />,
  },
  {
    name: "Menaxhimi i stokut",
    layout: "/admin",
    path: "stock-upsert",
    icon: <MdShoppingCart className="h-6 w-6" />,
    component: <Upsert />,
  },
]

export default routes;
