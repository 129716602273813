import React, { useEffect, useState } from "react";
import RecipeInput from "./RecipeInput";
import { ApiManager } from "app/ApiManager";

const RecipeTableRows = ({
  rowsData,
  deleteTableRows,
  handleChange,
  handleSelectChange,
  errors,
}) => {
  const [stocks, setStocks] = useState(null);
  const [unitOfMeasure, setUnitOfMeasure] = useState("");


  useEffect(() => {
    const getStock = async () => {
      const response = await ApiManager.get(`/Stock/StockList`, {
        params: {
          jwtToken: localStorage.getItem("token")
        }
      })
      setStocks(response.data);
    }
    getStock();
  }, [])

  const changeRecipe = async (index, e) => {
    var select = document.getElementById(`recipe-${index}`);
    var selectValue = select.value;
    var selectText = select.options[select.selectedIndex].text;
    const response = await ApiManager.get(`/Stock/GetByIdAndName`, {
      params: {
        id: selectValue,
        name: selectText,
      },
    })
    setUnitOfMeasure(response.data);
    handleSelectChange(index, e);
  }

  return rowsData.map((data, index) => {
    const { ingredientName, quantity, unitQuantity } = data;
    return (
      <tr
        key={index}
        className="flex items-center justify-between border-b border-gray-200 bg-white 
        bg-white/0"
      >
        <td>
          <select
            id={`recipe-${index}`}
            onChange={(e) => changeRecipe(index, e)}
          >
            <option disabled selected>Zgjedh njërën</option>
            {stocks && stocks.map((stock) => {
              return ingredientName === stock.name ? (
                <option selected id={`${stock.type}`} key={stock.name} value={stock.id}>{stock.name}</option>
              ) : (
                <option id={`${stock.type}`} key={stock.name} value={stock.id}>{stock.name}</option>
              )
            })}
          </select>
        </td>
        <td>
          <p className="text-sm font-medium text-navy-700">
            {errors[`quantity-${index}`] === undefined
              ? ""
              : errors[`quantity-${index}`]}
          </p>
          <RecipeInput
            type="text"
            value={quantity}
            onChange={(event) => handleChange(index, event)}
            name="quantity"
            placeholder="P.sh 1.5"
            state={errors[`quantity-${index}`] ? "error" : ""}
            autoComplete="off"
          />
        </td>
        <td>
          <p className="text-sm font-medium text-navy-700">
            {errors[`unitQuantity-${index}`] === undefined
              ? ""
              : errors[`unitQuantity-${index}`]}
          </p>
          <RecipeInput
            type="text"
            disabled
            value={unitOfMeasure}
            onChange={(event) => handleChange(index, event)}
            name="unitQuantity"
            placeholder=""
            id={`unitQuantity-${index}`}
            state={errors[`unitQuantity-${index}`] ? "error" : ""}
            autoComplete="off"
          />
        </td>
        <td className="flex items-center justify-end">
          <button
            className="text-black rounded bg-gray-100 px-2 text-lg font-bold outline-none 
            hover:bg-gray-300 focus:outline-none"
            onClick={() => deleteTableRows(index)}
            type="button"
          >
            <span className="text-black text-l text-red-700">x</span>
          </button>
        </td>
      </tr>
    );
  });
};

export default RecipeTableRows;
