export const validate = (values, switchChecked) => {
  const requiredErrorMessage = "Kjo fushë është e kërkuar!";
  const errors = {};
  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 4 || values.name.length > 40) {
    errors.name = "Numri i karaktereve duhet të jetë mes 3 dhe 40!";
  }
  if (!values.description) {
    errors.description = requiredErrorMessage;
  } else if (
    values.description.length < 10 ||
    values.description.length > 150
  ) {
    errors.description = "Numri i karaktereve duhet të jetë mes 10 dhe 150!";
  }
  if (!values.price) {
    errors.price = requiredErrorMessage;
  } else if (values.price < 0) {
    errors.price = "Çmimi nuk mund të jetë më i vogel se zero!";
  } else if (!/^[0-9.]+$/.test(values.price)) {
    errors.price = "Vlera e shënuar duhet të përmbajë vetëm numra!";
  }
  if (values.calories < 0) {
    errors.calories = "Vlera e shënuar duhet të përmbajë  vetëm numra pozitiv!";
  } else if (!/^[0-9.]+$/.test(values.calories)) {
    errors.calories = "Vlera e shënuar duhet të përmbajë vetëm numra!";
  }
  if (!values.cookingTime) {
    errors.cookingTime = requiredErrorMessage;
  } else if (!/^[0-9.]+$/.test(values.cookingTime)) {
    errors.cookingTime = "Vlera e shënuar duhet të përmbajë vetëm numra!";
  }
  if (switchChecked) {
    if (!values.startDate) {
      errors.startDate = "Kjo fushë është e kërkuar për aplikimin e zbritjes!";
    }
    if (!values.endDate) {
      errors.endDate = "Kjo fushë është e kërkuar për aplikimin e zbritjes!";
    }
  }
  return errors;
};
