import React, { useEffect, useState } from "react";
import ManagerTable from "./components/ManagerTable";
import { columnsData } from "./variables/ColumnsData";
import { ApiManager } from "app/ApiManager";

const ManagerList = ({selectedBusiness}) => {
  const [managerList, setManagerList] = useState([]);
  useEffect(() => {
    const fetchAllManagers = async () => {
      const response = await ApiManager.get("/User/GetAllManagers", {
        params: {
          jwtToken: localStorage.getItem("token")
        }
      })
      setManagerList(response.data);
    }
    const fetchManagers = async () => {
      const response = await ApiManager.get(`/User/GetBusinessManagers/${selectedBusiness}`);
      setManagerList(response.data);
    }
    // selectedBusiness === 0 ? 
    fetchAllManagers() 
    // : fetchManagers();
  }, [selectedBusiness]);
  return (
    <>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
        <ManagerTable
          columnsData={columnsData}
          tableData={managerList}
          tableName="Lista e menaxherëve"
        />
      </div>
    </>
  );
};

export default ManagerList;
