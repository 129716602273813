import React, { useEffect, useContext, useState } from "react";
import Navbar from "./components/Navbar";
import { useStore } from "app/stores/store";
import ChefOrders from "./components/OrdersTable";
import { SignalRConnectionContext } from "app/SignalRConnectionProvider"
import { ApiManager } from "app/ApiManager";

const ChefDashboard = () => {
  const { ordersStore } = useStore();
  const { connection, connectionStatus } = useContext(SignalRConnectionContext);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (connection && connectionStatus === 'connected') {
      const playSound = () => {
        ApiManager.get("/Order/PlaySound")
        .catch();
      }
      const handleOrder = (order) => {
        try {
          playSound();
          ordersStore.handleRealTimeOrderForKitchen(order);
        } catch (error) {
          console.error('Error handling real-time order:', error);
        }
      };
      const handleOnlineOrder = (order) => {
        try {
          ordersStore.handleRealTimeOnlineOrderForKitchen(order);
          console.log("Hello");
          setCounter((prev) => { prev += 1 })
        } catch (error) {
          console.error('Error handling real-time order:', error);
        }
      };

      connection.on('ReceiveOrder', handleOrder);
      connection.on('ReceiveOnlineOrder', handleOnlineOrder);

      return () => {
        connection.off('ReceiveOrder', handleOrder);
        connection.off('ReceiveOnlineOrder', handleOnlineOrder);
      };
    }
  }, [connection, connectionStatus, ordersStore, counter]);
  return (
    <>
      <div className="mx-4 min-h-screen bg-lightPrimary p-2">
        <Navbar />
      <div className="">
        <ChefOrders />
      </div>
      </div>
    </>
  );
};

export default ChefDashboard;
