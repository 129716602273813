import { useStore } from "app/stores/store";
import React, { useState } from "react";

const OnlineProductCartButton = ({
  product,
  businessId,
  forceRender,
  setForceRender,
  id,
}) => {
  const { name, price, productType } = product;
  const { cartStore } = useStore();

  const handleAddToCart = () => {
    cartStore.addToCart(businessId, {
      ...product,
      productType,
      quantity: 1,
      id,
    });
    setForceRender(!forceRender);
  };

  return (
    <button
      onClick={handleAddToCart}
      type="button"
      className="h-20 w-30 md:h-28 md:w-44 lg:w-32"
    >
      <div className="flex gap-2 h-full w-full flex-col items-center justify-center rounded-xl bg-base-100 shadow-md hover:bg-lightPrimary">
        <h4 className="text-sm font-semibold text-navy-700">{name}</h4>
        <p className="text-sm font-medium text-gray-700"> {price} €</p>
      </div>
    </button>
  );
};

export default OnlineProductCartButton;
