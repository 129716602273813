import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TableItem = ({ data }) => {
  const navigation = useNavigate();

  const borderClassName =
    data.status === "E lirë"
      ? "border-green-400 hover:bg-green-100"
      : "border-red-400 hover:bg-red-100";

  const handleTableSelect = () => {
    navigation("/admin/table-upsert", {
      state: {
        tableId: data.tableId,
      },
    });
  };
  return (
    <>
      <button onClick={handleTableSelect} className={`w-full h-[8rem] rounded-xl border border-2 
          bg-white ${borderClassName}`}>
        <div className="">
          <h2 className="text-md font-semibold text-navy-700">
            {data.name}
          </h2>
          <p className="text-base text-gray-800">{data.status}</p>
        </div>
      </button>
    </>
  );
};

export default TableItem;
