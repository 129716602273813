export const columnsData = [
    {
      Header: "SASIA",
      accessor: "quantity",
    },
    {
      Header: "ÇMIMI I BLERJES",
      accessor: "price",
    },
    {
      Header: "ÇMIMI I SHITJES",
      accessor: "sellingPrice",
    },
    {
      Header: "PËRQINDJA",
      accessor: "percentage",
    },
    {
      Header: "ÇMIMI ME ZBRITJE",
      accessor: "salePrice",
    },
    {
      Header: "SHUMA",
      accessor: "total",
    },
  ];
  