import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useState, useEffect } from "react";
import { ApiManager } from "app/ApiManager";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function SignIn() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const role = decodedToken.Role;
      if (role === "Menaxher" || role ==="Drejtor" || role === "SuperAdmin" || role === "Faturues") {
        navigate("/admin/panel");
      } else if (role === "Kamarier") {
        navigate("/waiter/panel");
      } else if (role === "Kuzhinier") {
        navigate("/chef/panel");
      }
    } else {
      navigate("/auth/sign-in");
    }
  }, [navigate]);
  
  const handleLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage("Ju lutem shenoni kredencialet për t'u identifikuar!");
      return;
    }
    ApiManager.post("/Auth/Login", { email, password })
      .then((response) => {
        if(!response.data.isActive) {
          setErrorMessage("Llogaria juaj është jo aktive!");
          return;
        }
        localStorage.setItem("token", response.data.token);
        const decodedToken = jwt_decode(response.data.token);
        const role = decodedToken.Role;
        if (role === "Menaxher" || role ==="Drejtor" || role === "SuperAdmin" || role === "Faturues") {
          if(role === "Faturues") {
            navigate("/admin/stock-list");
          }
          else {
            navigate("/admin/panel");
          }
        } else if (role === "Kamarier") {
          navigate("/waiter/panel");
        } else if (role === "Kuzhinier") {
          navigate("/chef/panel");
        }
      })
      .catch((error) => {
        console.log(error.stack);
        if (error.response.status === 400) {
          setErrorMessage("Kredencialet tuaja nuk janë të sakta!");
        }
      });
  };
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <form onSubmit={(e) => handleLogin(e)} className="mt-[16vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 ">
          Kyçu
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Shkruani emailin dhe fjalëkalimin tuaj për t'u identifikuar!
        </p>
        {errorMessage && (
          <p className="text-center text-base text-red-500">{errorMessage}</p>
        )}
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="
          filan@fisteku.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Fjalëkalimi*"
          placeholder="Min. 6 karaktere"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 ">
              Më mbaj të kyçur
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 "
            href=" "
          >
            Keni harruar fjalëkalimin?
          </a>
        </div>
        {/* <SpinnerButton title={"Kyçu"} isWaiting={isWaiting}/> */}
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] 
        text-base font-medium text-white transition duration-200 hover:bg-brand-600 
        active:bg-brand-700 "
        >
          Kyçu
        </button>
      </form>
    </div>
  );
}
