const Footer = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <h5 className="text-gray-600 font-medium">
          ©{1900 + new Date().getYear()} RECTEC. All Rights Reserved.
      </h5>
    </div>
  );
};

export default Footer;
