import { ApiManager } from "app/ApiManager";
import { makeAutoObservable,  } from "mobx";
import { runInAction, toJS } from 'mobx';

export default class OrdersStore {
  orders = [];
  tables = [];
  onlineOrders = [];
  allOrderItems = [];
  allOnlineOrderItems = [];
  currentSelectedTable = {};
  currentOrderItemsStatus = [];
  notifications = [];
  finishedOrderItems = [];
  finishedOnlineOrderItems = [];
  allItems = [];

  constructor() {
    makeAutoObservable(this);
    this.fetchInitialData();
  }

  async fetchInitialData() {
    await this.fetchTables();
    await this.fetchOrders();
    await this.fetchOnlineOrders();
    this.resetCurrentOrderAndTableState();
    
  }

  async fetchTables() {
    try {
      const response = await ApiManager.get("/Table/GetTablesByBusinessId", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      this.tables = response.data || [];
    } catch (error) {
      console.log(error);
    }
  }

  async fetchOrders() {
    try {
      const response = await ApiManager.get("/Order/GetOrdersByBusinessId", {
        params: { jwtToken: localStorage.getItem("token") },
      });
      this.orders = response.data;
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    }
  }

  async fetchOnlineOrders() {
    try {
      const response = await ApiManager.get("/OnlineOrder/Orders", {
        params: { jwtToken: localStorage.getItem("token") },
      });
      this.onlineOrders = response.data;
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    }
  }

  isUserLoggedIn() {
    // Adjust this method to match your authentication logic
    return localStorage.getItem("token") !== null;
  }
  addOrder(newOrder) {
    if (!this.isUserLoggedIn()) {
      console.warn("Only logged-in waiters can add orders.");
      return;
    }
    runInAction(() => {
      this.orders.push(newOrder);
      this.updateTableState(newOrder.tableId, "E zënë");
      this.setCurrentOrderItemsStatus(newOrder, newOrder.tableId);
    });
  }
  resetCurrentOrderAndTableState() {
    runInAction(() => {
      this.currentSelectedTable = {}; // Reset to initial state or set to a default table
      this.currentOrderItemsStatus = []; // Clear current order items
      // Optionally, select a default table or perform other state resets as needed
    });
  }
  setTables(tablesData) {
    this.tables = tablesData;
  }

  setOrders(orders) {
    this.orders = [...orders];
    return toJS(this.orders);
  }

  get getCurrentTable() {
    return (
      this.tables.find(
        (t) => t.tableId === this.currentSelectedTable.tableId
      ) || {}
    );
  }
  getCurrentTableById(tableId) {
    return this.tables.find((t) => t.tableId === tableId) || {};
  }

  getOrdersBasedOnTableId(tableId) {
    return toJS(this.orders).filter((order) => order.tableId === tableId);
  }

  getAllOrders() {
    return toJS(this.orders);
  }

  getCurrentOrderItemsStatus() {
    if (!this.currentSelectedTable.tableId) return [];
    return this.orders
      .filter((order) => order.tableId === this.currentSelectedTable.tableId)
      .flatMap((order) => order.items || []); // Make sure items are always an array
  }

  async getAllOrderItems() {
    try {
      const response = await ApiManager.get("/Order/GetOrderItemsForKitchen", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      this.allOrderItems = response.data;
      return toJS(this.allOrderItems);
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getLastOrder(tableId) {
    try {
      const response = await ApiManager.get(
        `/Order/GetLastOrderOfTable?tableId=${tableId}`
      );
      return response.data;
    } catch (err) {}
  }

  async getLastOnlineOrder(businessId) {
    try {
      const response = await ApiManager.get(
        `/OnlineOrder/GetLastOrder/${businessId}`
      );
      return response.data;
    } catch (err) {}
  }

  setCurrentOrderItemsStatus(orders, tableId) {
    runInAction(() => {
      this.currentOrderItemsStatus = Array.isArray(orders) ? [...orders] : [];
      this.currentSelectedTable =
        this.tables.find((t) => t.tableId === tableId) || {};
    });
  }
  handleRealTimeOrder(order) {
    runInAction(async () => {
      let newOrders = [...this.orders]; // Clone the current orders array
      const existingOrderIndex = newOrders.findIndex(
        (o) => o.orderId === order.orderId
      );

      var orderFromDb = await this.getLastOrder(order.tableId);
      if (existingOrderIndex === -1) {
        // If the order is not in the list, add it
        newOrders.push(orderFromDb);
      } else {
        // If the order is already in the list, update it
        newOrders[existingOrderIndex] = orderFromDb;
      }

      // Now update the orders array with the new array
      this.orders = newOrders;

      // If the updated order belongs to the currently selected table, update the currentOrderItemsStatus
      if (this.currentSelectedTable.tableId === order.tableId) {
        this.currentOrderItemsStatus = this.orders
          .filter((o) => o.tableId === this.currentSelectedTable.tableId)
          .flatMap((o) => o.items || []);
      }

      // Update table status
      this.updateTableState(order.tableId, "E zënë");
      this.refreshUI("table");
    });
  }
  handleRealTimeOnlineOrder(order) {
    runInAction(async () => {
      let newOrders = [...this.onlineOrders]; // Clone the current orders array
      const existingOrderIndex = newOrders.findIndex(
        (o) => o.orderId === order.orderId
      );

      var orderFromDb = await this.getLastOnlineOrder(order.businessId);
      if (existingOrderIndex === -1) {
        // If the order is not in the list, add it
        newOrders.push(orderFromDb);
      } else {
        // If the order is already in the list, update it
        newOrders[existingOrderIndex] = orderFromDb;
      }

      // Now update the orders array with the new array
      this.onlineOrders = newOrders;

      // If the updated order belongs to the currently selected table, update the currentOrderItemsStatus
      // if (this.currentSelectedTable.tableId === order.tableId) {
      //   this.currentOrderItemsStatus = this.orders
      //     .flatMap((o) => o.items || []);
      // }

      this.refreshUI("online");
    });
  }
  refreshUI(value) {
    // Logic to refresh/reload order data in the UI
    if(value === " table")
    this.fetchOrders();
    else 
    this.fetchOnlineOrders();
  }
  //generate handle realtime for kichen orders
  handleRealTimeOrderForKitchen(order) {
    runInAction(async () => {
      let newOrders = [...this.allOrderItems]; 
      const existingOrderIndex = newOrders.findIndex(
        (o) => o.orderId === order.onlineOrderId
      );

      var orderFromDb = await this.getLastOrder(order.tableId);
      if (existingOrderIndex === -1) {
        // If the order is not in the list, add it
        newOrders.push(orderFromDb);
      } else {
        // If the order is already in the list, update it
        newOrders[existingOrderIndex] = orderFromDb;
      }

      // Now update the orders array with the new array
      this.allOrderItems = newOrders;

      // If the updated order belongs to the currently selected table, update the currentOrderItemsStatus
      if (this.currentSelectedTable.tableId === order.tableId) {
        this.currentOrderItemsStatus = this.allOrderItems
          .filter((o) => o.tableId === this.currentSelectedTable.tableId)
          .flatMap((o) => o.items || []);
      }

      // Update table status
      this.updateTableState(order.tableId, "E zënë");
    });
  }

  handleRealTimeOnlineOrderForKitchen(order) {
    runInAction(async () => {
      let newOrders = [...this.allOnlineOrderItems]; 
      const existingOrderIndex = newOrders.findIndex(
        (o) => o.orderId === order.onlineOrderId
      );
      var orderFromDb = await this.getLastOnlineOrder(order.businessId);
      if (existingOrderIndex === -1) {
        // If the order is not in the list, add it
        newOrders.push(orderFromDb);
      } else {
        // If the order is already in the list, update it
        newOrders[existingOrderIndex] = orderFromDb;
      }
      // Now update the orders array with the new array
      this.allOnlineOrderItems = newOrders;

      // If the updated order belongs to the currently selected table, update the currentOrderItemsStatus
      this.currentOrderItemsStatus = this.allOnlineOrderItems
        .flatMap((o) => o.items || []);
      // Update table status
      this.updateTableState(order.tableId, "E zënë");
    });
  }

  

  setCurrentSelectedTable(tableId, name) {
    const table = this.tables.find((t) => t.tableId === tableId);
    if (table) {
      runInAction(() => {
        this.currentSelectedTable = { ...table };
      });
    }
  }

  changeOrderItemStatus(status, id) {
    const index = this.allOrderItems.findIndex((x) => x.id === id);
    if (index !== -1) {
      this.allOrderItems[index].status = status;
    }
    // Update order item in database
    ApiManager.put(
      "/Order/UpdateOrderItemStatus",
      {},
      {
        params: {
          status: status,
          id: id,
        },
      }
    )
      .then(() => console.log("Order item status updated successfully."))
      .catch((error) =>
        console.log("Error updating order item status:", error)
      );
    return toJS(this.allOrderItems);
  }

  changeOnlineOrderItemStatus(status, id) {
    const index = this.allOnlineOrderItems.findIndex((x) => x.id === id);
    if (index !== -1) {
      this.allOnlineOrderItems[index].status = status;
    }
    // Update order item in database
    ApiManager.put(
      "/OnlineOrder/UpdateOrderItemStatus",
      {},
      {
        params: {
          status: status,
          id: id,
        },
      }
    )
      .then(() => console.log("Order item status updated successfully."))
      .catch((error) =>
        console.log("Error updating order item status:", error)
      );
    return toJS(this.allOnlineOrderItems);
  }
 
  
  handleRealTimeOrderStatus(status, id) {
    const index = this.allOrderItems.findIndex((x) => x.id === id);
    if (index !== -1) {
      this.allOrderItems[index].status = status;
    }
  }
  clearCurrentOrderItemsStatus() {
    this.currentOrderItemsStatus = [];
  }

  updateTableState(tableId, newState) {
    const tableIndex = this.tables.findIndex((t) => t.tableId === tableId);
    if (tableIndex !== -1) {
      runInAction(() => {
        this.tables[tableIndex] = {
          ...this.tables[tableIndex],
          status: newState,
        };
        this.tables = [...this.tables]; // This line is critical
      });
    }
  }

  getUnfinishedAllOrderItems() {
    return ApiManager.get("/Order/GetUnfinishedOrderItemsForKitchen", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        this.allOrderItems = response.data;
        return toJS(this.allOrderItems);
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  getFinishedAllOrderItems() {
    return ApiManager.get("/Order/GetFinishedOrderItemsForKitchen", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        this.finishedOrderItems = response.data;
        return toJS(this.finishedOrderItems);
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  
  getUnfinishedAllOnlineOrderItems() {
    return ApiManager.get("/OnlineOrder/GetUnfinishedOrderItemsForKitchen", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        this.allOnlineOrderItems = response.data;
        return toJS(this.allOnlineOrderItems);
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  getFinishedAllOnlineOrderItems() {
    return ApiManager.get("/OnlineOrder/GetFinishedOrderItemsForKitchen", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        this.finishedOnlineOrderItems = response.data;
        return toJS(this.finishedOnlineOrderItems);
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  processPaymentAndClearOrders(tableId) {
    this.updateTableState(tableId, "E lirë"); // Update table status
    runInAction(() => {
      this.orders = this.orders.filter(order => order.tableId !== tableId); // Remove orders
      this.currentOrderItemsStatus = []; // Clear current order items status
      // If needed, update any other relevant state
    });
  }

  addOnlineOrder(newOrder) {
    if (!this.isUserLoggedIn()) {
      console.warn("Only logged-in waiters can add orders.");
      return;
    }
    runInAction(() => {
      this.onlineOrders.push(newOrder);
      // this.updateTableState(newOrder.tableId, "E zënë");
      // this.setCurrentOrderItemsStatus(newOrder, newOrder.tableId);
    });
  }
 
  
  handleRealTimeOnlineOrderStatus(status, id) {
    const index = this.allOrderItems.findIndex((x) => x.id === id);
    if (index !== -1) {
      this.allOrderItems[index].status = status;
    }
  }

}
