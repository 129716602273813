import { makeAutoObservable, toJS,runInAction } from "mobx";
import { ApiManager } from "app/ApiManager";

export default class CartStore {
  carts = [];
  orders = [];
  onlineOrders = [];

  constructor() {
    makeAutoObservable(this);
  }

  addToCart(tableId, product) {
    if (!this.carts[tableId]) {
      this.carts[tableId] = [];
    }

    const cart = this.carts[tableId];
    const existingCartItemIndex = cart.findIndex(
      (item) =>
        item.id === product.id && item.productType === product.productType
    );

    if (existingCartItemIndex !== -1) {
      // Cart item already exists, increment the quantity
      cart[existingCartItemIndex].quantity += product.quantity;
    } else {
      // Cart item doesn't exist, add it to the cart
      cart.push({ ...product, tableId, quantity: 1 });
    }
  }

  getCartForTable(tableId) {
    if (!this.carts[tableId]) {
      this.carts[tableId] = [];
    }
    return toJS(this.carts[tableId]);
  }

  incrementQuantity(tableId, id, productType) {
    const cart = this.carts[tableId];
    const cartItemIndex = cart.findIndex(
      (item) => item.id === id && item.productType === productType
    );

    if (cartItemIndex !== -1) {
      cart[cartItemIndex].quantity += 1;
    }
  }

  decrementQuantity(tableId, id, productType) {
    const cart = this.carts[tableId];
    const cartItemIndex = cart.findIndex(
      (item) => item.id === id && item.productType === productType
    );
    if (cartItemIndex !== -1 && cart[cartItemIndex].quantity > 1) {
      cart[cartItemIndex].quantity -= 1;
    }
    console.log(cart[cartItemIndex].quantity);
  }

  deleteCartItem(tableId, productType, id) {
    const cart = this.getCartForTable(tableId);
    const index = cart.findIndex(
      (item) => item.productType === productType && item.id === id
    );
    if (index !== -1) {
      this.carts[tableId] = cart.filter((item, i) => i !== index);
    }
  }

  totalPrice(cart) {
    const total = cart.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    return parseFloat(total.toFixed(2));
  }


saveOrderToDb(tableId, onOrderSaved, type) {
    const cart = toJS(this.carts[tableId]);
    let businessId = cart[0].businessId;
    let orders = {
      TableId: tableId,
      businessId: businessId,
      OrderItems: cart,
      TotalPrice: this.totalPrice(cart),
      Type: type,
    };
    console.log(toJS(this.carts));
    ApiManager.post("/Order/WaiterAssistOrder", orders)
    .then((response) => {
      runInAction(() => {
        this.carts[tableId] = []; // Clear the cart
      });
      if (onOrderSaved && typeof onOrderSaved === 'function') {
        onOrderSaved(tableId, response.data);
      }
    })
      .catch((error) => {
        console.error("Error placing order:", error);
      });
  }

  addOnlineOrder(businessId, onOrderSaved, changeCounter) {
    const cart = toJS(this.carts[businessId]);
    console.log(cart);
    let orders = {
      businessId: businessId,
      items: cart,
      totalPrice: this.totalPrice(cart),
    };
    const addOrder = async () => {
      try {
        const response = await ApiManager.post(`/OnlineOrder/AddOrder/${localStorage.getItem("token")}`, orders);
        runInAction(() => {
          this.carts[businessId] = []; // Clear the cart
        });
        if (onOrderSaved && typeof onOrderSaved === 'function') {
          onOrderSaved(businessId, response.data);
          changeCounter();
        }
      } catch (error) {
        console.error("Error placing order:", error);
      }
    };
    addOrder();
  }

  getCartForOnline(businessId) {
    if (!this.carts[businessId]) {
      this.carts[businessId] = [];
    }
    return toJS(this.carts[businessId]);
  }

  addToOnlineCart(businessId, product) {
    if (!this.carts[businessId]) {
      this.carts[businessId] = [];
    }

    const cart = this.carts[businessId];
    const existingCartItemIndex = cart.findIndex(
      (item) =>
        item.id === product.id && item.productType === product.productType
    );

    if (existingCartItemIndex !== -1) {
      // Cart item already exists, increment the quantity
      cart[existingCartItemIndex].quantity += product.quantity;
    } else {
      // Cart item doesn't exist, add it to the cart
      cart.push({ ...product, businessId, quantity: 1 });
    }
    console.log(toJS(this.carts));
  }

}