import React, { useState, useEffect } from "react";
import RecipeTableRows from "./components/RecipeTableRows";
import { useStore } from "app/stores/store";
import { autorun, toJS } from "mobx";

const RecipeTable = () => {
  const [rowsData, setRowsData] = useState([
    { ingredientName: "", quantity: 0, unitQuantity: "", recipeId: 0, stockId: 0, type: "" },
  ]);
  const { upsertProductStore } = useStore();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    autorun(() => {
      const regularArray = toJS(upsertProductStore.rowsData);
      setRowsData(regularArray);
    });
  }, [upsertProductStore.rowsData]);

  const addTableRows = () => {
    if (rowsData.length === 0) {
      const rowsInput = {
        ingredientName: "",
        quantity: 0,
        unitQuantity: "",
        recipeId: 0,
      };
      setRowsData([rowsInput]);
      upsertProductStore.setRowsData([rowsInput]);
      setErrors({});
      return;
    }
    // Check if all inputs in the last row are filled
    const lastRow = rowsData[rowsData.length - 1];
    if (
      lastRow.ingredientName === "" ||
      lastRow.quantity === "" ||
      lastRow.unitQuantity === ""
    ) {
      const errors = {
        [`ingredientName-${rowsData.length - 1}`]:
          lastRow.ingredientName === "" ? "Kërkohet emri i përbërësit*" : "",
        [`quantity-${rowsData.length - 1}`]:
          lastRow.quantity === "" ? "Kërkohet sasia e përbërësit*" : "",
        [`unitQuantity-${rowsData.length - 1}`]:
          lastRow.unitQuantity === "" ? "Kërkohet njësia e përbërësit*" : "",
      };
      setErrors(errors);
      return;
    }

    const rowsInput = {
      ingredientName: "",
      quantity: 0,
      unitQuantity: "",
      recipeId: 0,
      stockId: 0,
      type: ""
    };
    setRowsData([...rowsData, rowsInput]);
    upsertProductStore.setRowsData([...rowsData, rowsInput]);
    setErrors({});
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    upsertProductStore.setRowsData(rows);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
    upsertProductStore.setRowsData(rowsInput);
  };

  const handleSelectChange = (index, event) => {
    const select = event.target;
    const option = select.options[select.selectedIndex];
    const name = option.text
    const type = option.id;
    const value = event.target.value;
    const rowsInput = [...rowsData];
    rowsInput[index]["ingredientName"] = name;
    rowsInput[index]["type"] = type;
    rowsInput[index]["stockId"] = value;
    setRowsData(rowsInput);
    upsertProductStore.setRowsData(rowsInput);
  }

  return (
      <div className="flex">
        <div className="w-full">
          <table className="w-full table-auto">
            <thead
              className="border-b border-gray-200 pb-[10px] text-start 
                 "
            >
              <tr className="flex  items-center justify-between">
                <th className="text-sm font-medium text-gray-800">
                  Përbërësi
                </th>
                <th className="text-sm font-medium text-gray-800">
                  Sasia
                </th>
                <th className="text-sm font-medium text-gray-800">
                  Njësia matëse
                </th>
                <th className="flex justify-end">
                  <button
                    style={{width: "100px"}}
                    className="linear hover-bg-custom :bg-navy-600 mx-auto mt-4 flex justify-center rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
                    onClick={addTableRows}
                    type="button"
                  >
                    Shto
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <RecipeTableRows
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
                errors={errors}
              />
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default RecipeTable;
