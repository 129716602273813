
import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePrevious, handleNext, handlePageClick }) => {
  // Array of page numbers
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="flex items-center justify-center gap-2 mt-5">
      <button
        disabled={currentPage === 1}
        onClick={handlePrevious}
        className="px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-700 disabled:bg-blue-300"
      >
        Kthehu
      </button>
      {pages.map(number => (
        <button
          key={number}
          onClick={() => handlePageClick(number)}
          className={`px-3 py-1 rounded ${currentPage === number ? 'bg-blue-700 text-white' : 'bg-white'} hover:bg-blue-700 hover:text-white`}
        >
          {number}
        </button>
      ))}
      <button
        disabled={currentPage === totalPages}
        onClick={handleNext}
        className="px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-700 disabled:bg-blue-300"
      >
        Vazhdo
      </button>
    </div>
  );
};

export default Pagination;
