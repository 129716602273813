import { useState, useEffect } from "react";
import { ApiManager } from "app/ApiManager";
import jwtDecode from "jwt-decode";

const TotalSales = ({ selectedBusiness }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const GetAllSales = async () => {
      try {
        const response = await ApiManager.get(`/Sales/SalesByUserId`, {
          params: {
            jwtToken: localStorage.getItem("token"),
          },
        });
        setTotal(response.data);
      } catch (error) {
        console.log(error, "err1");
      }
    };
    const GetCertainSales = async () => {
      const response = await ApiManager.get(
        `/Sales/SalesByBusinessId/${selectedBusiness}`
      );
      setTotal(response.data);
    };
    const GetSales = async () => {
      const response = await ApiManager.get(`/Sales/TotalSales`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setTotal(response.data);
    };
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if (role === "Drejtor") {
      var selectedBusiness = localStorage.getItem("selectedBusiness");
      if (selectedBusiness === "0") {
        GetAllSales();
      } else {
        GetCertainSales();
      }
    } else {
      GetSales();
    }
  }, [selectedBusiness]);
  return total;
};

export default TotalSales;
