// Item.js
import { observer } from "mobx-react";
import React ,{useEffect, useState} from "react";

const Item = observer(({ item,index,statusUpdate}) => {
  const [status, setStatus] = useState(item.status);
  useEffect(() => {
    setStatus(item.status); // Update status when item changes
  }, [item]); // Depend on item
  useEffect(() => {
    if (statusUpdate) {
      setStatus(statusUpdate);
    }
  }, [statusUpdate]);

  return (
    <tr key={`${item.orderId}-${item.id}`} className="text-center">
      <th className="hidden md:table-cell">{index + 1}</th>
      <td className="">{item.name}</td>
      <td>{item.quantity}</td>
      <td>{item.price.toFixed(2)} €</td>
      {item.productType === "Food" || item.productType === "Dessert" ? (
        <td>
          <p
            className={`rounded-lg p-2 ${
              status === "Përfunduar"
                ? "border-[#ffd746] bg-[#edffed] text-[#63b363]"
                : status === "Pranuar"
                ? "border-[#98ff98] bg-[#fff6e6] text-[#f0a76f]"
                : "border-[#ababab] bg-[#ededed] text-[#ababab]"
            }`}
          >
            {status}
          </p>
        </td>
      ) : null}
    </tr>
  );
});

export default Item;
