export const columnsData = [
  {
    Header: "EMËRTIMI",
    accessor: "name",
  },
  {
    Header: "PËRSHKRIMI",
    accessor: "description",
  },
  {
    Header: "ÇMIMI",
    accessor: "price",
  },
];
