export const columnsData = [
    {
      Header: "EMRI",
      accessor: "firstName",
    },
    {
      Header: "MBIEMRI",
      accessor: "lastName",
    },
    {
      Header: "EMAIL ADRESA",
      accessor: "email",
    },
    {
      Header: "NR. TEL",
      accessor: "phoneNumber",
    },
  ];
  