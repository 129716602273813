import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SpinnerButton from "components/buttons/SpinnerButton";
import ErrorLabel from "components/error/ErrorLabel";
import Swal from "sweetalert2";

const UpsertAmbient = () => {
  const [headerText, setHeaderText] = useState("");
  const [ambient, setAmbient] = useState({});
  const navigation = useNavigate();
  const location = useLocation();
  const [ambientId, setAmbientId] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (location.state?.ambientId) {
      setAmbientId(location.state.ambientId);
      setHeaderText("Përditësim i Ambientit");
      ApiManager.get(`/Ambient/GetAmbientById/${location.state.ambientId}`)
        .then((response) => {
          setAmbient(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setHeaderText("Regjistrim i Ambientit");
    }
  }, [location.state]);

  const formik = useFormik({
    initialValues: {
      name: ambient?.name || "",
      description: ambient?.description || "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Emërtimi është i detyrueshëm";
      }

      if (!values.description) {
        errors.description = "Përshkrimi është i detyrueshëm";
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsWaiting(true);
      const jwtToken = localStorage.getItem("token");
      let endpoint = ambientId ? `/Ambient/UpdateAmbient` : "/Ambient/Ambient";
      let method = ambientId ? ApiManager.put : ApiManager.post; 

      method(endpoint, values, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          jwtToken: jwtToken,
        },
      })
        .then(() => {
          setIsWaiting(false);
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: ambientId ? "Ambienti u përditësua me sukses" : "Ambienti u regjistrua me sukses",
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            navigation("/admin/list-enviroment"); 
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsWaiting(false);
        });
    },
  });

  useEffect(() => {
    if (ambient) {
      formik.setValues({
        name: ambient.name || "",
        description: ambient.description || "",
      });
    }
  }, [ambient]);

  const { errors, touched, handleSubmit } = formik;
  const handleAnulo = () =>{
    navigation("/admin/list-enviroment");
 }

  return (
    <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
      <h3 className="my-2 mb-4 text-xl font-bold text-navy-700">
        {headerText}
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="md:flex justify-between gap-x-3">
          <div className="mb-3 w-full">
            <InputField
              variant="auth"
              extra="mb-1"
              label="Emërtimi*"
              placeholder="P.sh Salla e Konferencave"
              id="name"
              name="name"
              type="text"
              {...formik.getFieldProps("name")}
              state={errors.name && touched.name ? "error" : null}
            />
            {errors.name && touched.name ? <ErrorLabel error={errors.name} /> : null}
          </div>
          <div className="mb-3 w-full">
            <InputField
              variant="auth"
              extra="mb-1"
              label="Përshkrimi"
              placeholder="P.sh Përshkrim i detajuar"
              id="description"
              name="description"
              type="text"
              {...formik.getFieldProps("description")}
              state={errors.description && touched.description ? "error" : null}
            />
            {errors.description && touched.description ? <ErrorLabel error={errors.description} /> : null}
          </div>
        </div>
        <div className="md:flex justify-around" >
          <SpinnerButton title={ambientId ? "Përditëso të dhënat" : "Ruaj të dhënat"} isWaiting={isWaiting} />
          <SpinnerButton title={"Anulo të dhënat"} isWaiting={isWaiting} secondary={true} onClick={()=>handleAnulo()} />
        </div>
      </form>
    </Card>
  );
};

export default UpsertAmbient;
