import axios from "axios";

let baseURL = "http://localhost:5000"; 

if (process.env.NODE_ENV === "production") {
  baseURL = "https://api.rectec.online"; 
}

export const ApiManager = axios.create({
  baseURL: baseURL+"/api",
  responseType: "json",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  allowCredentials: true,
});
export default baseURL;