import React, { useEffect, useState } from "react";
import { ApiManager } from "app/ApiManager";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "components/card";
import {
  MdOutlineArchive,
  MdOutlineUnarchive,
  MdOutlineEdit,
} from "react-icons/md";
import formatDate from "./variables/FormatDate";
import Swal from "sweetalert2";

const OfferDetails = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { offerId } = location.state;
  const [offerItem, setOfferItem] = useState({});
  const [archiveOffer, setArchiveOffer] = useState();

  useEffect(() => {
    ApiManager.get(`/Offer/GetOffer/${offerId}?requireImg=false`)
      .then((response) => {
        response.data.offerStart = formatDate(response.data.offerStart);
        response.data.offerEnd = formatDate(response.data.offerEnd);
        setOfferItem(response.data);
        setArchiveOffer(response.data.isArchived);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleEdit = () => {
    navigation("/admin/offer-upsert", {
      state: {
        offerId: offerId,
      },
    });
  };

  const handleArchive = () => {
    let endpoint = `/Offer/ArchiveOffer/${offerId}`;

    const requestData = !archiveOffer;
    ApiManager.post(endpoint, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
     .then((response) => {
      setArchiveOffer(!archiveOffer);
      const swalTitle = archiveOffer ? "Oferta u largua nga arkiva me sukses!" : "Oferta u arkivua me sukses!";
      Swal.fire({
        title: swalTitle,
        icon: "success",
        showConfirmButton: false,
        timer: 2000
      }).then((result) => {
          navigation("/admin/offers-list"); 
      });
    })
    .catch((error) => {
      console.log(error);
    });
  };
  return (
    <>
      <Card extra={"w-full h-full p-3 mt-5"}>
        <div className="w-full flex flex-col">
          <div className="flex flex-col-reverse md:flex-row justify-between">
            <h4 className="px-2 text-xl font-bold text-navy-700">
              {offerItem.name}
            </h4>
            <div className="mb-5 flex justify-between gap-3">
              <button
                onClick={handleArchive}
                className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
              bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200"
              >
                {offerItem.isArchived ? (
                  <span className="flex items-center">
                    Largo nga arkiva &nbsp;
                    <MdOutlineArchive className="h-5 w-5" />
                  </span>
                ) : (
                  <span className="flex items-center">
                    Arkivo &nbsp; <MdOutlineUnarchive className="h-5 w-5" />
                  </span>
                )}
              </button>
              <button
                onClick={handleEdit}
                className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
              bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200"
              >
                Përditëso
                <MdOutlineEdit className="h-5 w-5 " />
              </button>
            </div>
          </div>
          <p className="px-2 text-base text-gray-700">
            {offerItem.description}
          </p>
        </div>

        {/* Cards */}
        <div className="grid md:grid-cols-2 gap-4 mt-5">
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Emërtimi</p>
            <p className="text-base font-medium text-navy-700">
              {offerItem.name}
            </p>
          </div>

          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Çmimi</p>
            <p className="text-base font-medium text-navy-700">
              {offerItem.price} €
            </p>
          </div>

          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Përshkrimi</p>
            <p className="text-base font-medium text-navy-700">
              {offerItem.description}
            </p>
          </div>

          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Data e fillimit të ofertës</p>
            <p className="text-base font-medium text-navy-700">
              {offerItem.offerStart}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Data e mbarimit të ofertës</p>
            <p className="text-base font-medium text-navy-700">
              {offerItem.offerEnd}
            </p>
          </div>

          {offerItem.foods && offerItem.foods.length > 0 && (
            <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
              <p className="text-sm text-gray-800">Ushqimet në ofertë</p>
              <div>
                {offerItem.foods.map((food, index) => (
                  <span
                    className="inline-block text-base font-medium text-navy-700 "
                    key={index}
                  >
                    {food.name}
                    {index !== offerItem.foods.length - 1 && (
                      <span>,&nbsp;</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          )}

          {offerItem.drinks && offerItem.drinks.length > 0 && (
            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
              <p className="text-sm text-gray-800">Pijet në ofertë</p>
              <div>
                {offerItem.drinks.map((drink, index) => (
                  <span
                    className="inline-block text-base font-medium text-navy-700"
                    key={index}
                  >
                    {drink.name}
                    {index !== offerItem.drinks.length - 1 && (
                      <span>,&nbsp;</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          )}

          {offerItem.desserts && offerItem.desserts.length > 0 && (
            <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 ">
              <p className="text-sm text-gray-800">Ëmbëlsirat në ofertë</p>
              <div>
                {offerItem.desserts.map((dessert, index) => (
                  <span
                    className="inline-block text-base font-medium text-navy-700 "
                    key={index}
                  >
                    {dessert.name}
                    {index !== offerItem.desserts.length - 1 && (
                      <span>,&nbsp;</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default OfferDetails;
