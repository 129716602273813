import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

const OnlineOrderItem = observer(({ statusUpdate, index, item }) => {
  const [status, setStatus] = useState(item.status);
  useEffect(() => {
    setStatus(item.status); // Update status when item changes
  }, [item]); // Depend on item
  useEffect(() => {
    if (statusUpdate) {
      setStatus(statusUpdate);
    }
  }, [statusUpdate]);

  return (
    <li
      key={item.id}
      className="mb-3 flex items-center justify-between text-lg text-gray-800"
    >
      <div className="text-base">
        <span className="pr-2">{++index}.</span>
        {item.name} x {item.quantity}
      </div>

      {item.productType === "Food" || item.productType === "Dessert" ? (
        <div>
          <p
            className={`rounded-lg py-1 px-4 text-base font-medium  ${
              status === "Përfunduar"
                ? "border-[#ffd746] bg-[#edffed] text-[#63b363]"
                : status === "Pranuar"
                ? "border-[#98ff98] bg-[#fff6e6] text-[#f0a76f]"
                : "border-[#ababab] bg-[#ededed] text-[#ababab]"
            }`}
          >
            {status}
          </p>
        </div>
      ) : null}
    </li>
  );
});

export default OnlineOrderItem;
