import React, { useState, useEffect } from "react";
import FinanceList from "./components/FinanceList";

const YearlyFinance = () => {
  return (
    <>
      <FinanceList tableName="Financat vjetore" />
    </>
  );
};

export default YearlyFinance;
