import React, { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import Card from "components/card";
import ErrorLabel from "components/error/ErrorLabel";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import { useNavigate, useLocation } from "react-router-dom";
import { validate } from "./variables/ValidateForm";
import SpinnerButton from "components/buttons/SpinnerButton";
import Swal from "sweetalert2";
import UpsertOwner from "./components/UpsertOwner";

const UpsertBusiness = () => {
  const navigation = useNavigate();
  const [headerText, setHeaderText] = useState("Regjistrim");
  const location = useLocation();
  const [business, setBusiness] = useState({});
  const [businessId, setBusinessId] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const [selectedManagerId, setSelectedManagerId] = useState();
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [ownerList, setOwnerList] = useState();
  const [businessOwner, setBusinessOwner] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(0);

  const changeCurrentManager = (value) => {
    setSelectedOwner(parseInt(value));
  };

  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const response = await ApiManager.get("/User/GetAllOwners");
        setOwnerList(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (location.state) {
      setBusinessId(location.state.businessId);
      if (location.state.businessId) {
        setHeaderText("Përditësim");
        let endpoint = `/Business/BusinessById/${location.state.businessId}`;

        if (endpoint) {
          const fetchData = async () => {
            try {
              const response = await ApiManager.get(endpoint);
              setBusiness(response.data);
            } catch (error) {
              console.log(error);
            }
          };
          const fetchBusinessOwner = async () => {
            try {
              const response = await ApiManager.get(
                `/Business/GetOwnerOfBusiness/${location.state.businessId}`
              );
              setBusinessOwner(response.data);
            } catch (error) {
              console.log(error);
            }
          };
          fetchData();
          fetchOwners();
          fetchBusinessOwner();
        }
      } else {
        setHeaderText("Regjistrim");
      }
    }
    fetchOwners();
  }, [location.state]);

  const formik = useFormik({
    initialValues: {
      name: "",
      nub: "",
      email: "",
      phoneNumber: "",
      address: "",
      id: "",
      managerId: "",
      logoPath: "",
      accountName: "",
      accountNumber: "",
    },

    validate: (values) => validate(values),
    onSubmit: async (values) => {
      // setIsWaiting(true);
      if(document.querySelectorAll(".owner-error").length >= 1)
        return;
      values.managerId = selectedManagerId;
      if (selectedManagerId === 0) {
        var ownerId = document.getElementById("owner").value;
        if (ownerId === 0) return;
        values.managerId = ownerId;
      }

      var formData = new FormData();
      formData.append("id", values.id);
      formData.append("name", values.name);
      formData.append("address", values.address);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("nUB", values.nub);
      formData.append("email", values.email);
      formData.append("accountName", values.accountName);
      formData.append("accountNumber", values.accountNumber);
      formData.append("managerId", values.managerId);
      formData.append("imageFile", selectedImage);

      let endpoint;
      if (businessId !== 0) {
        endpoint = "/Business/EditBusiness";
      } else {
        endpoint = "/Business/CreateBusiness";
      }

      const response = await ApiManager.post(endpoint, formData);

      if (response.data.isCreated >= 1) {
        if (selectedOwner === 0) {
          if (businessId === 0) {
            var formData2 = new FormData();
              endpoint = "/Business/CreateBusinessAndManager";
              var ownerFirstName =
                document.getElementById("owner-firstName").value;
              var ownerLastName =
                document.getElementById("owner-lastName").value;
              var ownerEmail = document.getElementById("owner-email").value;
              var ownerPhoneNumber = document.getElementById(
                "owner-phoneNumber"
              ).value;
              formData2.append("businessId", response.data.businessId);
              formData2.append("firstName", ownerFirstName);
              formData2.append("lastName", ownerLastName);
              formData2.append("email", ownerEmail);
              formData2.append("phoneNumber", ownerPhoneNumber);

              ApiManager.post(
                `/User/CreateOwnerFromBusiness?jwtToken=${localStorage.getItem(
                  "token"
                )}`,
                formData2
              )
                .then((response2) => {
                  setIsWaiting(false);
                  if(response2) {
                    Swal.fire({
                      icon: "success",
                      title: `Biznesi dhe drejtori janë ${headerText
                        .slice(0, -2)
                        .toLowerCase()}uar me sukses`,
                      showConfirmButton: false,
                      timer: 2000,
                    }).then(() => {
                      navigation("/admin/business-list");
                    });
                  }
                })
                .catch(() => {
                  setIsWaiting(false);
                });
          }
        }
        else {
          setIsWaiting(false);
            Swal.fire({
              icon: "success",
              title: `Biznesi është ${headerText
                .slice(0, -2)
                .toLowerCase()}uar me sukses`,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              navigation("/admin/business-list");
            });
        }
      }
      else if (response.data.isCreated === 0) {
        setIsWaiting(false);
        Swal.fire({
          icon: "error",
          title: `Ndodhi nje gabim, provoni perseri me vone`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (response.data.isCreated === -1) {
        setIsWaiting(false);
        Swal.fire({
          icon: "error",
          title: `Ekziston nje biznes me kete email!`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (response.data.isCreated === -2) {
        setIsWaiting(false);
        Swal.fire({
          icon: "error",
          title: `Ekziston nje biznes me kete numer telefoni`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      setIsWaiting(false);
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      name: business?.name || "",
      nub: business?.nub || "",
      email: business?.email || "",
      phoneNumber: business?.phoneNumber || "",
      address: business?.address || "",
      id: business?.businessId || "",
      managerId: selectedManagerId || "",
      accountName: business?.accountName || "",
      accountNumber: business?.accountNumber || "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  const { touched, errors, handleSubmit } = formik;
  const handleAnulo = () => {
    navigation("/admin/business-list");
  };

  const renderProfileImage = () => {
    if (profilePicture) {
      // Make sure the profilePicture is not an object before using it
      return (
        <img
          src={`data:image/jpeg;base64,${profilePicture}`}
          alt="Profile Picture"
          className="w-[8rem]"
        />
      );
    } else {
      return <p>Nuk ka foto të profilit</p>;
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024 * 10) {
        Swal.fire({
          icon: "error",
          title: "Fotoja e profilit nuk mund te jete me e madhe se 10MB!",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      setSelectedImage(file);
      setUploadedImage(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <h3 className=" my-2 mb-4 text-xl font-bold text-navy-700 dark:text-white">
          {headerText} i biznesit
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Emri*"
                id="name"
                name="name"
                type="text"
                {...formik.getFieldProps("name")}
                state={errors.name && touched.name ? "error" : null}
              />
              {errors.name && touched.name ? (
                <ErrorLabel error={errors.name} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Numri unik i Biznesit*"
                type="text"
                id="nub"
                name="nub"
                {...formik.getFieldProps("nub")}
                state={errors.nub && touched.nub ? "error" : null}
              />
              {errors.nub && touched.nub ? (
                <ErrorLabel error={errors.nub} />
              ) : null}
            </div>
          </div>
          <div className="flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Email*"
                type="email"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
                state={errors.email && touched.email ? "error" : null}
              />
              {errors.email && touched.email ? (
                <ErrorLabel error={errors.email} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Numri i telefonit i biznesit"
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                {...formik.getFieldProps("phoneNumber")}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Adresa biznesit"
                type="tel"
                id="address"
                name="address"
                {...formik.getFieldProps("address")}
              />
            </div>
          </div>
          <div className="flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Emri i llogarisë"
                type="text"
                id="accountName"
                name="accountName"
                {...formik.getFieldProps("accountName")}
                state={
                  errors.accountName && touched.accountName ? "error" : null
                }
              />
              {errors.accountName && touched.accountName ? (
                <ErrorLabel error={errors.accountName} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Numri i llogarisë"
                type="text"
                id="accountNumber"
                name="accountNumber"
                {...formik.getFieldProps("accountNumber")}
                state={
                  errors.accountNumber && touched.accountNumber ? "error" : null
                }
              />
              {errors.accountNumber && touched.accountNumber ? (
                <ErrorLabel error={errors.accountNumber} />
              ) : null}
            </div>
          </div>
          <div>
            <div class="relative">
              <label>Drejtori i biznesit</label>
              <select
                id="owner"
                state={errors.name && touched.name ? "error" : null}
                onChange={(e) => changeCurrentManager(e.target.value)}
                class="focus:shadow-outline block w-full appearance-none rounded border border-gray-300 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
              >
                {businessId === 0 ? <option value="0">Shto</option> : null}
                {ownerList?.map((manager) => {
                  return businessOwner?.id === manager.id ? (
                    <option selected key={manager.id} value={manager.id}>
                      {manager.fullName}
                    </option>
                  ) : (
                    <option key={manager.id} value={manager.id}>
                      {manager.fullName}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="h-4 w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 14.707a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L10 12.586l-3.293-3.293a1 1 0 1 0-1.414 1.414l4 4z" />
                </svg>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 rounded-lg bg-white bg-clip-border">
              <div className="flex flex-col rounded-lg px-3 py-4">
                <InputField
                  onChange={handleImageChange}
                  variant="auth"
                  extra="mb-1 w-full"
                  label="Ngarko foton e biznesit"
                  id="profilePicture"
                  name="profilePicture"
                  type="file"
                />
              </div>
              <div className="place-self-center justify-self-center">
                {renderProfileImage()}
              </div>
            </div>
          </div>
          {businessId === 0 && selectedOwner === 0 ? <UpsertOwner /> : null}
          <div className="flex justify-around">
            <SpinnerButton title={"Ruaj të dhënat"} isWaiting={isWaiting} />
            <SpinnerButton
              title={"Anulo të dhënat"}
              isWaiting={isWaiting}
              secondary={true}
              onClick={() => handleAnulo()}
            />
          </div>
        </form>
      </Card>
    </>
  );
};

export default UpsertBusiness;
