import React, { useState, useEffect } from "react";
import { ApiManager } from "app/ApiManager";
import ErrorLabel from "components/error/ErrorLabel";
import InputField from "components/fields/InputField";
import { useFormik } from "formik";
import { optionsUnitOfMeasure } from "views/admin/stock/variables/SelectListOptions";
import SelectList from "./SelectList";
import Swal from "sweetalert2";

const AddExtraProductModal = ({ isOpen, onClose, onSubmitSuccess }) => {
  const [selectedUnitOfMeasure, setSelectedUnitOfMeasure] = useState(
    optionsUnitOfMeasure[0]
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      unitQuantity: null,
      priceOfExtra: "",
      extraProductId: 0,
      unit: selectedUnitOfMeasure.name,
      calories: "",
      cookingTime: "",
    },
    //  validate,
    onSubmit: async (values) => {
      const jwtToken = localStorage.getItem("token");

      try {
        if (values.unitQuantity <= 0) {
          throw new Error("Sasia duhet të jetë më e madhe se 0");
        }
        if (values.priceOfExtra < 0.05) {
          throw new Error("Çmimi duhet të jetë më i madh se 0.05");
        }
        if (values.calories < 0) {
          throw new Error("Kaloritë duhet të jenë më të mëdha se 0");
        }
        if (values.cookingTime < 0) {
          throw new Error("Koha e pregatitjes duhet të jetë më e madhe se 0");
        }

        const response = await ApiManager.post(
          "/ExtraProduct/AddExtraProduct",
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              jwtToken: jwtToken,
            },
          }
        );
        if (response.status === 200) {
          formik.setValues({
            name: "",
            unitQuantity: null,
            priceOfExtra: "",
            extraProductId: 0,
            unit: selectedUnitOfMeasure.name,
            calories: "",
            cookingTime: "",
          });
          Swal.fire({
            icon: "success",
            title: "Produkti ekstra eshte shtuar me sukses!",
            timer: 2500,
            confirmButtonText: "Në rregull"
          })
          .then(() => {
            onClose();
            onSubmitSuccess();
          })
        }
      } catch (error) {}
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      name: "",
      unitQuantity: null,
      priceOfExtra: "",
      extraProductId: 0,
      unit: selectedUnitOfMeasure.name || "",
      calories: "",
      cookingTime: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!isOpen) return null;

  const { touched, errors, handleSubmit } = formik;
  return (
    <>
      <div className="fixed inset-0 z-40 bg-opacity-20 backdrop-blur-sm"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto p-4">
        <div
          style={{ width: "60%" }}
          className="relative flex max-w-4xl flex-col justify-between rounded-lg bg-white p-4 shadow-lg"
        >
          <div>
            <div className="mb-5 flex flex-row-reverse items-center justify-between">
              <span className="">
                <button onClick={onClose} className="text-4xl font-medium">
                  &times;
                </button>
              </span>
              <h2 className="text-xl font-semibold">Shto produkt ekstra</h2>
            </div>
            <>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col justify-between gap-x-3 md:flex-row">
                  <div className="mb-3 w-full">
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label="Emërtimi*"
                      id="name"
                      name="name"
                      type="text"
                      {...formik.getFieldProps("name")}
                      state={errors.name && touched.name ? "error" : null}
                    />
                    {errors.name && touched.name ? (
                      <ErrorLabel error={errors.name} />
                    ) : null}
                  </div>
                  <div className="mb-3 w-full">
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label="Sasia*"
                      id="unitQuantity"
                      name="unitQuantity"
                      type="number"
                      min="0"
                      {...formik.getFieldProps("unitQuantity")}
                      state={
                        errors.unitQuantity && touched.unitQuantity
                          ? "error"
                          : null
                      }
                    />
                    {errors.unitQuantity && touched.unitQuantity ? (
                      <ErrorLabel error={errors.unitQuantity} />
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col justify-between gap-x-3 md:flex-row">
                  <div className="mb-3 w-full">
                    <SelectList
                      label="Njësia matëse e sasisë*"
                      selected={selectedUnitOfMeasure}
                      setSelected={setSelectedUnitOfMeasure}
                      options={optionsUnitOfMeasure}
                    />
                    {errors.unit && touched.unit ? (
                      <ErrorLabel error={errors.unit} />
                    ) : null}
                  </div>
                  <div className="mb-3 w-full">
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label="Kaloritë* (kCal)"
                      id="calories"
                      name="calories"
                      type="number"
                      {...formik.getFieldProps("calories")}
                      state={
                        errors.calories && touched.calories ? "error" : null
                      }
                    />
                    {errors.calories && touched.calories ? (
                      <ErrorLabel error={errors.calories} />
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col justify-between gap-x-3 md:flex-row">
                  <div className="mb-3 w-full">
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label="Koha e përgatitjes (në minuta)"
                      id="cookingTime"
                      name="cookingTime"
                      type="number"
                      {...formik.getFieldProps("cookingTime")}
                      state={
                        errors.cookingTime && touched.cookingTime
                          ? "error"
                          : null
                      }
                    />
                    {errors.cookingTime && touched.cookingTime ? (
                      <ErrorLabel error={errors.cookingTime} />
                    ) : null}
                  </div>
                  <div className="mb-3 w-full">
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label="Çmimi* (EUR)"
                      id="priceOfExtra"
                      name="priceOfExtra"
                      type="number"
                      {...formik.getFieldProps("priceOfExtra")}
                      state={
                        errors.priceOfExtra && touched.priceOfExtra
                          ? "error"
                          : null
                      }
                    />
                    {errors.priceOfExtra && touched.priceOfExtra ? (
                      <ErrorLabel error={errors.priceOfExtra} />
                    ) : null}
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    onClick={handleSubmit}
                    className="w-full rounded bg-blue-500 py-3 px-6 text-xl font-semibold text-white hover:bg-blue-700"
                  >
                    Shto
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddExtraProductModal;
