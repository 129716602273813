import React, { useEffect, useState } from "react";
import TableItem from "./TableItem";
import { ApiManager } from "app/ApiManager";
import { observer } from "mobx-react";
import { useStore } from "app/stores/store";
import SelectList from "views/admin/menu/components/SelectList";

const TablesList = observer(() => {
  const { ordersStore } = useStore();
  const [ambients, setAmbients] = useState([]);
  const [selectedAmbient, setSelectedAmbient] = useState("");
  const [test, setTest] = useState({value: '', name: 'Të gjitha'});

  useEffect(() => {
    ApiManager.get("/Ambient/GetAmbientsByBusinessId", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setAmbients(response.data);
      })
      .catch((error) => {
        console.error("Error fetching ambients:", error);
      });
  }, []);

  useEffect(() => {
    const endpoint = selectedAmbient
      ? `/Ambient/GetTablesByAmbient/${selectedAmbient}`
      : "/Table/GetTablesByBusinessId";

    ApiManager.get(endpoint, {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        ordersStore.setTables(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tables:", error);
      });
  }, [selectedAmbient, ordersStore]);
  const handleSelectChange = (selectedOption) => {
    const ambientId = selectedOption.value;
    setSelectedAmbient(ambientId);
    setTest(selectedOption);
  };

  const selectOptions = ambients.map((ambient) => ({
    value: ambient.ambientId,
    name: ambient.name,
  }));

  return (
 <>
  <div className="h-[37rem] flex flex-col rounded-xl bg-white p-4 pt-[15rem] pb-4 md:pt-[10rem] lg:pt-0">
    <div className="my-5">
      <label className="text-base">Zgjedh Ambientin tuaj</label>
      <SelectList
        selected={test}
        setSelected={handleSelectChange}
        options={[{ value: "", name: "Të gjitha" }, ...selectOptions]}
      />
    </div>
    <div className="mt-5 grid grid-cols-2 gap-3 overflow-auto md:grid-cols-5">
      {ordersStore.tables.map((table) => (
        <div key={table.tableId}>
          <TableItem data={table} />
        </div>
      ))}
    </div>
  </div>
</>

  
  );
});

export default TablesList;
