import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";

function CardMenu(props) {
  const { onPrint, stockType } = props;

  const navigation = useNavigate();
  const handleAddStock = () => {
    navigation("/admin/stock-upsert", {
      state: {
        stockType: stockType,
      },
    });
  };
  
  return (
    <div className="flex gap-5">
    <button
            onClick={onPrint}
            className="linear px-2 flex items-center justify-center gap-2 rounded-lg bg-red-500 text-white transition duration-200 hover:cursor-pointer hover:bg-red-400"
          >
            <span className="px-4 py-2 text-sm font-medium text-white">Print PDF</span>
          </button>
      <button
        onClick={() => handleAddStock()}
        className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg bg-lightPrimary
    px-3 py-2 text-sm text-gray-800 !transition !duration-200 
    hover:bg-gray-100 active:bg-gray-200"
      >
        <MdOutlineAdd className="h-5 w-5" /> Shto artikull në stok
      </button>
    </div>
  );
}

export default CardMenu;
