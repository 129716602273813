import React, { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import SelectList from "views/admin/menu/components/SelectList";
import { ApiManager } from "app/ApiManager";

const PrintReceipt = ({
  onChangeType,
  print,
  stockType,
  isOpen,
  onClose,
  handleSubmitSuccess,
  onChangeClient,
}) => {
  const [printOptions, setPrintOptions] = useState([
    {
      name: "Faturë",
      value: 1,
    },
    {
      name: "Profaturë",
      value: 2,
    },
  ]);
  const [selectedOption, setSelectedOption] = useState({
    name: "Faturë",
    value: 1,
  });
  const [clientOptions, setClientOptions] = useState([
    {
      name: "Shto",
      value: 0,
    },
  ]);
  const [selectedClient, setSelectedClient] = useState({
    name: "Shto",
    value: 0,
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const fetchClient = (client) => {
    ApiManager.get(`/Client/GetById`, {
      params: {
        clientId: client,
      },
    })
      .then((response) => {
        print(
          response.data.id,
          response.data.name,
          response.data.email,
          response.data.address,
          response.data.phoneNumber,
          response.data.accountName,
          response.data.accountNumber
        );
      })
      .catch();
  };

  const changeReceiptType = (value) => {
    setSelectedOption(value);
    onChangeType(value.name);
  };

  useEffect(() => {
    const fetchClients = async () => {
      const response = await ApiManager.get(`/Client/GetByBusinessId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      if (response.data.length >= 1) {
        onChangeClient(response.data[0]);
        setSelectedClient({
          name: response.data[0]?.name,
          value: response.data[0]?.id,
        });
        setClientOptions([
          { name: "Shto", value: 0 },
          ...response.data.map((client) => ({
            name: client.name,
            value: client.id,
          })),
        ]);
      }
    };
    fetchClients();
  }, []);

  const changeClient = (option) => {
    onChangeClient(option.value);
    setSelectedClient(option);
  };

  const handleSubmit = () => {
    if(document.querySelectorAll(".client-error").length >= 1)
      return;
    var endpoint =
      stockType.value === 1
        ? "/FifoStock/EmptyStock"
        : "/FiscalStock/EmptyStock";
    if (selectedClient.value === 0) {
      const client = {
        id: "",
        name: name,
        email: email,
        address: address,
        phoneNumber: phoneNumber,
        accountName: accountName,
        accountNumber: accountNumber,
      };
      console.log(client);
      ApiManager.post(`/Client/Create`, client, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.isCreated >= 1) {
            // ApiManager.post(endpoint)
            print(
              response.data.clientId,
              client.name,
              client.email,
              client.address,
              client.phoneNumber,
              client.accountName,
              client.accountNumber
            );
          }
        })
        .catch();
    } else {
      fetchClient(selectedClient.value);
    }
  };

  const changeInput = (fn, value) => {
    fn(value);
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-40 bg-opacity-20 backdrop-blur-sm"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto p-4">
        <div
          style={{ width: "60%" }}
          className="relative flex max-w-4xl flex-col justify-between rounded-lg bg-white p-4 shadow-lg"
        >
          <div>
            <div className="mb-5 flex flex-row-reverse items-center justify-between">
              <span className="">
                <button onClick={onClose} className="text-4xl font-medium">
                  &times;
                </button>
              </span>
              <h2 className="text-xl font-semibold">Printo Faturen</h2>
            </div>
            <>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col justify-between gap-x-3 md:flex-row">
                  <div className="mb-3 w-full">
                    <SelectList
                      label="Zgjedh mënyrën*"
                      selected={selectedOption}
                      setSelected={changeReceiptType}
                      options={printOptions}
                    />
                  </div>
                  <div className="mb-3 w-full">
                    <SelectList
                      label="Zgjedh klientin*"
                      selected={selectedClient}
                      setSelected={changeClient}
                      options={clientOptions}
                    />
                  </div>
                </div>
                {selectedClient.value === 0 ? (
                  <div>
                    <div className="flex flex-col justify-between gap-x-3 md:flex-row">
                      <div className="mb-3 w-full">
                        <InputField
                          onChange={(e) => changeInput(setName, e.target.value)}
                          variant="auth"
                          extra="mb-1"
                          label="Emri dhe Mbiemri*"
                          id="name"
                          name="name"
                          type="text"
                        />
                        {!/^[a-zA-Z\s]+$/.test(name) ? (
                          <div className="client-error text-sm text-red-400">
                            Vlera e shënuar duhet të përmbajë vetëm shkronja!
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 w-full">
                        <InputField
                          onChange={(e) =>
                            changeInput(setEmail, e.target.value)
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Email*"
                          id="email"
                          name="email"
                          type="text"
                        />
                        {!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                          email
                        ) ? (
                          <div className="client-error text-sm text-red-400">
                            Emaili i dhënë nuk është valid!
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-col justify-between gap-x-3 md:flex-row">
                      <div className="mb-3 w-full">
                        <InputField
                          onChange={(e) =>
                            changeInput(setPhoneNumber, e.target.value)
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Numri i telefonit*"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="text"
                        />
                        {!/^\d{9}$/.test(phoneNumber) ? (
                          <div className="client-error text-sm text-red-400">
                            Vlera e shënuar duhet të përmbajë vetëm 9 numra!
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 w-full">
                        <InputField
                          onChange={(e) =>
                            changeInput(setAddress, e.target.value)
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Adresa*"
                          id="address"
                          name="address"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col justify-between gap-x-3 md:flex-row">
                      <div className="mb-3 w-full">
                        <InputField
                          onChange={(e) =>
                            changeInput(setAccountName, e.target.value)
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Emri i llogarisë*"
                          id="accountName"
                          name="accountName"
                          type="text"
                        />
                      </div>
                      <div className="mb-3 w-full">
                        <InputField
                          onChange={(e) =>
                            changeInput(setAccountNumber, e.target.value)
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Numri i llogarisë*"
                          id="accountNumber"
                          name="accountNumber"
                          type="text"
                        />
                        {!/^\d{16}$/.test(accountNumber) ? (
                          <div className="client-error text-sm text-red-400">
                            Vlera e shënuar duhet të përmbajë vetëm 16 numra!
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="mt-4">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    style={{ backgroundColor: "rgb(59 130 246 / 1)" }}
                    className="w-full rounded bg-blue-500 py-3 px-6 text-xl font-semibold text-white hover:bg-blue-700"
                  >
                    Printo
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintReceipt;
