export const columnsData = [
  {
    Header: "EMRI I BIZNESIT",
    accessor: "name",
  },
  {
    Header: "NUMRI UNIK I BIZNESIT",
    accessor: "nub",
  },
  {
    Header: "ADRESA E BIZNESIT",
    accessor: "address",
  },
  {
    Header: "NR. TEL I BIZNESIT",
    accessor: "phoneNumber",
  },
  {
    Header: "EMAIL ADRESA E BIZNESIT",
    accessor: "email",
  },
];
