import React, { useState } from 'react';
import Card from 'components/card';
import InputField from "components/fields/InputField";
import { ApiManager } from 'app/ApiManager';
import Swal from 'sweetalert2';

const StockItemDetails = ({data}) =>{
  const [percentage, setPercentage] = useState(data.salePercentage);

  const changePercentage = (input) => {
    if(/^(100(?:\.0+)?|\d{0,2}(?:\.\d+)?|0(?:\.\d+)?)$/.test(input.target.value))
      setPercentage(input.target.value);
  }

  const handleEdit = () => {
    ApiManager.put(`/FifoStock/Edit?id=${data.id}&percentage=${percentage}`).then((response) => {
      if(response.data) {
        Swal.fire({
          icon: "success",
          title: "Përditësimi është bërë me sukses",
          timer: 2500,
          showConfirmButton: false
        }).then(() => {
          window.location.reload();
        })
      }
    })
  }
    return(
    <>
     <Card extra={"w-full h-full p-3 mt-5"}>
        
        <div className="w-full flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <h4 className="px-2 text-xl font-bold text-navy-700">
              {data.name}
            </h4>
            <button
              onClick={handleEdit}
              className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
              bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200"
            >
              Përditëso
              {/* <MdOutlineEdit className="h-5 w-5" /> */}
            </button>
          </div>
          <p className="px-2 text-base text-gray-700">
            {data.description}
          </p>
        </div>
        {/* Cards */}
        <div className="grid md:grid-cols-2 gap-4 mt-5">
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Emërtimi</p>
            <p className="text-base font-medium text-navy-700">
              {data.itemName}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Çmimi</p>
            <p className="text-base font-medium text-navy-700">
              {data.pricePerUnit?.toFixed(2)} €
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Sasia</p>
            <p className="text-base font-medium text-navy-700">
              {data.quantity}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Njësia matëse</p>
            <p className="text-base font-medium text-navy-700">
              {data.unitOfMeasure}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <InputField
                onChange={changePercentage}
                variant="auth"
                extra="mb-1"
                label="Përqindja*"
                id="itemName"
                name="itemName"
                type="text"
                value={percentage}
              />
          </div>
         
        </div>
      </Card>
    </>
    )
}

export default StockItemDetails;