import React, { useEffect, useState } from "react";
import { ApiManager } from "app/ApiManager";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "components/card";
import { MdOutlineEdit } from "react-icons/md";

const ExtraProductDetails = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { extraProductId } = location.state;
  const [extraProduct, setExtraProduct] = useState({});

  useEffect(() => {
    ApiManager.get(`/ExtraProduct/GetExtraProduct/${extraProductId}`)
      .then((response) => {
        setExtraProduct(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [extraProductId]);

  const handleEdit = () => {
    navigation("/admin/extraProduct-upsert", {
      state: {
        extraProductId: extraProductId,
      },
    });
  };

  const formatPrice = (price) => {
    return parseFloat(price).toFixed(2) + " €";
  };

  return (
    <>
      <Card extra={"w-full h-full p-3 mt-5"}>
        <div className="flex w-full flex-col">
          <div className="flex justify-between">
            <h4 className="px-2 text-xl font-bold text-navy-700">
              {extraProduct.name}
            </h4>
            <div className="flex gap-3">
              <button
                onClick={handleEdit}
                className="!linear active:bg-gray-2 z-[1] flex items-center justify-center gap-2
              rounded-lg bg-lightPrimary px-3 py-2 text-brand-500 !transition 
              !duration-200 hover:bg-gray-100"
              >
                Përditëso
                <MdOutlineEdit className="h-5 w-5 " />
              </button>
            </div>
          </div>
        </div>

        {/* Cards */}
        <div className="grid gap-4 md:grid-cols-2">
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 ">
            <p className="text-sm text-gray-800">Emërtimi</p>
            <p className="text-base font-medium text-navy-700">
              {extraProduct.name}
            </p>
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 ">
            <p className="text-sm text-gray-800">Çmimi</p>
            <p className="text-base font-medium text-navy-700">
              {formatPrice(extraProduct.priceOfExtra)}{" "}
            </p>
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 ">
            <p className="text-sm text-gray-800">Sasia</p>
            <p className="text-base font-medium text-navy-700">
              {extraProduct.unitQuantity}
            </p>
          </div>
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 ">
            <p className="text-sm text-gray-800">Njësia matëse</p>
            <p className="text-base font-medium text-navy-700">
              {extraProduct.unit}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ExtraProductDetails;
