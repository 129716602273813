import React, { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Switch from "components/switch";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import SelectList from "views/admin/menu/components/SelectList";
import Multiselect from "components/multiselect/Multiselect";
import ErrorLabel from "components/error/ErrorLabel";
import Swal from "sweetalert2";
import { validate } from "views/admin/menu/variables/ValidateFood";
import AddExtraProductModal from "views/admin/menu/components/AddExtraProductModal";
import { productOptions } from "../variables/ProductTypeOptions";

const AddProduct = ({ setExtra, setPreparedAt, setType }) => {
  const [showDiscountInputs, setshowDiscountInputs] = useState("hidden");
  const [switchChecked, setSwitchChecked] = useState(false);
  const [file, setFile] = useState();
  const [extraProducts, setExtraProducts] = useState();
  const [selectedExtraProducts, setSelectedExtraProducts] = useState([]);
  const options = [{ name: "Kuzhinë" }, { name: "Bar" }];
  const [selectedpreparedAt, setSelectedPreparedAt] = useState(options[0]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [productType, setProductType] = useState("Ushqim");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItemType, setSelectedItemType] = useState(productOptions[0]);

  const preparedAt = (value) => {
    setPreparedAt(value.name);
    setSelectedPreparedAt(value);
  };

  const type = (value) => {
    setType(value.name);
    setSelectedItemType(value);
  }

  const handleFileUpload = (e) => {
    const input = e.target;
    const selectedFile = input.files[0];

    // Check file type
    if (
      selectedFile.type !== "image/jpeg" &&
      selectedFile.type !== "image/png" &&
      selectedFile.type !== "image/webp"
    ) {
      Swal.fire({
        icon: "error",
        title: "Formati i fotove duhet të jetë jpeg ose png ose webp ",
        showConfirmButton: false,
        timer: 2500,
      });
      // Clear the selected file
      input.value = ""; // This will clear the selected file
      return;
    }

    // Check file size (e.g., 50MB limit)
    const maxFileSize = 50 * 1024 * 1024; // 50MB
    if (selectedFile.size > maxFileSize) {
      Swal.fire({
        icon: "error",
        title: "Madhësia e fotove duhet të jetë më e vogël se 50MB",
        showConfirmButton: false,
        timer: 2500,
      });
      // Clear the selected file
      input.value = ""; // This will clear the selected file
      return;
    }

    // Proceed with setting the file and creating a preview
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      id: 0,
      price: "",
      inDiscount: "",
      description: "",
      calories: "",
      cookingTime: "",
      startDate: "",
      endDate: "",
      productType: productType,
      preparedAt: selectedpreparedAt?.name,
      ImageFile: file,
      switchChecked: switchChecked,
    },
    validate: (values) => validate(values, switchChecked),
    onSubmit: (values) => {
      setIsWaiting(true);

      const formData = new FormData();
      if (file) {
        formData.append("ImageFile", file);
        formData.append("fileName", file.name);
      }
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      Object.keys(formik.values).forEach((key) => {
        if (key !== "ImageFile") {
          // Exclude the file since it's already added
          formData.append(key, formik.values[key]);
        }
      });

      // Append selectedExtraProducts to formData
      formData.append(
        "ExtraProductsJSON",
        JSON.stringify(selectedExtraProducts)
      );
    },
  });

  const updateExtraList = () => {
    var jwtToken = localStorage.getItem("token");
    ApiManager.get("/ExtraProduct/ListOfExtraProducts", {
      params: {
        jwtToken: jwtToken,
      },
    })
      .then((response) => {
        setExtraProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    var jwtToken = localStorage.getItem("token");
    ApiManager.get("/ExtraProduct/ListOfExtraProducts", {
      params: {
        jwtToken: jwtToken,
      },
    })
      .then((response) => {
        setExtraProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const { touched, errors, handleSubmit } = formik;
  return (
    <>
      <div>
        <div className="my-5 flex flex-row-reverse items-center justify-between">
          <span className=""></span>
          <h2 className="text-xl font-semibold">Produkti</h2>
        </div>
        <>
          <div className="flex flex-col justify-between gap-x-3 md:flex-row">
            <div className="flex w-full flex-col">
              <div className="mb-3 w-full">
                <SelectList
                  label="Lloji i produktit*"
                  selected={selectedItemType}
                  setSelected={type}
                  options={productOptions}
                />
              </div>
              <div className="mb-3 w-full">
                <InputField
                  variant="auth"
                  extra="mb-1"
                  label="Emërtimi*"
                  id="name"
                  name="name"
                  type="text"
                  {...formik.getFieldProps("name")}
                  state={errors.name && touched.name ? "error" : null}
                />
                {errors.name && touched.name ? (
                  <ErrorLabel error={errors.name} />
                ) : null}
              </div>

              <div className="mb-3 w-full">
                <InputField
                  variant="auth"
                  label="Çmimi për shitje (EUR)*"
                  type="number"
                  id="price"
                  name="price"
                  {...formik.getFieldProps("price")}
                  state={errors.price && touched.price ? "error" : null}
                />
                {errors.price && touched.price ? (
                  <ErrorLabel error={errors.price} />
                ) : null}
              </div>
            </div>
            <div className="mb-3 flex w-full flex-col">
              <TextField
                label="Përshkrimi*"
                id="description"
                name="description"
                rows="10"
                {...formik.getFieldProps("description")}
                state={
                  errors.description && touched.description ? "error" : null
                }
              />
              {errors.description && touched.description ? (
                <ErrorLabel error={errors.description} />
              ) : null}
            </div>
          </div>
          <div className="mt-5 flex flex-col justify-between gap-4 md:flex-row">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Kaloritë (kCal)"
                type="number"
                id="calories"
                name="calories"
                {...formik.getFieldProps("calories")}
                state={errors.calories && touched.calories ? "error" : null}
              />
              {errors.description && touched.description ? (
                <ErrorLabel error={errors.calories} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Koha e përgatitjes (në minuta)*"
                type="number"
                id="cookingTime"
                name="cookingTime"
                {...formik.getFieldProps("cookingTime")}
                state={
                  errors.cookingTime && touched.cookingTime ? "error" : null
                }
              />
              {errors.cookingTime && touched.cookingTime ? (
                <ErrorLabel error={errors.cookingTime} />
              ) : null}
            </div>
            <div className="flex w-full items-center p-2">
              <label
                htmlFor="checkbox"
                className="text-base font-medium text-navy-700"
              >
                Produkti a është në zbritje? &nbsp;
              </label>
              <Switch
                showDiscountInputs={showDiscountInputs}
                setshowDiscountInputs={setshowDiscountInputs}
                switchChecked={switchChecked}
                setSwitchChecked={setSwitchChecked}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between md:flex-row md:gap-4">
            <div className={`w-full ${showDiscountInputs}`}>
              <InputField
                variant="auth"
                label="Data e fillimit të zbritjes*"
                type="date"
                id="startDate"
                name="startDate"
                state={errors.startDate && touched.startDate ? "error" : null}
                {...formik.getFieldProps("startDate")}
              />
              {errors.startDate && touched.startDate ? (
                <ErrorLabel error={errors.startDate} />
              ) : null}
            </div>
            <div className={`w-full ${showDiscountInputs}`}>
              <InputField
                variant="auth"
                label="Data e mbarimit të zbritjes*"
                type="date"
                id="endDate"
                name="endDate"
                state={errors.endDate && touched.endDate ? "error" : null}
                {...formik.getFieldProps("endDate")}
              />
              {errors.endDate && touched.endDate ? (
                <ErrorLabel error={errors.endDate} />
              ) : null}
            </div>
          </div>
          <div className="mt-5 flex flex-col justify-between md:flex-row md:gap-4">
            <div className="w-full items-center">
              <div className="flex items-center gap-5">
                <label className={`text-sm font-medium text-navy-700`}>
                  Produktet ekstra
                </label>
                <div>
                  <button
                    onClick={openModal}
                    type="button"
                    className="rounded bg-primary"
                  >
                    <span className="px-2 text-white">+</span>
                  </button>
                  <span> (Shto produkt ekstra)</span>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-full">
                  <Multiselect
                    onChange={setExtra}
                    options={extraProducts}
                    setSelectedExtraProducts={setSelectedExtraProducts}
                    name="ExtraProducts"
                  />
                </div>
              </div>
            </div>
            <div className="w-full items-center">
              <SelectList
                label="Vendi përgatitjes"
                selected={selectedpreparedAt}
                setSelected={preparedAt}
                options={options}
              />
            </div>
          </div>

          <div className="mt-5 flex flex flex-col md:flex-row md:gap-4">
            <div className="mb-3 w-full">
              <InputField
                label="Foto e produktit"
                type="file"
                name="ImageFile" // This name should match the IFormFile property name in ProductVM
                id="ImageFile"
                state={errors.ImageFile && touched.ImageFile ? "error" : null}
                onChange={handleFileUpload}
                onBlur={formik.handleBlur}
              />
              {errors.ImageFile && touched.ImageFile ? (
                <ErrorLabel error={errors.ImageFile} />
              ) : null}
            </div>
            <div className="flex w-full items-center justify-center">
              {/* Show image preview if a new image is selected */}
              {file && (
                <div className="mt-2">
                  <img
                    src={imagePreviewUrl}
                    alt="Product Preview"
                    className="h-8 w-8 cursor-pointer rounded-full object-cover object-center md:h-20 md:w-20"
                  />
                </div>
              )}
            </div>
          </div>
          <AddExtraProductModal
            isOpen={modalOpen}
            onClose={closeModal}
            onSubmitSuccess={updateExtraList}
          />
        </>
      </div>
    </>
  );
};

export default AddProduct;
