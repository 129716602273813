import React, { createContext } from 'react';
import * as signalR from '@microsoft/signalr';

const SignalRConnectionContext = createContext(null);

class SignalRConnectionProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connection: null,  // Updated to include connection in state
      connectionStatus: 'initializing', // Updated initial status
      error: null
    };
  }

  componentDidMount() {
    this.initializeConnection();
  }

  initializeConnection() {
    const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.rectec.online' : 'http://localhost:5000';
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${baseUrl}/orderHub`, {
        withCredentials: false,
        transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling
      })
      .withAutomaticReconnect()
      .build();

    this.setState({ connection, connectionStatus: 'connecting' }); // Updated to set the connection and status in state

    connection.onclose(() => {
      this.setState({ connectionStatus: 'disconnected', error: null });
    });

    this.startConnection(connection); // Pass connection to start function
  }

  async startConnection(connection) { // Receive connection as parameter
    try {
      await connection.start();
      this.setState({ connectionStatus: 'connected', error: null });
    } catch (error) {
      this.setState({ connectionStatus: 'disconnected', error: error });
    }
  }

  render() {
    const { connection, connectionStatus, error } = this.state;
    const value = {
      connection,
      connectionStatus,
      error
    };

    return (
      <SignalRConnectionContext.Provider value={value}>
        {this.props.children}
      </SignalRConnectionContext.Provider>
    );
  }
}

export { SignalRConnectionProvider, SignalRConnectionContext };
