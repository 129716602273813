import React, { useEffect, useState } from "react";
import OwnerTable from "./components/OwnerTable";
import { columnsData } from "./variables/ColumnsData";
import { ApiManager } from "app/ApiManager";

const OwnerList = () => {
  const [ownerList, setOwnerList] = useState([]);
  useEffect(() => {
    ApiManager.get("/User/GetAllOwners")
      .then((response) => {
        setOwnerList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
        <OwnerTable
          columnsData={columnsData}
          tableData={ownerList}
          tableName="Lista e pronarëve"
        />
      </div>
    </>
  );
};

export default OwnerList;
