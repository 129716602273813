import React, { useMemo } from "react";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate } from "react-router-dom";

const ProductTable = (props) => {
  const { columnsData, tableData, tableName } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const navigation = useNavigate();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4"}>
        <header className="relative flex items-center justify-between">
          <h3 className="text-xl font-bold text-navy-700">
            {tableName}
          </h3>
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table
            {...getTableProps()}
            className="w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  <th
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start 
                  "
                  >
                    <div className="text-xs font-medium tracking-wide text-gray-800 lg:text-xs">
                      NR.
                    </div>
                  </th>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start 
                     "
                      key={index}
                    >
                      <div className="text-xs block w-max font-medium tracking-wide text-gray-800 lg:text-xs">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data.length !== 0 ? (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        navigation("/admin/product-details", {
                          state: {
                            productId: row.original.productId,
                            productType: row.original.productType,
                          },
                        });
                      }}
                      className="cursor-pointer hover:bg-blue-50 "
                      {...row.getRowProps()}
                    >
                      <td>
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 ">
                            {index + 1}
                          </p>
                        </div>
                      </td>
                      {row.cells.map((cell, index) => {
                        let data = "";
                        if (cell.column.Header === "ÇMIMI") {
                          data = (
                            <div className="flex items-center gap-2">
                              <p className="text-center text-sm font-bold text-navy-700">
                                {cell.value} €
                              </p>
                            </div>
                          );
                        } else if (cell.column.Header === "KALORITË") {
                          data = (
                            <div className="flex items-center gap-2">
                              <p className="text-sm font-bold text-navy-700">
                                {cell.value} kcal
                              </p>
                            </div>
                          );
                        } else if (cell.column.Header === "KOHA PËRGATITJES") {
                          data = (
                            <div className="flex items-center gap-2">
                              <p className="text-sm font-bold text-navy-700 ">
                                {cell.value} min
                              </p>
                            </div>
                          );
                        } else {
                          data = (
                            <div className="flex items-center gap-2">
                              <p className="text-sm font-bold text-navy-700 ">
                                {cell.value}
                              </p>
                            </div>
                          );
                        }

                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="pt-[14px] pb-[16px] sm:text-[14px]"
                          >
                            {data}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns.length + 1}
                    className="md:text-center text-sm font-bold text-navy-700 py-3"
                  >
                    Nuk ka produkte në këtë kategori
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
};

export default ProductTable;
