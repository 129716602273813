import React, { useEffect, useState } from "react";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react";
import OnlineItem from "./OnlineItem";

const OnlineCart = observer(({counterChange, businessId, forceRender, setForceRender }) => {
  const [cartItems, setCartItems] = useState([]);
  const { cartStore } = useStore();
  const [totalPrice, setTotalPrice] = useState(0);
  const { ordersStore } = useStore();
  const [changed, setChanged] = useState(0);

  useEffect(() => {
    const fetchCartForTable = () => {
      const tableCart = cartStore.getCartForOnline(businessId);
      setCartItems(tableCart);
      setTotalPrice(cartStore.totalPrice(tableCart));
    };

    fetchCartForTable();
  }, [cartStore.carts[businessId], forceRender, changed]);

  const handleOrderSaved = (businessId, orderData) => {
    console.log(orderData, "Data");
    // ordersStore.addOnlineOrder(orderData);
    setForceRender(prevState => !prevState);
  };
  const saveOrder = () => {
    cartStore.addOnlineOrder(businessId, handleOrderSaved, counterChange);
  };

  return (
    <div className="lg:mt-8 w-full rounded-xl bg-lightPrimary p-3 lg:w-2/4">
      <div className="mb-4 flex items-center justify-between md:flex-row">
        <span className="text-sm font-semibold text-navy-700 md:text-base">Totali i pagesës: </span>
        <span className="text-sm font-semibold text-navy-700 md:text-base">{totalPrice} €</span>
      </div>
      <div className="h-48 overflow-y-auto overflow-x-hidden md:h-60 lg:h-3/4">
        {cartItems && cartItems.length > 0 && (
          <div>
            {cartItems.map((cartItem) => (
              <OnlineItem
                key={`${cartItem.productType}-${cartItem.id}-${businessId}`}
                businessId={businessId}
                cartItem={cartItem}
                forceRender={forceRender}
                setForceRender={setForceRender}
                changeChanged={setChanged}
              />
            ))}
          </div>
        )}
      </div>
      {cartItems && cartItems.length > 0 && (
        <button
          className="btn btn-outline btn-success btn-sm mt-8 w-full lg:mt-16"
          onClick={saveOrder}
        >
          Bëje porosinë
        </button>
      )}
    </div>
  );
});
export default OnlineCart;
