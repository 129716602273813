import React, { useEffect, useState } from "react";
import { ApiManager } from "app/ApiManager";
import { columnsData } from "./variables/ColumnsData";
import RatingTable from "./components/RatingTable";

const RatingList = () => {
  const [ratings, setRatingList] = useState([]);

  useEffect(() => {
    ApiManager.get("/Rating/GetRatings", {
      params: { jwtToken: localStorage.getItem("token") },
    })
      .then((response) => {
        setRatingList(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
        <RatingTable
          columnsData={columnsData} 
          tableData={ratings}
          tableName="Lista e vlerësimeve"
        />
      </div>
      
    </>
  );
};

export default RatingList;
