import React from "react";
import InputField from "components/fields/InputField";
import { ApiManager } from "app/ApiManager";
import Swal from "sweetalert2";

const Supplier = ({ isOpen, onClose, onSubmitSuccess }) => {
  const handleSubmit = () => {
    const data = {
      id: 0,
      name: document.getElementById("supplier-name").value,
      nub: document.getElementById("supplier-nub").value,
      email: document.getElementById("supplier-email").value,
      personName: document.getElementById("supplier-manager").value,
      address: document.getElementById("supplier-address").value,
      phoneNumber: document.getElementById("supplier-phoneNumber").value,
      accountName: document.getElementById("supplier-accountName").value,
      accountNumber: document.getElementById("supplier-accountNumber").value,
      businessId: "",
    };
    ApiManager.post(
      `/Supplier/Create?jwtToken=${localStorage.getItem("token")}`,
      data
    )
      .then((response) => {
        if (response.data.isCreated >= 1) {
          Swal.fire({
            icon: "success",
            title: "Furnitori është shtuar me sukses!",
            timer: 2500,
            confirmButtonText: "Në rregull",
          }).then(() => {
            onSubmitSuccess();
            onClose();
          });
        } else if (response.data.isCreated === -1) {
          Swal.fire({
            icon: "error",
            title: "Ekziston një furnitor me këtë email!",
            timer: 2500,
            confirmButtonText: "Në rregull",
          });
        } else if (response.data.isCreated === -2) {
          Swal.fire({
            icon: "error",
            title: "Ekziston një furnitor me këtë numër telefoni",
            timer: 2500,
            confirmButtonText: "Në rregull",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ekziston një furnitor me këtë numër bankar",
            timer: 2500,
            confirmButtonText: "Në rregull",
          });
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  if (!isOpen) return null;
  return (
    <div className="mt-3">
      <div className="fixed inset-0 z-40 bg-opacity-20 backdrop-blur-sm"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto p-4">
        <div
          style={{ width: "60%" }}
          className="relative flex max-w-4xl flex-col justify-between rounded-lg bg-white p-4 shadow-lg"
        >
          <div className="mb-5 flex flex-row-reverse items-center justify-between">
            <span className="">
              <button onClick={onClose} className="text-4xl font-medium">
                &times;
              </button>
            </span>
            <h2 className="text-xl font-semibold">Shto furnitor</h2>
          </div>
          <div className="justify-between gap-x-3 md:flex">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Furnitori*"
                id="supplier-name"
                name="supplier-name"
                type="text"
              />
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Adresa*"
                id="supplier-address"
                name="supplier-address"
                type="text"
              />
            </div>
          </div>
          <div className="justify-between gap-x-3 md:flex">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Numri i telefonit*"
                id="supplier-phoneNumber"
                name="supplier-phoneNumber"
                type="number"
              />
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Email*"
                id="supplier-email"
                name="supplier-email"
                type="text"
              />
            </div>
          </div>
          <div className="justify-between gap-x-3 md:flex">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Menaxheri*"
                id="supplier-manager"
                name="supplier-manager"
                type="text"
              />
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Numri unik i biznesit*"
                id="supplier-nub"
                name="supplier-nub"
                type="number"
              />
            </div>
          </div>
          <div className="justify-between gap-x-3 md:flex">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Emri i llogarisë*"
                id="supplier-accountName"
                name="supplier-accountName"
                type="text"
              />
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Numri i llogarisë bankare*"
                id="supplier-accountNumber"
                name="supplier-accountNumber"
                type="number"
              />
            </div>
          </div>
          <div className="mt-4">
            <button
              onClick={handleSubmit}
              className="w-full rounded bg-blue-500 py-3 px-6 text-xl font-semibold text-white hover:bg-blue-700"
            >
              Shto
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Supplier;
