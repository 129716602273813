export const validate = (values, file, switchChecked) => {

  const requiredErrorMessage = "Kjo fushë është e kërkuar!";
  const errors = {};
  if (!values.firstName) {
    errors.firstName = requiredErrorMessage;
  } else if (values.firstName.length < 4 || values.firstName.length > 10) {
    errors.firstName = "Numri i karaktereve duhet të jetë mes 3 dhe 10!";
  } else if (!/^[a-zA-Z\s]+$/.test(values.firstName)) {
    errors.firstName = "Vlera e shënuar duhet të përmbajë vetëm shkronja!";
  }
  if (!values.lastName) {
    errors.lastName = requiredErrorMessage;
  } else if (values.lastName.length < 4 || values.lastName.length > 10) {
    errors.lastName = "Numri i karaktereve duhet të jetë mes 3 dhe 10!";
  } else if (!/^[a-zA-Z\s]+$/.test(values.lastName)) {
    errors.lastName = "Vlera e shënuar duhet të përmbajë vetëm shkronja!";
  }
  if (!values.email) {
    errors.email = requiredErrorMessage;
  } else if (
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.email)
  ) {
    errors.email = "Emaili i dhënë nuk është valid!";
  }
  return errors;
};
