import React, { useEffect, useState } from "react";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react";
import OrderStatus from "./OrderStatus";
import { toJS } from 'mobx';

const ChefOrders = observer((props) => {
  const { ordersStore } = useStore();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await ordersStore.getUnfinishedAllOrderItems();
        await ordersStore.getUnfinishedAllOnlineOrderItems();
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [ordersStore, props.count]);

  const filterUniqueItems = (array) => {
    const uniqueItems = array.reduce((acc, current) => {
      const existingItem = acc.find(item => item.id === current.id);
      if (existingItem) {
        if (current.onlineOrder) existingItem.onlineOrder = current.onlineOrder;
        if (current.order) existingItem.order = current.order;
      } else {
        acc.push({ ...current });
      }
      return acc;
    }, []);
    return uniqueItems;
  };

  useEffect(() => {
    if (!loading) {
      var allOrders = [...ordersStore.allOrderItems, ...ordersStore.allOnlineOrderItems];
      var filteredOrders = filterUniqueItems(allOrders);
      setOrders(filteredOrders)
      props.changeOrderCount(ordersStore.allOrderItems.length + ordersStore.allOnlineOrderItems.length);
    }
  }, [loading, ordersStore.allOrderItems, ordersStore.allOnlineOrderItems]);

  if (loading) {
    return (
      <div className="mt-6 flex h-full w-full justify-center rounded-xl bg-white py-6">
        <span className="loading loading-dots loading-lg"></span>
      </div>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
  
    const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
  
    return `${formattedDate} - ${formattedTime}`;
  };

  return (
    <>
      {orders && orders.length > 0 ? (
        <div className="mt-6 h-full overflow-x-auto rounded-xl bg-white p-3" style={{paddingTop:'75px', paddingBottom:'75px'}}>
          <table className="table">
            <thead>
              <tr className="text-center text-base text-gray-800">
                <th>Nr.</th>
                <th>Tavolina</th>
                <th>Ushqimi</th>
                <th>Komenti</th>
                <th>Pagesa</th>
                <th>Lloji i produktit</th>
                <th>Koha e porositjes</th>
                <th>Statusi</th>
                <th>Menaxhimi</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr className="text-center" key={index}>
                  <th>{index + 1}</th>
                  <td>{order.order?.table ? order.order?.table.name : "Online"}</td>
                  <td>{order.name}</td>
                  <td className="" style={{ display: "table-cell",overflowWrap: "anywhere", width: "300px", overflowY: "auto", overflowX: "hidden", textAlign: "center", maxHeight: "400px" }}><p className="text-center text-sm  lg:text-base" style={{width:"inherit"}}>{order.comment}</p></td>
                  <td className="">{order.price} €</td>
                  <td className="">
                    {order.productType === "Food"
                      ? "Ushqim"
                      : order.productType === "Drink"
                      ? "Pije"
                      : "Desert"}{" "}
                  </td>
                  <td className="">
                    {order.order ? formatDate(order.order?.orderedAt) : formatDate(order.onlineOrder?.orderedAt)}
                  </td>
                  <td className="table-cell ">
                    <p
                      className={`rounded-lg p-2  text-sm w-[72px] xl:w-full  lg:test-base${
                        order.status === "Përfunduar"
                          ? " border-[#ffd746] bg-[#edffed] text-[#63b363]"
                          : order.status === "Në përgatitje"
                          ? "border-[#98ff98] bg-[#fff6e6] text-[#f0a76f]"
                          : "border-[#ababab] bg-[#ededed] text-[#ababab]"
                      }`}
                    >
                      {order.status === "Në përgatitje" ? "Në përgatitje" : order.status}
                    </p>
                  </td>
                  <td className="text-center">
                    <OrderStatus isOnline={order.order?.table ? false : true} order={order} changeCount={props.changeOrderCount}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mt-6 flex h-full w-full justify-center rounded-xl bg-white py-6">
          <p className="font-bold">Nuk ka ende porosi!</p>
        </div>
      )}
    </>
  );
});

export default ChefOrders;
