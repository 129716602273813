import React ,{useContext, useEffect}from "react";
import { useStore } from "app/stores/store";
import { MdOutlineDone, MdAdsClick, MdRotateLeft } from "react-icons/md";
import { Dropdown } from "flowbite-react";



const OrderStatus = ({ isOnline, order, changeCount }) => {
  const { ordersStore } = useStore();
  const handleStatusChange = (status, orderId) => {
    console.log(isOnline);
    changeCount();
    if(isOnline) {
      ordersStore.changeOnlineOrderItemStatus(status, orderId);
    }
    else
      ordersStore.changeOrderItemStatus(status, orderId);
  };
  


  return (
    <Dropdown label="" arrowIcon={false} renderTrigger={() => <span className={" cursor-pointer table-cell rounded-md bg-[#e2f0fb]  py-2 px-1 text-[#5296cf] md:block"} >Ndrysho statusin</span>}>
      <Dropdown.Item onClick={() => handleStatusChange("Në përgatitje", order.id)} className="text-yellow-500">
        <MdAdsClick className="h-5 w-5" />
        Në përgatitje
      </Dropdown.Item>
      <Dropdown.Item onClick={() => handleStatusChange("Përfunduar", order.id)} className="text-green-500">
        <MdOutlineDone className="h-5 w-5" />
          Përfunduar
      </Dropdown.Item>
      <Dropdown.Item onClick={() => handleStatusChange("Në pritje", order.id)} className="">
        <MdRotateLeft className="h-5 w-5" />
          Pritje
      </Dropdown.Item>
    </Dropdown>
    // <div className="dropdown dropdown-end">
    //   <button
    //     tabIndex={0}
    //     className="cursor-pointer rounded-md bg-[#e2f0fb] px-4 py-2 text-[#5296cf]"
    //   >
    //     Ndrysho statusin
    //   </button>
    //   <ul
    //     tabIndex={0}
    //     className="menu dropdown-content rounded-box z-[1] mt-1 w-48 
    //   bg-base-100 p-2 text-yellow-500 shadow"
    //   >
    //     <li>
    //       <button
    //         className=""
    //         onClick={() => handleStatusChange("Pranuar", order.id)}
    //       >
    //         <MdAdsClick className="h-5 w-5" />
    //         Pranuar
    //       </button>
    //     </li>
    //     <li className="">
    //       <button
    //         className="text-green-500"
    //         onClick={() => handleStatusChange("Përfunduar", order.id)}
    //       >
    //         <MdOutlineDone className="h-5 w-5" />
    //         Përfunduar
    //       </button>
    //     </li>
    //     <li className="">
    //       <button
    //         className="text-gray-700"
    //         onClick={() => handleStatusChange("Në pritje", order.id)}
    //       >
    //         <MdRotateLeft className="h-5 w-5" />
    //         Pritje
    //       </button>
    //     </li>
    //   </ul>
    // </div>
  );
};

export default OrderStatus;
