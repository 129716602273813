import authImg from "assets/img/auth/auth.jpg";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          //  auth/sign-in
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white ">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div
              className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 
            md:max-w-[75%] lg:h-screen lg:max-w-[1013px] 
            lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]"
            >
              <div
                className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] 
              lg:pl-0 xl:max-w-full"
              >
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="admin/default" replace />}
                  />
                </Routes>
                <div className="absolute right-0 hidden h-full min-h-screen border-0 md:block lg:w-[40.5vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center border-t-0 
                     border-r-0 bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
