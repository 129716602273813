import { ApiManager } from "app/ApiManager";
import React, { useEffect, useState } from "react";
import WaiterCart from "./cart/WaiterCart";
import ProductCartButton from "./cart/ProductCartButton";

const TableModal = ({ table }) => {
  const [foods, setFoods] = useState([]);
  const [drinks, setDrinks] = useState([]);
  const [desserts, setDesserts] = useState([]);
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    ApiManager.get("/Food/GetFoodByBusinessId", {
      params: {
        jwtToken: token,
      },
    }).then((response) => {
      setFoods(response.data);
    });

    ApiManager.get("/Drink/GetDrinksByBusinessId", {
      params: {
        jwtToken: token,
      },
    }).then((response) => {
      setDrinks(response.data);
    });

    ApiManager.get("/Dessert/GetDessertsByBusinessId", {
      params: {
        jwtToken: token,
      },
    }).then((response) => {
      setDesserts(response.data);
    });
  }, []);

  return (
    <>
      <dialog
        id={`my_modal_${table.tableId}`}
        className="modal"
        key={table.tableId}
      >
        <form
          method="dialog"
          className="relative flex flex-col lg:flex-row max-h-[90vh] xl:h-4/6 2xl:h-5/6 gap-6 overflow-y-auto rounded-xl bg-white p-4 w-11/12 md:w-9/12"
        >
          <button className="btn btn-sm btn-circle absolute top-[0.5rem] right-2">
            ✕
          </button>
          <div className="mt-8 py-2 overflow-y-auto overflow-x-hidden grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4">
            {foods &&
              foods.length > 0 &&
              foods.map((food) => (
                <ProductCartButton
                  key={food.foodId}
                  product={food}
                  id={food.foodId}
                  tableId={table.tableId}
                  setForceRender={setForceRender}
                  forceRender={forceRender}
                />
              ))}
            {drinks &&
              drinks.length > 0 &&
              drinks.map((drink) => (
                <ProductCartButton
                  key={drink.drinkId}
                  product={drink}
                  id={drink.drinkId}
                  tableId={table.tableId}
                  setForceRender={setForceRender}
                  forceRender={forceRender}
                />
              ))}
            {desserts &&
              desserts.length > 0 &&
              desserts.map((dessert) => (
                <ProductCartButton
                  key={dessert.dessertId}
                  product={dessert}
                  id={dessert.dessertId}
                  tableId={table.tableId}
                  setForceRender={setForceRender}
                  forceRender={forceRender}
                />
              ))}
          </div>
          <WaiterCart
            tableId={table.tableId}
            forceRender={forceRender}
            setForceRender={setForceRender}
          />
        </form>
      </dialog>
    </>
  );
};

export default TableModal;
