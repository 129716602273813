import React, { useEffect, useState } from "react";
import { columnsData } from "./variables/columnsData";
import { ApiManager } from "app/ApiManager";
import ExtraProductTable from "./components/ExtraProductTable";

const ExtraProductList = () => {
  const [extraProductList, setExtraProductList] = useState([]);

  useEffect(() => {
    var jwtToken = localStorage.getItem("token");
    ApiManager.get("/ExtraProduct/ListOfExtraProducts", {
      params: {
        jwtToken: jwtToken,
      },
    })
      .then((response) => {
        setExtraProductList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
        <ExtraProductTable
          columnsData={columnsData}
          tableData={extraProductList}
          tableName="Lista e produkteve shtesë"
        />
      </div>
    </>
  );
};

export default ExtraProductList;
