import React, { useEffect, useState } from "react";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react";

const ChefOrders = observer(() => {
  const { ordersStore } = useStore();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await ordersStore.getFinishedAllOrderItems();
        await ordersStore.getFinishedAllOnlineOrderItems();
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [ordersStore]);

  useEffect(() => {
    if (!loading) {
      setOrders(ordersStore.finishedOrderItems);
    }
  }, [loading, ordersStore.finishedOrderItems]);

  if (loading) {
    return (
      <div className="mt-6 flex h-full w-full justify-center rounded-xl bg-white py-6">
        <span className="loading loading-dots loading-lg"></span>
      </div>
    );
  }

  return (
    <>
      {orders && orders.length > 0 ? (
        <div className="mt-6 h-full rounded-xl bg-white p-3 overflow-x-auto">
          <table className="table">
            <thead>
              <tr className="text-center text-base text-gray-800">
                <th>Nr.</th>
                <th>Tavolina</th>
                <th>Ushqimi</th>
                <th>Komenti</th>
                <th>Pagesa</th>
                <th>Lloji i produktit</th>
                <th>Statusi</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr className="text-center" key={order.id}>
                  <th>{index + 1}</th>
                  <td>{order.order?.table ? order.order?.table.name : "Online"}</td>
                  <td>{order.name}</td>
                  <td className="" style={{ display: "table-cell",overflowWrap: "anywhere", width: "400px", overflowY: "auto", overflowX: "hidden", textAlign: "center", maxHeight: "400px" }}><p className="text-center text-sm  lg:text-base" style={{width:"inherit"}}>{order.comment}</p></td>
                  <td className="">{order.price} €</td>
                  <td className="">
                    {order.productType === "Food"
                      ? "Ushqim"
                      : order.productType === "Drink"
                      ? "Pije"
                      : "Desert"}{" "}
                  </td>
                  <td className="table-cell ">
                    <p
                      className={`rounded-lg p-2  ${
                        order.status === "Përfunduar"
                          ? " border-[#ffd746] bg-[#edffed] text-[#63b363]"
                          : order.status === "Në përgatitje"
                          ? "border-[#98ff98] bg-[#fff6e6] text-[#f0a76f]"
                          : "border-[#ababab] bg-[#ededed] text-[#ababab]"
                      }`}
                    >
                      {order.status}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mt-6 flex h-full w-full justify-center rounded-xl bg-white py-6">
          <p className="font-bold">Nuk ka ende porosi!</p>
        </div>
      )}
    </>
  );
});

export default ChefOrders;
