import React, { useState, useEffect } from "react";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Notification from "./Notification";
import { ApiManager } from "app/ApiManager";
import jwt_decode from "jwt-decode";
import OnlineOrder from "./OnlineOrder";
import OnlineList from "./cart/OnlineOrders/OnlineList";

const Navbar = () => {
  const navigation = useNavigate();
  const [fullName, setFullName] = useState("");
  const [orderCount, setOrderCount] = useState(0);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigation("/auth/sign-in");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const role = decodedToken.Role;
      if (role === "Menaxher") {
        navigation("/admin/panel");
      } else if (role === "Kamarier") {
        navigation("/waiter/panel");
      } else if (role === "Kuzhinier") {
        navigation("/chef/panel");
      }
    } else {
      navigation("/auth/sign-in");
    }
    ApiManager.get("/User/GetCurrentUserFullName", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setFullName(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigation]);

  const handleOrderAssist = () => {
    var modal = document.getElementById(`online-order`);
    modal.showModal();
  };

  const handleViewOnlineOrders = () => {
    var modal = document.getElementById(`online-list`);
    modal.showModal();
  };

  const changeOrderCount = () => {
    setOrderCount((prev) => (prev += 1));
  };

  return (
    <div
      className="bg-lighPrimary fixed flex flex-col gap-10 rounded-xl bg-white text-xl lg:static w-full p-3"
    >
      <div className="flex items-center justify-between gap-4">
        <div>
          <h3 className="text-md font-medium text-gray-800">{fullName}</h3>
        </div>

        <div className="flex gap-2">
          <Notification />
          <button className="btn btn-outline btn-error" onClick={handleLogout}>
            <MdLogout className="h-6 w-6 " />
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-3 md:flex-row md:justify-end">
        <div className="flex flex-col">
          <button
            className="btn btn-outline btn-success btn-md"
            onClick={() => handleViewOnlineOrders()}
          >
            Shiko porositë online
          </button>
          <OnlineList counter={orderCount} />
        </div>
        <div className="flex flex-col">
          <button
            className="btn btn-outline btn-success btn-md"
            onClick={() => handleOrderAssist()}
          >
             Bëj porosi online
          </button>
          <OnlineOrder changeCounter={changeOrderCount} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
