import React, { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import avatar from "assets/img/avatars/user-avatar.png";
import { ApiManager } from "app/ApiManager";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const Navbar = (props) => {
  const navigation = useNavigate();
  const { onOpenSidenav, brandText, changeSelectedBusiness } = props;
  const [fullName, setFullName] = useState("");
  const [user, setUser] = useState({});
  const [profilePicture, setProfilePicture] = useState(null);
  const [role, setRole] = useState("");
  const [businessList, setBusinessList] = useState();
  const [selectedBusiness, setSelectedBusiness] = useState(() => {
    return localStorage.getItem("selectedBusiness" || 0);
  });

  useEffect(() => {
    const selectedBusiness = localStorage.getItem("selectedBusiness");
    if (selectedBusiness) setSelectedBusiness(selectedBusiness);
    else localStorage.setItem("selectedBusiness", 0);
    const jwtToken = localStorage.getItem("token");
    const decodedToken = jwtDecode(jwtToken);
    setRole(decodedToken.Role);
    ApiManager.get(`/Business/GetOwnerBusinesses/${decodedToken.sub}`)
      .then((response) => {
        setBusinessList(response.data);
      })
      .catch((error) => {});
    ApiManager.get("/User/GetCurrentUserFullName", {
      params: {
        jwtToken: jwtToken,
      },
    })
      .then((response) => {
        setFullName(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = () => {
    const jwtToken = localStorage.getItem("token");
    ApiManager.get("/User/ProfileOfUser", {
      params: {
        jwtToken: jwtToken,
      },
    })
      .then((response) => {
        setUser(response.data);
        if (response.data.base64Image) {
          setProfilePicture(response.data.base64Image);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch user profile:", error);
      });
  };

  const changeBusiness = (value) => {
    changeSelectedBusiness(value);
    localStorage.setItem("selectedBusiness", value);
    if(process.env.NODE_ENV == "production") {
      window.location.reload();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigation("/auth/sign-in");
  };

  return (
    <nav className="sticky z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 pt-5 backdrop-blur-xl">
      <div>
        <p className="">
          <Link to="#" className="font-semibold hover:text-navy-700 text-xl md:text-2xl lg:text-3xl">
            {brandText}
          </Link>
        </p>
      </div>
      <div className="flex items-center gap-5">
        {role === "Drejtor" ? (
          <select
            onChange={(e) => changeBusiness(e.target.value)}
            style={{ width: "70%", borderRadius: "10px" }}
            id="owner"
            class="focus:shadow-outline block w-full appearance-none rounded border border-gray-300 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
          >
            {selectedBusiness === 0 ? (
              <option value="0">Të gjitha bizneset</option>
            ) : (
              <option selected value="0">
                Të gjitha bizneset
              </option>
            )}
            {businessList
              ? Object.entries(businessList)?.map(([key, value]) => {
                  return selectedBusiness === key ? (
                    <option selected key={key} value={key}>
                      {value}
                    </option>
                  ) : (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  );
                })
              : null}
          </select>
        ) : null}
        <div className="flex flex-row-reverse items-center gap-3 relative dark:!bg-navy-800 dark:shadow-none">
          <span
            className="flex cursor-pointer text-xl text-gray-600 xl:hidden"
            onClick={onOpenSidenav}
          >
            <FiAlignJustify className="h-8 w-8" />
          </span>
          {/* Notification */}
          {/* <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 " />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 ">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 ">
                  Njoftime
                </p>
                <p className="text-sm font-bold text-navy-700 ">
                  Shënoni të gjitha të lexuara
                </p>
              </div>

                <button className="flex flex-row justify-start items-center gap-2 pt-5">
                  <div
                    className="flex h-8 w-8 items-center justify-center rounded-lg
                bg-gradient-to-b from-brandLinear to-brand-500 text-xl text-white"
                  >
                    <IoMdNotificationsOutline />
                  </div>

                  <p className="text-sm font-medium text-gray-800">
                    Nuk ka njoftime të reja
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        /> */}

          {/* Profile & Dropdown */}

          <Dropdown
            button={
              <img
                src={
                  profilePicture
                    ? `data:image/jpeg;base64,${profilePicture}`
                    : avatar
                }
                alt="Profile Picture"
                className="h-8 w-8 md:h-12 md:w-12 cursor-pointer rounded-full object-cover object-center"
              />
            }
            children={
              <div
                className="z-50 flex flex-col justify-start gap-4 rounded-xl bg-white bg-cover bg-no-repeat 
            p-4 shadow-xl shadow-shadow-500"
              >
                <div className="flex flex-col items-center">
                  <p className="text-base font-semibold text-navy-700">
                    Çkemi, {fullName} 👋
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <Link
                    to={"/admin/profile"}
                    className="text-base text-gray-800"
                  >
                    Profili
                  </Link>

                  <button
                    onClick={handleLogout}
                    className="text-start text-base font-medium text-red-500 hover:text-red-500"
                  >
                    Dilni
                  </button>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
