import { createContext, useContext } from "react";
import UpsertProductStore from "./menu/UpsertProductStore";
import OrdersStore from "./menu/OrdersStore";
import CartStore from "./waiterOrdering/CartStore";

export const store = {
  upsertProductStore: new UpsertProductStore(),
  ordersStore: new OrdersStore(),
  cartStore: new CartStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
