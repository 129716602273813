import React  from "react";
import { useRef } from "react";
import handClickIcon from "../../../assets/img/layout/hand-click-icon.png";
import TableModal from "./TableModal";

const WaiterOrderAssist = ({ table }) => {
  const waiterOrderAssistRef = useRef(null);
  const handleOrderAssist = (tableId) => {
    var modal = document.getElementById(`my_modal_${tableId}`);
    modal.showModal();

    if (waiterOrderAssistRef.current) {
      waiterOrderAssistRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <div id={`waiterOrderAssist_${table.tableId}`} ref={waiterOrderAssistRef}>
      <p className="text-center text-lg font-semibold text-navy-700">
        {table.name} nuk ka ende porosi!
      </p>
      <div className="flex flex-col justify-center items-center gap-4 mt-4">
        <img src={handClickIcon} className="w-24" alt="" />
        <button
          className="btn btn-outline btn-success btn-md"
          onClick={() => handleOrderAssist(table.tableId)}
        >
          Bëj porosinë
        </button>
      </div>
      <TableModal table={table} />
    </div>
  );
};

export default WaiterOrderAssist;
