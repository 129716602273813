import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";

function CardMenu(props) {
  const { transparent, stockType } = props;
  const [open, setOpen] = React.useState(false);
  const navigation = useNavigate();
  const handleAddExtraProduct = () => {
    navigation("/admin/extraProduct-upsert");
  };

  return (
    <>
      <button
        onClick={() => handleAddExtraProduct()}
        className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg bg-lightPrimary
px-3 py-2 text-sm text-gray-800 !transition !duration-200 
hover:bg-gray-100 active:bg-gray-200"
      >
        <MdOutlineAdd className="h-5 w-5" /> Shto produkt shtesë
      </button>
    </>
  );
}

export default CardMenu;
