import { ApiManager } from "app/ApiManager";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SignalRConnectionContext } from "app/SignalRConnectionProvider";
import { useStore } from "app/stores/store";
import OnlineOrder from "./OnlineOrder";
import { observer } from "mobx-react";

const OnlineList = observer(({ counter }) => {
  const [onlineOrders, setOnlineOrders] = useState([]);
  const [paidCount, setPaidCount] = useState(0);
  const { ordersStore } = useStore();
  const { connection, connectionStatus } = useContext(SignalRConnectionContext);
  const [updatedItems, setUpdatedItems] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchOnlineOrders = async () => {
      const response = await ApiManager.get(`/OnlineOrder/Orders`, {
        params: {
          jwtToken: token,
        },
      });
      setOnlineOrders(response.data);
    };
    fetchOnlineOrders();
    if (connection && connectionStatus === "connected") {
      try {
        connection.on("ReceiveOnlineOrderStatus", (newStatus, id) => {
          setUpdatedItems((prev) => ({ ...prev, [id]: newStatus }));
          ordersStore.refreshUI("online");
          fetchOnlineOrders();
          if(newStatus === "Përfunduar") {
            ApiManager.get("/Order/PlayKitchen")
            .catch(() => {});
          }
        });
      } catch (error) {
        console.error("Error handling real-time order:", error);
      }

      return () => {
        connection.off("ReceiveOnlineOrderStatus");
      };
    }
  }, [counter, paidCount, connection, connectionStatus, ordersStore]);

  const accordionSwitch = (value) => {
    const accordion = document.getElementById(`switch-${value}`);
    if (accordion.classList.contains("hidden"))
      accordion.classList.remove("hidden");
    else accordion.classList.add("hidden");
  };

  const payOrder = (orderId, isFifo) => {
    var body = {
      orderId: orderId,
      jwtToken: localStorage.getItem("token"),
    };
    ApiManager.post(`/OnlineOrder/PayOrder`, body, {
      params: {
        isFifo: isFifo,
        jwtToken: localStorage.getItem("token"),
      }
    })
      .then((response) => {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Pagesa është kryer me sukses",
            confirmButtonText: "Në rregull",
            timer: 3000,
          }).then(() => {
            setPaidCount((prev) => (prev += 1));
            var modal = document.getElementById("online-list");
            modal.showModal();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ndodhi një gabim, ju lutem provoni më vonë",
            confirmButtonText: "Në rregull",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Ndodhi një gabim, ju lutem provoni më vonë",
          confirmButtonText: "Në rregull",
          timer: 3000,
        });
      });
  };

  return (
    <>
      {/* Modal Content Here */}
      <dialog id={`online-list`} className="modal">
        <form
          method="dialog"
          className="relative flex h-screen w-11/12 gap-6 overflow-hidden rounded-xl bg-white p-2 md:p-4 lg:w-3/5"
          style={{ height: "85vh" }}
        >
          <button className="btn btn-sm btn-circle absolute top-[0.5rem] right-2 mb-4">
            ✕
          </button>
          <div
            className="flex w-full flex-col gap-5 overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 4rem)" }}
          >
            <h1 className="text-lg font-semibold text-gray-800">
              Porositë online
            </h1>
            <div
              className="flex flex-col gap-5"
              style={{ paddingRight: onlineOrders.length >= 10 ? "16px" : "" }}
            >
              {onlineOrders && onlineOrders.length > 0 ? (
                onlineOrders.map((order) => (
                  <OnlineOrder
                    key={order.onlineOrderId}
                    order={order}
                    accordionSwitch={accordionSwitch}
                    payOrder={payOrder}
                    statusUpdate={updatedItems[order.onlineOrderId]}
                  />
                ))
              ) : (
                <div>
                  <h1 className="pt-3 text-center text-xl font-semibold uppercase">
                    Nuk ka porosi
                  </h1>
                </div>
              )}
            </div>
          </div>
        </form>
      </dialog>
    </>
  );
});

export default OnlineList;
