import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import Card from "components/card";
import { ApiManager } from "app/ApiManager";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import SpinnerButton from "components/buttons/SpinnerButton";
import ErrorLabel from "components/error/ErrorLabel";
import { validate } from "../variables/ValidateForm";

function RegisterSupplier() {
  const navigation = useNavigate();
  const location = useLocation();
  const [supplierId, setSupplierId] = useState(0);
  const [supplier, setSupplier] = useState({});
  const [headerText, setHeaderText] = useState("Regjistrim");
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    console.log(location.state, "state");
    if (location.state) {
      if (location.state.id) {
        const fetchClient = async () => {
          const response = await ApiManager.get(`/Supplier/GetById`, {
            params: {
              supplierId: location.state.id,
            },
          });
          console.log(response.data, "This is the supplier");
          setSupplierId(location.state.id);
          setSupplier(response.data);
        };
        fetchClient();
        setHeaderText("Përditësim");
      }
    }
  }, [location.state]);

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      nub: "",
      email: "",
      personName: "",
      address: "",
      phoneNumber: "",
      accountName: "",
      accountNumber: "",
      businessId: "",
    },
    validate: (values) => validate(values),
    onSubmit: (values) => {
      setIsWaiting(true);

      let endpoint;
      if (supplierId !== 0) {
        endpoint = "/Supplier/Edit";
      } else {
        endpoint = "/Supplier/Create";
      }

      ApiManager.post(endpoint, values, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          setIsWaiting(false);
          if (response.data.isCreated >= 1) {
            Swal.fire({
              icon: "success",
              title: `Furnitori është ${headerText
                .slice(0, -2)
                .toLowerCase()}uar me sukses`,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              navigation("/admin/supplier");
            });
          } else if (response.data.isCreated === 0) {
            Swal.fire({
              icon: "error",
              title: `Ndodhi nje gabim, provoni perseri me vone`,
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data.isCreated === -1) {
            Swal.fire({
              icon: "error",
              title: `Ekziston nje furnitor me kete email!`,
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data.isCreated === -2) {
            Swal.fire({
              icon: "error",
              title: `Ekziston nje furnitor me kete numer telefoni`,
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data.isCreated === -3) {
            Swal.fire({
              icon: "error",
              title: `Ekziston nje furnitor me kete numer të llogarisë`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setIsWaiting(false);
        });
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      id: supplier?.id || 0,
      name: supplier?.name || "",
      nub: supplier?.nub || "",
      email: supplier?.email || "",
      personName: supplier?.personName || "",
      address: supplier?.address || "",
      phoneNumber: supplier?.phoneNumber || "",
      accountName: supplier?.accountName || "",
      accountNumber: supplier?.accountNumber || "",
      businessId: supplier?.businessId || "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier]);

  const { touched, errors, handleSubmit } = formik;

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <h3 className=" my-2 mb-4 text-xl font-bold text-navy-700">
          {headerText} i furnitorit
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="justify-between gap-x-3 md:flex">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Furnitori"
                id="name"
                name="name"
                type="text"
                {...formik.getFieldProps("name")}
                state={errors.name && touched.name ? "error" : null}
              />
              {errors.name && touched.name ? (
                <ErrorLabel error={errors.name} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Adresa"
                type="text"
                id="address"
                name="address"
                {...formik.getFieldProps("address")}
                state={errors.address && touched.address ? "error" : null}
              />
              {errors.address && touched.address ? (
                <ErrorLabel error={errors.address} />
              ) : null}
            </div>
          </div>

          <div className="justify-between gap-x-3 md:flex">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Numri Telefonit"
                type="number"
                id="phoneNumber"
                name="phoneNumber"
                {...formik.getFieldProps("phoneNumber")}
                state={errors.phoneNumber && touched.phoneNumber ? "error" : null}
              />
              {errors.phoneNumber && touched.phoneNumber ? (
                <ErrorLabel error={errors.phoneNumber} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Email"
                type="email"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
                state={errors.email && touched.email ? "error" : null}
              />
              {errors.email && touched.email ? (
                <ErrorLabel error={errors.email} />
              ) : null}
            </div>
          </div>
          <div className="justify-between gap-x-3 md:flex">
          <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Menaxheri"
                type="text"
                id="personName"
                name="personName"
                {...formik.getFieldProps("personName")}
                state={errors.personName && touched.personName ? "error" : null}
              />
              {errors.personName && touched.personName ? (
                <ErrorLabel error={errors.personName} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Numri unik i biznesit"
                type="number"
                id="nub"
                name="nub"
                {...formik.getFieldProps("nub")}
                state={errors.nub && touched.nub ? "error" : null}
              />
              {errors.nub && touched.nub ? (
                <ErrorLabel error={errors.nub} />
              ) : null}
            </div>
          </div>

          <div className="justify-between gap-x-3 md:flex">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Emri i llogaris bankare"
                type="text"
                id="accountName"
                name="accountName"
                {...formik.getFieldProps("accountName")}
                state={errors.accountName && touched.accountName ? "error" : null}
              />
              {errors.accountName && touched.accountName ? (
                <ErrorLabel error={errors.accountName} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Numri i llogarise bankare"
                type="text"
                id="accountNumber"
                name="accountNumber"
                {...formik.getFieldProps("accountNumber")}
                state={errors.accountNumber && touched.accountNumber ? "error" : null}
              />
              {errors.accountNumber && touched.accountNumber ? (
                <ErrorLabel error={errors.accountNumber} />
              ) : null}
            </div>
          </div>

          <div className="justify-around md:flex">
            <SpinnerButton title={"Ruaj të dhënat"} isWaiting={isWaiting} />
          </div>
        </form>
      </Card>
    </>
  );
}

export default RegisterSupplier;
