import React from "react";
import { MdOutlineArchive } from "react-icons/md";
import { MdOutlineUnarchive } from "react-icons/md";
import { useStore } from "app/stores/store";
import { useState } from "react";
import Card from "components/card";

const OfferNavbar = () => {
  const { upsertProductStore } = useStore();
  const [showArchivedOffers, setShowArchivedOffers] = useState(false);

  const handleArchivedOffersList = () => {
    setShowArchivedOffers(!showArchivedOffers);
    upsertProductStore.showArchivedOffers =
      !upsertProductStore.showArchivedOffers;
  };

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <div className="flex justify-center md:justify-between">
            <button
              onClick={handleArchivedOffersList}
              className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
                  bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
                  hover:bg-gray-100 active:bg-gray-200 w-full md:w-auto"
            >
              {showArchivedOffers === true ? (
                <span className="flex items-center">
                  Ofertat &nbsp; <MdOutlineUnarchive className="h-5 w-5" />
                </span>
              ) : (
                <span className="flex items-center">
                  Ofertat e arkivuara &nbsp;
                  <MdOutlineArchive className="h-5 w-5" />
                </span>
              )}
            </button>
          </div>
      </Card>
    </>
  );
};

export default OfferNavbar;
