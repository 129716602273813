import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import WaiterDashboard from "views/waiter/Dashboard";
import ChefDashboard from "views/chef/Dashboard";
import { SignalRConnectionProvider } from "app/SignalRConnectionProvider";

const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="waiter/*" element={
        <SignalRConnectionProvider>
          <WaiterDashboard />
        </SignalRConnectionProvider>
      } />
      <Route path="chef/*" element={
        <SignalRConnectionProvider>
          <ChefDashboard />
        </SignalRConnectionProvider>
      } />
      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default App;
