import React, { useState, useEffect } from 'react';
import UnfinishedOrders from "../../../components/orders/UnfinishedOrders";
import FinishedOrders from "../../../components/orders/FinishedOrders";
import { observer } from "mobx-react";

const ChefOrders = observer(() => {
  const [unfinishedOrders, setUnfinishedOrders] = useState(true);
  const [orderCount, setOrderCount] = useState(0);


  return (
    <>
      <div className="flex justify-end items-end">
        <button
          onClick={() => setUnfinishedOrders(!unfinishedOrders)}
          className="linear mt-4 flex justify-center rounded-xl 
          bg-indigo-600 py-[8px] px-4 text-base font-medium text-white transition 
          duration-200 hover:bg-indigo-700 active:bg-indigo-900"
        >
          {unfinishedOrders
            ? "Porositë e përfunduara"
            : "Porositë e pa përfunduara"}
        </button>
      </div>

      {unfinishedOrders ? <UnfinishedOrders count={orderCount} changeOrderCount={setOrderCount} /> : <FinishedOrders />}
    </>
  );
});

export default ChefOrders;
