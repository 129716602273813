// Custom components
import React from "react";

function InputField(props) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    onChange,
    onBlur,
    name,
    value,
    fileName,
    min,
  } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 :text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        id={id}
        value={value}
        name={name}
        placeholder={placeholder}
        min={min}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 "
            : state === "error"
            ? "border-red-400 text-red-500 placeholder:text-red-500 "
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 " : ""
        }`}
      />
    </div>
  );
}

export default InputField;

