import React, { useEffect, useState, useCallback } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Card from "components/card";
import Switch from "components/switch";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import SelectList from "views/admin/menu/components/SelectList";
import Multiselect from "components/multiselect/Multiselect";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorLabel from "components/error/ErrorLabel";
import { validate } from "./variables/ValidateFood";
import RecipeTable from "./RecipeTable";
import { useStore } from "app/stores/store";
import { autorun, toJS } from "mobx";
import SpinnerButton from "components/buttons/SpinnerButton";
import Swal from "sweetalert2";
import { store } from "app/stores/store";
import AddExtraProductModal from "./components/AddExtraProductModal";
import Cropper from "react-easy-crop";
import  getCroppedImg  from "../stock/variables/cropImage";

const UpsertProduct = () => {
  const [showDiscountInputs, setshowDiscountInputs] = useState("hidden");
  const [switchChecked, setSwitchChecked] = useState(false);
  const [file, setFile] = useState();
  const [extraProducts, setExtraProducts] = useState();
  const [selectedExtraProducts, setSelectedExtraProducts] = useState([]);
  const options = [{ name: "Kuzhinë" }, { name: "Bar" }];
  const [selectedpreparedAt, setSelectedPreparedAt] = useState(options[0]);
  const [headerText, setHeaderText] = useState("");
  const [productItem, setProductItem] = useState({});
  const [prevSelectedExtraProducts, setPrevSelectedExtraProducts] = useState(
    []
  );
  const [productIdFromState, setProductIdFromState] = useState();
  const [showRecipeTable, setShowRecipeTable] = useState(false);
  const { upsertProductStore } = useStore();
  const [recipeData, setRecipeData] = useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage]= useState("");
  const [showCroppedImage, setShowCroppedImage] = useState(false);
  const [isOpen, setIsOpen] = useState(true);


  const handleButtonClick = (isEdit) => {
    setIsModalOpen(true);
    Swal.fire({
      icon: "success",
      title: isEdit
        ? "Produkti u përditësua me sukses!"
        : "Produkti u shtua me sukses!",
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {
      navigation("/admin/product-list", {
        state: {
          isArchived: productItem.isArchived,
        },
      });
    });
  };

  const navigation = useNavigate();
  const location = useLocation();

  const handleAddRecipe = () => {
    setShowRecipeTable(!showRecipeTable);
  };

  const handleAddExtra = () => {};

  useEffect(() => {
    autorun(() => {
      const regularArray = toJS(upsertProductStore.rowsData);
      setRecipeData(regularArray);
    });
  }, [upsertProductStore.rowsData]);

  useEffect(() => {
    var jwtToken = localStorage.getItem("token");
    ApiManager.get("/ExtraProduct/ListOfExtraProducts", {
      params: {
        jwtToken: jwtToken,
      },
    })
      .then((response) => {
        setExtraProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updateExtraList = () => {
    var jwtToken = localStorage.getItem("token");
    ApiManager.get("/ExtraProduct/ListOfExtraProducts", {
      params: {
        jwtToken: jwtToken,
      },
    })
      .then((response) => {
        setExtraProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { productType } = location.state;
  
  useEffect(() => {
    if (location.state) {
      const { productId } = location.state;
      setProductIdFromState(productId);
      if (productId) {
        setHeaderText("Përditësim");

        setShowRecipeTable(true);

        let endpoint = "";
        if (productType === "Food") {
          endpoint = `/Food/GetFood/${productId}?requireImg=true`;
        } else if (productType === "Drink") {
          endpoint = `/Drink/GetDrink/${productId}?requireImg=true`;
        } else if (productType === "Dessert") {
          endpoint = `/Dessert/GetDessert/${productId}?requireImg=true`;
        }

        if (endpoint) {
          const fetchData = async () => {
            try {
              await ApiManager.get(endpoint).then((response) => {
                setProductItem(response.data);
              });
            } catch (error) {
              console.log(error);
            }
          };
          fetchData();
        }
      } else {
        setHeaderText("Regjistrim");
        store.upsertProductStore.resetState();
      }
    }
  }, [location.state, productType]);

  const handleFileUpload = (e) => {
    
    const input = e.target;
    const selectedFile = input.files[0];

    // Check file type
    if (
      selectedFile.type !== "image/jpeg" &&
      selectedFile.type !== "image/png" &&
      selectedFile.type !== "image/webp"
      
    ){
      Swal.fire({
        icon: "error",
        title: "Formati i fotove duhet të jetë jpeg ose png ose webp ",
        showConfirmButton: false,
        timer: 2500,
      });
      // Clear the selected file
      input.value = "";
      
      // This will clear the selected file
      return;
    }

    // Check file size (e.g., 50MB limit)
    const maxFileSize = 50 * 1024 * 1024; // 50MB
    if (selectedFile.size > maxFileSize) {
      Swal.fire({
        icon: "error",
        title: "Madhësia e fotove duhet të jetë më e vogël se 50MB",
        showConfirmButton: false,
        timer: 2500,
      });
      // Clear the selected file
      input.value = ""; // This will clear the selected file
      return;
    }

    // Proceed with setting the file and creating a preview
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
      setIsOpen(true);
    };
    reader.readAsDataURL(selectedFile);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imagePreviewUrl, croppedAreaPixels);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result; // Access result inside onloadend
        // Update state with cropped image data
        setCroppedImage(base64data);
        setIsOpen(false);
      };
      reader.readAsDataURL(croppedImage); // Start reading cropped image as data URL
    } catch (e) {
      console.error(e);
    }
  }, [imagePreviewUrl, croppedAreaPixels]);

  const formik = useFormik({
    initialValues: {
      name: "",
      id: 0,
      price: "",
      inDiscount: "",
      description: "",
      calories: "",
      cookingTime: "",
      startDate: "",
      endDate: "",
      productType: productType,
      preparedAt: selectedpreparedAt.name,
      ImageFile: file,
      switchChecked: switchChecked,
    },
    validate: (values) => validate(values, switchChecked),
    onSubmit: (values) => {
      setIsWaiting(true);
      const jwtToken = localStorage.getItem("token");

      const formData = new FormData();
      if (croppedImage) {
        formData.append("base64Image", croppedImage);
        formData.append("fileName", file.name);
      }

      // if (file) {
      //   formData.append("ImageFile", file);
      //   formData.append("fileName", file.name);
      // }
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      Object.keys(formik.values).forEach((key) => {
        if (key !== "ImageFile") {
          // Exclude the file since it's already added
          formData.append(key, formik.values[key]);
        }
      });

      // Append selectedExtraProducts to formData
      formData.append(
        "ExtraProductsJSON",
        JSON.stringify(selectedExtraProducts)
      );
      if (showRecipeTable) {
        formData.append("RecipeDataJSON", JSON.stringify(recipeData));
      }

      let endpoint;
      if (productType === "Food") {
        if (productIdFromState) {
          productItem.id = productItem.foodId;
          endpoint = "/Food/EditFood";
        } else {
          endpoint = "/Food/CreateFood";
        }
      } else if (productType === "Drink") {
        if (productIdFromState) {
          productItem.id = productItem.drinkId;
          endpoint = "/Drink/EditDrink";
        } else {
          endpoint = "/Drink/CreateDrink";
        }
      } else if (productType === "Dessert") {
        if (productIdFromState) {
          productItem.id = productItem.dessertId;
          endpoint = "/Dessert/EditDessert";
        } else {
          endpoint = "/Dessert/CreateDessert";
        }
      }

      ApiManager.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          jwtToken: jwtToken,
        },
      })
        .then((response) => {
          setIsWaiting(false);
          handleButtonClick(!!productIdFromState);
          navigation("/admin/product-list", {
            state: {
              isArchived: productItem.isArchived,
            },
          });
        })
        .catch((error) => {
          setIsWaiting(false);
        });

      setIsWaiting(false);
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      name: productItem?.name || "",
      description: productItem?.description || "",
      price: productItem?.price || "",
      calories: productItem?.calories || "",
      cookingTime: productItem?.cookingTime || 0,
      preparedAt: selectedpreparedAt?.name || "",
      id: location.state.productId || 0,
      imageName: productItem?.imageName || "",
    }));
    if (productItem.extraProducts) {
      const selectedOptions = productItem.extraProducts.map((extraProduct) => ({
        value: extraProduct.extraProductId,
        label: extraProduct.name,
      }));
      setPrevSelectedExtraProducts(selectedOptions);
    } else {
      setPrevSelectedExtraProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productItem, productItem.extraProducts]);
  const handleAnulo = () => {
    navigation("/admin/product-list");
  };
  const { touched, errors, handleSubmit } = formik;

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <h3 className="mb-5 text-xl font-bold text-navy-700">
          {headerText} i produktit
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-between gap-x-3 md:flex-row">
            <div className="flex w-full flex-col">
              <div className="mb-3 w-full">
                <InputField
                  variant="auth"
                  extra="mb-1"
                  label="Emërtimi*"
                  id="name"
                  name="name"
                  type="text"
                  {...formik.getFieldProps("name")}
                  state={errors.name && touched.name ? "error" : null}
                />
                {errors.name && touched.name ? (
                  <ErrorLabel error={errors.name} />
                ) : null}
              </div>
              <div className="mb-3 w-full">
                <InputField
                  variant="auth"
                  label="Çmimi (EUR)*"
                  type="number"
                  id="price"
                  name="price"
                  {...formik.getFieldProps("price")}
                  state={errors.price && touched.price ? "error" : null}
                />
                {errors.price && touched.price ? (
                  <ErrorLabel error={errors.price} />
                ) : null}
              </div>
            </div>
            <div className="mb-3 flex w-full flex-col">
              <TextField
                label="Përshkrimi*"
                id="description"
                name="description"
                rows="6"
                {...formik.getFieldProps("description")}
                state={
                  errors.description && touched.description ? "error" : null
                }
              />
              {errors.description && touched.description ? (
                <ErrorLabel error={errors.description} />
              ) : null}
            </div>
          </div>
          <div className="mt-5 flex flex-col justify-between gap-4 md:flex-row">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Kaloritë (kCal)"
                type="number"
                id="calories"
                name="calories"
                {...formik.getFieldProps("calories")}
                state={errors.calories && touched.calories ? "error" : null}
              />
              {errors.description && touched.description ? (
                <ErrorLabel error={errors.calories} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Koha e përgatitjes (në minuta)*"
                type="number"
                id="cookingTime"
                name="cookingTime"
                {...formik.getFieldProps("cookingTime")}
                state={
                  errors.cookingTime && touched.cookingTime ? "error" : null
                }
              />
              {errors.cookingTime && touched.cookingTime ? (
                <ErrorLabel error={errors.cookingTime} />
              ) : null}
            </div>
            <div className="flex w-full items-center p-2">
              <label
                htmlFor="checkbox"
                className="text-base font-medium text-navy-700"
              >
                Produkti a është në zbritje? &nbsp;
              </label>
              <Switch
                showDiscountInputs={showDiscountInputs}
                setshowDiscountInputs={setshowDiscountInputs}
                switchChecked={switchChecked}
                setSwitchChecked={setSwitchChecked}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between md:flex-row md:gap-4">
            <div className={`w-full ${showDiscountInputs}`}>
              <InputField
                variant="auth"
                label="Data e fillimit të zbritjes*"
                type="date"
                id="startDate"
                name="startDate"
                state={errors.startDate && touched.startDate ? "error" : null}
                {...formik.getFieldProps("startDate")}
              />
              {errors.startDate && touched.startDate ? (
                <ErrorLabel error={errors.startDate} />
              ) : null}
            </div>
            <div className={`w-full ${showDiscountInputs}`}>
              <InputField
                variant="auth"
                label="Data e mbarimit të zbritjes*"
                type="date"
                id="endDate"
                name="endDate"
                state={errors.endDate && touched.endDate ? "error" : null}
                {...formik.getFieldProps("endDate")}
              />
              {errors.endDate && touched.endDate ? (
                <ErrorLabel error={errors.endDate} />
              ) : null}
            </div>
          </div>
          <div className="mt-5 flex flex-col justify-between md:flex-row md:gap-4">
            <div className="w-full items-center">
              <div className="flex items-center gap-5">
                <label className={`text-sm font-medium text-navy-700`}>
                  Produktet ekstra
                </label>
                <div>
                  <button
                    onClick={openModal}
                    type="button"
                    className="rounded bg-primary"
                  >
                    <span className="px-2 text-white">+</span>
                  </button>
                  <span> (Shto produkt ekstra)</span>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-full">
                  <Multiselect
                    options={extraProducts}
                    setSelectedExtraProducts={setSelectedExtraProducts}
                    name="ExtraProducts"
                    defaultValue={prevSelectedExtraProducts}
                  />
                </div>
              </div>
            </div>
            <div className="w-full items-center">
              <SelectList
                label="Vendi përgatitjes"
                selected={selectedpreparedAt}
                setSelected={setSelectedPreparedAt}
                options={options}
              />
            </div>
          </div>

          <div className="mt-5 flex flex flex-col md:flex-row md:gap-4">
            <div className="mb-3 w-full">
              <InputField
                label="Foto e produktit"
                type="file"
                name="ImageFile" // This name should match the IFormFile property name in ProductVM
                id="ImageFile"
                state={errors.ImageFile && touched.ImageFile ? "error" : null}
                onChange={handleFileUpload}
                onBlur={formik.handleBlur}
              />
              {errors.ImageFile && touched.ImageFile ? (
                <ErrorLabel error={errors.ImageFile} />
              ) : null}
            </div>
            <div className="flex w-full items-center justify-center">
                  {/* Show the Cropper component */}
                  {imagePreviewUrl && isOpen &&(
                    <div>
                   <Cropper
                    image={imagePreviewUrl}
                      crop={crop}
                      zoom={zoom}
                      aspect={4 / 3} // Set the aspect ratio as needed
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      style={{ zIndex: 1 }}
                    />
                    {file && imagePreviewUrl &&  (
                      <button type="button" onClick={handleCropSave} className="absolute z-10 border-2 rounded-lg bg-red-300 p-2">Save Cropped Image</button>
                    )}
                  </div>
                 )}
                </div>
            
            <div className="flex w-full items-center justify-center">
              {/* Show image preview if a new image is selected */}
              {file && (
                <div className="mt-2">
                  <img
                    src={croppedImage}
                    alt="Product Preview"
                    className="h-8 w-8 cursor-pointer rounded-full object-cover object-center md:h-20 md:w-20"
                  />
                </div>
                
              )}
              {/* Show current image path if product is being edited */}
              {!file && productItem?.base64Image && (
                <div className="mt-2">
                  <img
                    src={`data:image/jpeg;base64,${productItem.base64Image}`}
                    alt="Current Product Image"
                    className="h-8 w-8 cursor-pointer rounded-full object-cover object-center md:h-20 md:w-20"
                  />
                </div>
              )}
            </div>
          </div>
            </form>

          <div>
            <button
              type="button"
              onClick={handleAddRecipe}
              className="!linear z-[1] my-3 flex items-center justify-center gap-2
              rounded-lg bg-lightPrimary p-1 text-brand-500 !transition 
              !duration-200 hover:bg-gray-100 active:bg-gray-200 "
            >
              {showRecipeTable === true
                ? "Anuloni normativin -"
                : "Shtoni normativin +"}
            </button>
            {showRecipeTable && <RecipeTable />}
          </div>
          <div className="flex justify-around">
            <SpinnerButton
              title={"Ruaj Produktin"}
              isWaiting={isWaiting}
              style={{ width: "300px" }}
              onClick={formik.handleSubmit}
            />
            <SpinnerButton
              title={"Anulo Produktin"}
              isWaiting={isWaiting}
              style={{ width: "300px" }}
              secondary={true}
              onClick={() => handleAnulo()}
            />
          </div>

        <AddExtraProductModal
          isOpen={modalOpen}
          onClose={closeModal}
          onSubmitSuccess={updateExtraList}
        />
      </Card>
    </>
  );
};

export default UpsertProduct;
