import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ApiManager } from "app/ApiManager";
import jwtDecode from "jwt-decode";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const labels = [
  "Janar",
  "Shkurt",
  "Mars",
  "Prill",
  "Maj",
  "Qershor",
  "Korrik",
  "Gusht",
  "Shtator",
  "Tetor",
  "Nentor",
  "Dhjetor",
];

const YearlyBar = ({ selectedBusiness }) => {
  const [sales, setSales] = useState([]);
  useEffect(() => {
    const AllSalesByMonths = async () => {
      const response = await ApiManager.get(`/Sales/MonthlyByUserId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setSales(response.data);
    };
    const CertainSalesByMonths = async () => {
      const response = await ApiManager.get(
        `Sales/MonthlyByBusinessId/${selectedBusiness}`
      );
      setSales(response.data);
    };
    const SalesByMonths = async () => {
      const response = await ApiManager.get("/Sales/SalesByMonths", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setSales(response.data);
    };
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if (role === "Drejtor") {
      var selectedBusiness = localStorage.getItem("selectedBusiness");
      if (selectedBusiness === "0") {
        AllSalesByMonths();
      } else {
        CertainSalesByMonths();
      }
    } else {
      SalesByMonths();
    }
  }, [selectedBusiness]);

  const data = {
    labels,
    datasets: [
      {
        label: "Shitjet",
        data: labels.map((_, i) => sales[i]),
        backgroundColor: "rgba(66, 42, 251, 0.8)",
      },
    ],
  };
  return <Bar options={options} data={data} />;
};

export default YearlyBar;
