export const columnsData = [
  {
    Header: "Emri i tavolinës",
    accessor: "tableName", 
  },

  {
    Header: "Vlerësimi për shërbim",
    accessor: "serviceRating",
  },
  {
    Header: "Vlerësimi për porosi",
    accessor: "orderRating",
  },
  {
    Header: "Komenti",
    accessor: "comment",
  },

];
