import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { adminRoutes } from "routes";
import { ownerRoutes } from "routes";
import { printerRoutes } from "routes";
import jwt_decode from "jwt-decode";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");
  const [selectedBusiness, setSelectedBusiness] = useState(0);
  const [role, setRole] = useState("");

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  //kur te shtojsh nir rol tri duhet me thirr ktu
  useEffect(() => {
    const decodedToken = jwt_decode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    setRole(decodedToken.Role);
    if (role === "SuperAdmin") getActiveRoute(adminRoutes);
    else if (role === "Drejtor") {
      getActiveRoute(ownerRoutes);
    }
    else if(role === "Faturues") {
      getActiveRoute(printerRoutes);
    }
     else getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const changeSelectedBusiness = (value) => {
    setSelectedBusiness(value);
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        const element =
          prop.component.type.name === "Dashboard" ||
          prop.component.type.name === "ManagerList"
            ? React.cloneElement(prop.component, { selectedBusiness })
            : prop.component;
        return <Route path={`/${prop.path}`} element={element} key={key} />;
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
      <div className="flex h-full w-full">
        <Sidebar open={open} onClose={() => setOpen(false)} />
        {/* Navbar & Main Content */}
        <div className="h-full w-full bg-lightPrimary ">
          {/* Main Content */}
          <main
            className={`mx-2 h-full flex-none transition-al xl:ml-60`}
          >
            {/* Routes */}
            <div className="h-full">
              <Navbar
                changeSelectedBusiness={changeSelectedBusiness}
                onOpenSidenav={() => setOpen(true)}
                logoText={"Tailwind React"}
                brandText={currentRoute}
                //if adding more roles add them here
                secondary={getActiveNavbar(
                  role === "SuperAdmin"
                      ? adminRoutes
                      : role === "Drejtor"
                      ? ownerRoutes
                      : role === "Faturues"
                      ? printerRoutes 
                      : routes
                )}
                {...rest}z
              />
              <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
                <Routes>
                  {/* if adding more roles add them here */}
                  {getRoutes(
                    role === "SuperAdmin"
                      ? adminRoutes
                      : role === "Drejtor"
                      ? ownerRoutes
                      : role === "Faturues"
                      ? printerRoutes 
                      : routes
                  )}

                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
              </div>
              <div className="p-3">
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
  );
}
