import React, { useState, useEffect } from "react";
import StockTable from "./StockTable";
import { ApiManager } from "app/ApiManager";
import { columnsData } from "../variables/columnsData";
import {
  MdInventory,
  MdOutlineKitchen,
  MdOutlineLocalBar,
} from "react-icons/md";
import EditStockModal from "./StockModal";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const StockList = () => {
  const [kitchenStock, setKitchenStock] = useState([]);
  const [barStock, setBarStock] = useState([]);
  const [pergjithshemStock, setPergjithshemStock] = useState([]);
  const [selectedStock, setSelectedStock] = useState("Pergjithshem");
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchStockData = () => {
    var jwtToken = localStorage.getItem("token");
    ApiManager.get("/Stock/ListOfStock", {
      params: {
        jwtToken: jwtToken,
      },
    })
      .then((response) => {
        const flattenDataKitchenStock = [];
        response.data.kitchenStocks.forEach((stock) => {
          flattenDataKitchenStock.push({
            name: stock.itemName,
            description: stock.description,
            unitOfMeasure: stock.unitOfMeasure,
            quantity: stock.quantity,
            stockId: stock.stockId,
          });
        });
        setKitchenStock(flattenDataKitchenStock);

        const flattenDataBarStock = [];
        response.data.barStocks.forEach((stock) => {
          flattenDataBarStock.push({
            name: stock.itemName,
            description: stock.description,
            unitOfMeasure: stock.unitOfMeasure,
            quantity: stock.quantity,
            stockId: stock.stockId,
          });
        });
        setBarStock(flattenDataBarStock);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchAllStock = async () => {
    const response = await ApiManager.get("/Stock/AllStock", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    });
    setPergjithshemStock(response.data);
  };

  useEffect(() => {
    fetchStockData();
    fetchAllStock();
  }, []);
  const changeStockType = () => {
    if (selectedStock === "Kitchen") {
      setSelectedStock("Kitchen");
    } else if (selectedStock === "Bar") {
      setSelectedStock("Bar");
    } else if (selectedStock === "Pergjithshem") {
      setSelectedStock("Pergjithshem");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filterStock = (stock) => {
    return stock.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery) ||
        item.description.toLowerCase().includes(searchQuery)
    );
  };

  const filteredKitchenStock = filterStock(kitchenStock);
  const filteredBarStock = filterStock(barStock);
  // const allStock = [...kitchenStock, ...barStock];
  const filteredPergjithshemStock = filterStock(pergjithshemStock);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const exportToPdf = () => {
    const element = document.getElementById("general-stock");
    const logoImgHeight = 0;
    const logoImgY = 10;
    const padding = 10;
    const textMarginTop = 2;
    const textUnderLogo = `STOKU I ${
      selectedStock === "Kitchen"
        ? "KUZHINËS"
        : selectedStock === "Bar"
        ? "BANAKUT"
        : "PËRGJITHSHËM"
    }`;
    const textFontSize = 12;
    const tableMarginTop = 10;
    const tableFontSize = 24;

    html2canvas(element, {
      removeContainer: true,
      scale: 1.4,
      onclone: (document) => {
        document.getElementById("general-stock").style.overflow = "visible";
        document.getElementById("general-stock").style.boxShadow = "none";
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const imgWidth = 210 - 2 * padding;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pdf = new jsPDF("p", "mm");

      // pdf.addImage(logoImageURL, "PNG", logoImgX, logoImgY, logoImgWidth, logoImgHeight);

      pdf.setFontSize(textFontSize);
      pdf.setFont("Monsterrat", "bold");

      const textWidth =
        (pdf.getStringUnitWidth(textUnderLogo) * textFontSize) / 25.4;
      const textX = (pdf.internal.pageSize.width - textWidth) / 2.8;

      pdf.text(
        textX,
        logoImgY + logoImgHeight + padding + textMarginTop,
        textUnderLogo
      );
      // Set font size for the table content
      pdf.setFontSize(tableFontSize);

      pdf.addImage(
        imgData,
        "JPEG",
        padding,
        logoImgY + logoImgHeight + padding + textMarginTop + tableMarginTop,
        imgWidth,
        imgHeight
      );

      // Footer text
      const footerText =
        "Tirana str. No. 91, 10 000 Prishtina | www.rectec.ac | info.rekas@gmail.com";
      const footerTextWidth = (pdf.getStringUnitWidth(footerText) * 10) / 25.4;
      const footerX = (pdf.internal.pageSize.width - footerTextWidth) / 4;

      const footerY = pdf.internal.pageSize.height - 15;

      // Set font size for the footer text
      pdf.setFontSize(10);
      pdf.text(footerText, footerX, footerY);

      pdf.save("Genral-Stock.pdf");

      document.getElementById("general-stock").style.boxShadow = "none";
    });
  };

  return (
    <>
      <div className="mt-5 flex flex-col justify-between md:flex-row md:items-center">
        <div>
          <input
            type="text"
            placeholder="Kërko..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full rounded border bg-white p-2"
          />
        </div>
        <div className="my-5 flex justify-between md:gap-5">
          <button
            onClick={openModal}
            className="linear flex items-center justify-center gap-2 rounded-lg bg-gray-300 text-gray-800 transition duration-200 hover:cursor-pointer hover:bg-gray-400 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80"
          >
            <span className="px-4 py-2 text-sm font-medium text-gray-800">
              Shto sasi
            </span>
          </button>
          <div class="flex items-center">
            <input
              id="default-radio-1"
              type="radio"
              defaultChecked={selectedStock === "Pergjithshem"}
              onClick={(e) => setSelectedStock("Pergjithshem")}
              value=""
              name="default-radio"
              className="my-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            />
            <label
              for="default-radio-1"
              className="ms-2 ml-1 flex items-center text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              <MdInventory />
              Stoku i përgjithshëm
            </label>
          </div>
          <div class="flex items-center">
            <input
              id="default-radio-2"
              type="radio"
              defaultChecked={selectedStock === "Kitchen"}
              onClick={(e) => setSelectedStock("Kitchen")}
              value=""
              name="default-radio"
              className="my-2 h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            />
            <label
              for="default-radio-2"
              className="ms-2 ml-1 flex items-center text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              <MdOutlineKitchen />
              Stoku i kuzhinës
            </label>
          </div>
          <div class="flex items-center">
            <input
              id="default-radio-3"
              type="radio"
              defaultChecked={selectedStock === "Bar"}
              onClick={(e) => setSelectedStock("Bar")}
              value=""
              name="default-radio"
              className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            />
            <label
              for="default-radio-3"
              className="ms-2 ml-1 flex items-center text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              <MdOutlineLocalBar />
              Stoku i Banakut
            </label>
          </div>
        </div>
      </div>
      <div className="grid h-full grid-cols-1 gap-5">
        {selectedStock === "Kitchen" ? (
          <StockTable
            onPrint={exportToPdf}
            columnsData={columnsData}
            tableData={filteredKitchenStock}
            tableName="Stoku i kuzhinës"
            stockType="KitchenStock"
          />
        ) : selectedStock === "Bar" ? (
          <StockTable
            onPrint={exportToPdf}
            columnsData={columnsData}
            tableData={filteredBarStock}
            tableName="Stoku i banakut"
            stockType="BarStock"
          />
        ) : (
          <StockTable
            onPrint={exportToPdf}
            columnsData={columnsData}
            tableData={filteredPergjithshemStock}
            tableName="Stoku i përgjithshëm"
            stockType="PergjithshemStock"
          />
        )}
      </div>
      <EditStockModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmitSuccess={fetchStockData}
        items={
          selectedStock === "Kitchen"
            ? kitchenStock
            : selectedStock === "Bar"
            ? barStock
            : pergjithshemStock
        }
        selectedStock={selectedStock}
      />
    </>
  );
};

export default StockList;
