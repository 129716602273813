import React from "react";
import Dropdown from "components/dropdown";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

function CardMenu(props) {
  const { transparent } = props;
  const [open, setOpen] = React.useState(false);
  const navigation = useNavigate();

  const handleAddBusiness = () => {
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if(role === "Drejtor")
      navigation("/admin/owner-business-upsert");
    else
      navigation("/admin/business-upsert");
  };
  return (
    // <Dropdown
    //   button={
    //     <button
    //       onClick={() => setOpen(!open)}
    //       open={open}
    //       className={`flex items-center text-xl hover:cursor-pointer ${
    //         transparent
    //           ? "bg-none text-white hover:bg-none active:bg-none"
    //           : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
    //       } linear justify-center rounded-lg font-bold transition duration-200`}
    //     >
    //       <BsThreeDots className="h-6 w-6" />
    //     </button>
    //   }
    //   animation={"origin-top-right transition-all duration-300 ease-in-out"}
    //   classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
    //   children={
        <div className="w-max rounded-xl bg-white py-3 px-4 text-sm bg-gray-100 hover:bg-gray-300 cursor-pointer">
          <p
            className="hover:text-black flex items-center gap-2 text-gray-800"
            onClick={() => handleAddBusiness()}
          >
            <span>
              <AiOutlinePlus />
            </span>
            Regjistro Biznes
          </p>
        </div>
    //   }
    // />
  );
}

export default CardMenu;
