import React, { useState } from "react";
import { ApiManager } from "app/ApiManager";
import Swal from "sweetalert2";

const EditStockModal = ({
  isOpen,
  onClose,
  items,
  selectedStock,
  onSubmitSuccess,
}) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [quantity, setQuantity] = useState("");

  const handleItemChange = (e) => {
    setSelectedItem(e.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleSubmit = () => {
    const selectedStockItem = items.find((item) => item.name === selectedItem);
    if (!selectedStockItem) {
      Swal.fire({
        icon: "error",
        title: "Gabim",
        text: "Ju lutem zgjidhni nje artikull.",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    if (!quantity) {
      Swal.fire({
        icon: "error",
        title: "Gabim",
        text: "Ju lutem vendosni sasine.",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    const endpoint =
      selectedStock === "Kitchen"
        ? "KitchenStock/UpdateStockQuantity"
        : "BarStock/UpdateStockQuantity";

    const parsedQuantity = parseFloat(quantity);
    if (isNaN(parsedQuantity) || parsedQuantity < 0) {
      Swal.fire({
        icon: "error",
        title: "Gabim",
        text: "Sasia duhet te jete nje numer pozitiv.",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    const payload = {
      stockId: selectedStockItem.stockId,
      additionalQuantity: parsedQuantity,
    };
    ApiManager.post(endpoint, payload, {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (onSubmitSuccess) {
          onSubmitSuccess();
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Stoku u perditësua me sukses.",
            showConfirmButton: false,
            timer: 2500,
          });
        }
        onClose();
        setSelectedItem("");
        setQuantity("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-40 bg-opacity-20 backdrop-blur-sm"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto p-4">
        <div style={{width: "50%", height:"40%"}} className="relative flex max-w-4xl flex-col justify-between rounded-lg bg-white p-4 shadow-lg">
          <div>
            <div className="mb-5 flex flex-row-reverse justify-between items-center">
              <span className="">
                <button onClick={onClose} className="text-4xl font-medium">
                  &times;
                </button>
              </span>
              <h2 className="text-xl font-semibold">
                Përditëso stokun
              </h2>
            </div>
            <select
              className="mb-6 w-full rounded border border-gray-300 bg-white p-3"
              value={selectedItem}
              onChange={handleItemChange}
            >
              <option value="" className="text-xs md:text-sm">
                Ju lutem zgjidhni
              </option>
              {items.map((item) => (
                <option
                  key={item.stockId}
                  value={item.name}
                  className="text-xs md:text-sm"
                >
                  {item.name}
                </option>
              ))}
            </select>
            <input
              type="number"
              className="mb-6 w-full rounded border border-gray-300 bg-white p-3"
              placeholder="Sasia"
              value={quantity}
              onChange={handleQuantityChange}
            />
          </div>
          <div>
            <button
              onClick={handleSubmit}
              className="w-full rounded bg-blue-500 py-3 px-6 text-xl font-semibold text-white hover:bg-blue-700"
            >
              Përditëso
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStockModal;
