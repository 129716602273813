import { useState, useEffect } from "react";
import { ApiManager } from "app/ApiManager";
import jwtDecode from "jwt-decode";

const StockCount = ({ selectedBusiness }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const GetAllStocks = async () => {
      try {
        const response = await ApiManager.get(`/Stock/StockByUserId`, {
          params: {
            jwtToken: localStorage.getItem("token"),
          },
        });
        setTotal(response.data);
      } catch (error) {
        console.log(error, "err1");
      }
    };
    const GetCertainStocks = async () => {
      const response = await ApiManager.get(
        `/Stock/StockByBusinessId/${selectedBusiness}`
      );
      setTotal(response.data);
    };
    const GetStocks = async () => {
      const response = await ApiManager.get(`/Stock/TotalStock`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setTotal(response.data);
    };
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if (role === "Drejtor") {
      var selectedBusiness = localStorage.getItem("selectedBusiness");
      if (selectedBusiness === "0") {
        GetAllStocks();
      } else {
        GetCertainStocks();
      }
    } else {
      GetStocks();
    }
  }, [selectedBusiness]);
  return total;
};

export default StockCount;
