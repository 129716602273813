import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const SelectList = (props) => {
  const { selected, setSelected, label, options, id } = props;

  const handleSelect = (value) => {
    setSelected(value);
  };

  return (
    <div className="relative">
      <label className="text-sm font-medium text-navy-700">{label}</label>
      <Listbox value={selected} onChange={handleSelect}>
        {({ open }) => (
          <>
            <div className="mt-2 relative">
              <Listbox.Button
                className="h-12 w-full cursor-default rounded-lg border border-gray-200 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2"
              >
                <span id={id} className="block truncate">{selected.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Listbox.Options
                  static
                  className="z-50 absolute mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none max-h-[300px]"
                >
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        `${active ? "text-white bg-blue-600" : "text-gray-900"}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`${selected ? "font-medium" : "font-normal"} block truncate`}>
                            {option.name}
                          </span>
                          {selected ? (
                            <span className={`${active ? "text-white" : "text-blue-600"} absolute inset-y-0 left-0 flex items-center pl-3`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default SelectList;
