export const columnsData = [
  {
    Header: "EMËRTIMI",
    accessor: "name",
  },
  {
    Header: "PËRSHKRIMI",
    accessor: "description",
  },
  {
    Header: "SASIA",
    accessor: "quantity",
  },
  {
    Header: "NJËSIA MATËSE",
    accessor: "unitOfMeasure",
  },
];
