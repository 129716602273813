import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function ClientCard() {
  const navigation = useNavigate();

  const handleAddClient = () => {
    navigation("/admin/register-client");
  };

  return (
    <>
      <button
        onClick={() => handleAddClient()}
        className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg bg-lightPrimary
    px-3 py-2 text-sm text-gray-800 !transition !duration-200 
    hover:bg-gray-100 active:bg-gray-200"
      >
        <MdOutlineAdd className="h-5 w-5" /> Regjistro Klientë
      </button>
    </>
  );
}

export default ClientCard;
