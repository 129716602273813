import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ApiManager } from "app/ApiManager";
import jwtDecode from "jwt-decode";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const getDaysInMonth = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth(); // Note: Months are zero-indexed (0 for January, 1 for February, etc.)
  const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the total number of days in the current month

  const daysArray = [];
  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push(day);
  }

  return daysArray;
};

const labels = getDaysInMonth();

const MonthlyBar = ({ selectedBusiness }) => {
  const [sales, setSales] = useState([]);
  useEffect(() => {
    const AllSalesByDays = async () => {
      const response = await ApiManager.get(`/Sales/DailyByUserId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setSales(response.data);
    };
    const CertainSalesByDays = async () => {
      const response = await ApiManager.get(
        `Sales/DailyByBusinessId/${selectedBusiness}`
      );
      setSales(response.data);
    };
    const SalesByDays = async () => {
      const response = await ApiManager.get("/Sales/SalesByDays", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setSales(response.data);
    };
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if (role === "Drejtor") {
      var selectedBusiness = localStorage.getItem("selectedBusiness");
      if (selectedBusiness === "0") {
        AllSalesByDays();
      } else {
        CertainSalesByDays();
      }
    } else {
      SalesByDays();
    }
  }, [selectedBusiness]);

  const data = {
    labels,
    datasets: [
      {
        label: "Shitjet",
        data: labels.map((_, i) => sales[i]),
        backgroundColor: "rgba(66, 42, 251, 0.8)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default MonthlyBar;
