import React, { useEffect, useState } from "react";
import { useStore } from "app/stores/store";
import CartItem from "./CartItem";
import { observer } from "mobx-react";

const WaiterCart = observer(({ tableId, forceRender, setForceRender }) => {
  const [cartItems, setCartItems] = useState([]);
  const { cartStore } = useStore();
  const [totalPrice, setTotalPrice] = useState(0);
  const { ordersStore } = useStore();
  const [changed, setChanged] = useState(0);

  useEffect(() => {
    const fetchCartForTable = () => {
      const tableCart = cartStore.getCartForTable(tableId);
      setCartItems(tableCart);
      setTotalPrice(cartStore.totalPrice(tableCart));
    };

    fetchCartForTable();
  }, [cartStore.carts[tableId], forceRender, changed]);

  const handleOrderSaved = (tableId, orderData) => {
    ordersStore.addOrder(orderData);
    setForceRender(prevState => !prevState);
  };
  const saveOrder = (value) => {
    cartStore.saveOrderToDb(tableId, handleOrderSaved, value);
  };

  const formatPrice = (price) => {
    return parseFloat(price).toFixed(2) + " €";
  };

  return (
    <div className="lg:mt-8 w-full rounded-xl bg-lightPrimary p-3 lg:w-2/4">
      <div className="mb-2 flex items-center justify-between md:flex-row">
        <span className="text-sm font-semibold text-navy-700 md:text-base">Totali i pagesës: </span>
        <span className="text-sm font-semibold text-navy-700 md:text-base">
          {formatPrice(totalPrice)}
        </span>
      </div>
      <div className="h-48 overflow-y-auto overflow-x-hidden md:h-60 lg:h-3/5 xl:h-4/5">
        {cartItems && cartItems.length > 0 && (
          <div>
            {cartItems.map((cartItem) => (
              <CartItem
                key={`${cartItem.productType}-${cartItem.id}-${tableId}`}
                cartItem={cartItem}
                tableId={tableId}
                forceRender={forceRender}
                setForceRender={setForceRender}
                changeChanged={setChanged}
              />
            ))}
          </div>
        )}
      </div>
      {cartItems && cartItems.length > 0 && (
        <div>
          <button
          className="btn btn-outline btn-success btn-sm mt-4 w-full lg:mt-0.5 "
          onClick={() => saveOrder("TAKE OUT")}
        >
          Bëje porosinë(me vete)
        </button>
        <button
          className="btn btn-outline btn-success btn-sm mt-2 w-full "
          onClick={() => saveOrder("DINE-IN")}
        >
          Bëje porosinë(këtu)
        </button>
        </div>
      )}
    </div>
  );
});
export default WaiterCart;
