import React, { useMemo, useState, useEffect } from 'react';
import Card from 'components/card';
import { columnsFiskalData } from '../variables/columnsFiskalData';
// import { data } from '../variables/fiskalStockData';
import StockFiskalItemDetails from './StockFiskalItemDetails';
import { ApiManager } from 'app/ApiManager';

const StockFiskalTable = () => {
  const columns = useMemo(() => columnsFiskalData, [columnsFiskalData]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([])

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
  };

  useEffect(() => {
    const fetchFiscal = async () => {
      const response = await ApiManager.get(`/FiscalStock/GetByBusinessId`, {
        params: {
          jwtToken: localStorage.getItem("token")
        }
      })
      setData(response.data);
    }
    fetchFiscal();
  }, [])

  return (
    <>
    
    <div id="fiskal-stock" style={{boxShadow:"unset" }}>
      <Card extra={"w-full sm:overflow-auto p-4"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700">
            <h3>Stoku Fiskal</h3>
          </div>
        </header>
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full" variant="simple" color="gray-500" mb="24px">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-16 pb-[10px] text-start ">
                  <div className="text-xs font-medium tracking-wide text-gray-800">
                    NR.
                  </div>
                </th>
                {columns.map((column, index) => (
                  <th
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                    key={index}>
                    <div className="text-xs font-medium tracking-wide text-gray-800">
                      {column.Header}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.length >= 1 ? data.map((stock, index) => (
                <tr 
                  key={index}
                  onClick={() => handleRowClick(stock)}
                  className='py-2 cursor-pointer hover:bg-indigo-100 '
                >
                  <td>{++index}</td>
                  <td>{stock.itemName}</td>
                  <td>{stock.quantity}</td>
                  <td>{stock.unitOfMeasure}</td>
                  <td>{stock.pricePerUnit?.toFixed(2)} €</td>
                  <td>{stock.salePercentage}%</td>
                </tr>
              )) : 
              <tr>
                <td colSpan={6} className='text-center font-bold py-2'>Nuk ka të dhëna</td>  
              </tr>}
            </tbody>
          </table>
        </div>
      </Card>
      {selectedRow && <StockFiskalItemDetails data={selectedRow} />}
      </div>
    </>
  );
};

export default StockFiskalTable;
