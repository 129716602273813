import Card from "components/card";
import React, { useState, useEffect } from "react";
import burger from "../../../../assets/svg/product/burger.svg";
import soda from "../../../../assets/svg/product/soda.svg";
import cake from "../../../../assets/svg/product/cheesecake.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  MdOutlineArchive,
  MdOutlineRestaurantMenu,
  MdOutlineUnarchive,
} from "react-icons/md";
import { useStore } from "app/stores/store";

const AddProductsNavbar = (props) => {
  const navigation = useNavigate();
  const { upsertProductStore } = useStore();
  const [showArchivedProducts, setShowArchivedProducts] = useState(false);

  useEffect(() => {
    if (props.isArchived !== null) {
      setShowArchivedProducts(props.isArchived);
    }
  }, []);

  const handleAddProduct = (productType) => {
    navigation("/admin/product-upsert", {
      state: {
        productType: productType,
      },
    });
  };

  const handleArchivedProductList = () => {
    setShowArchivedProducts(!showArchivedProducts);
    upsertProductStore.showArchivedProducts =
      !upsertProductStore.showArchivedProducts;
  };
  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <div className="flex flex-col justify-between gap-3 md:flex-row">
          <div>
            <button
              onClick={handleArchivedProductList}
              className="!linear z-[1] flex w-full items-center justify-center gap-2
              rounded-lg bg-lightPrimary px-3 py-2 text-brand-500 !transition 
              !duration-200 hover:bg-gray-100 active:bg-gray-200 md:w-auto"
            >
              {showArchivedProducts === true ? (
                <span className="flex items-center">
                  Meny &nbsp; <MdOutlineUnarchive className="h-5 w-5" />
                </span>
              ) : (
                <span className="flex items-center">
                  Meny e arkivuar &nbsp;
                  <MdOutlineArchive className="h-5 w-5" />
                </span>
              )}
            </button>
          </div>
          <div>
            <Link
              to="/admin/extraProduct-list"
              className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
              bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200"
            >
              <span className="flex items-center">
                Produktet shtesë &nbsp;
                <MdOutlineRestaurantMenu className="h-5 w-5" />
              </span>
            </Link>
          </div>
          <div className="flex justify-center gap-x-5">
            {/* FOOD */}

            <button
              onClick={() => handleAddProduct("Food")}
              className="linear flex items-center justify-center rounded-lg bg-lightPrimary bg-none
              p-2 text-xl font-bold text-white text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100
          hover:bg-none active:bg-none "
            >
              <span
                className="px-1"
                style={{ color: "rgb(66, 42, 251)" }}
                width={"20px"}
              >
                +
              </span>

              <img src={burger} width={"20px"} alt="add food" />
            </button>
            {/* DRINK */}
            <button
              onClick={() => handleAddProduct("Drink")}
              className="linear flex items-center justify-center rounded-lg bg-lightPrimary bg-none
              p-2 text-xl font-bold text-white text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100
          hover:bg-none active:bg-none"
            >
              <span
                className="px-1"
                style={{ color: "rgb(66, 42, 251)" }}
                width={"20px"}
              >
                +
              </span>
              <img src={soda} width={"20px"} alt="add drink" />
            </button>
            {/* CAKE */}
            <button
              onClick={() => handleAddProduct("Dessert")}
              className="linear flex items-center justify-center rounded-lg bg-lightPrimary bg-none
              p-2 text-xl font-bold text-white text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100
          hover:bg-none active:bg-none"
            >
              <span
                className="px-1"
                style={{ color: "rgb(66, 42, 251)" }}
                width={"20px"}
              >
                +
              </span>
              <img src={cake} width={"20px"} alt="add cake" />
            </button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AddProductsNavbar;
