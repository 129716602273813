import { useState, useEffect } from "react";
import { ApiManager } from "app/ApiManager";
import jwtDecode from "jwt-decode";

const UserCount = ({ selectedBusiness }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const GetAllUsers = async () => {
      try {
        const response = await ApiManager.get(`/User/UsersByUserId`, {
          params: {
            jwtToken: localStorage.getItem("token"),
          },
        });
        setTotal(response.data);
      } catch (error) {
        console.log(error, "err1");
      }
    };
    const GetCertainUsers = async () => {
      const response = await ApiManager.get(
        `/User/UsersByBusinessId/${selectedBusiness}`
      );
      setTotal(response.data);
    };
    const GetUsers = async () => {
      const response = await ApiManager.get(`/User/UserCount`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setTotal(response.data);
    };
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if (role === "Drejtor") {
      var selectedBusiness = localStorage.getItem("selectedBusiness");
      if (selectedBusiness === "0") {
        GetAllUsers();
      } else {
        GetCertainUsers();
      }
    } else {
      GetUsers();
    }
  }, [selectedBusiness]);
  return total;
};

export default UserCount;
