import React, { useEffect, useState } from "react";
import Card from "components/card";
import { ApiManager } from "app/ApiManager";
import InputField from "components/fields/InputField";
import { Spinner } from "flowbite-react";
import Swal from "sweetalert2";

const Profile = () => {
  const [user, setUser] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchUserProfile();
  }, []);
  
  const fetchUserProfile = () => {
    const jwtToken = localStorage.getItem("token");
    ApiManager.get("/User/ProfileOfUser", {
      params: {
        jwtToken: jwtToken,
      },
    })
    .then((response) => {
      setUser(response.data);
      if (response.data.base64Image) {
        setProfilePicture(response.data.base64Image);
      }
    })
    .catch((error) => {
      console.error("Failed to fetch user profile:", error);
    });
  };
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024 * 5) {
        Swal.fire({
          icon: "error",
          title: "Fotoja e profilit nuk mund te jete me e madhe se 5MB!",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      setSelectedImage(file);
      setUploadedImage(URL.createObjectURL(file));
    }
  };

  const uploadProfilePicture = () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("imageFile", selectedImage);
      formData.append("jwtToken", localStorage.getItem("token"));

      setIsWaiting(true);
      ApiManager.post("/User/UploadProfilePicture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Fotoja e profilit u ngarkua me sukses!",
          showConfirmButton: false,
          timer: 2000,
        });
        // You should be checking response.data.base64Image here
        setProfilePicture(response.data.base64Image);
        // No need to set uploadedImage here as you already update it after fetching user profile
        setIsWaiting(false);
        fetchUserProfile(); // to update the profile with the new image
      })
      
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Gabim gjate ngarkimit te fotos! Ju lutem provoni perseri!",
            showConfirmButton: false,
            timer: 2000,
          });
          setIsWaiting(false);
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Ju lutem zgjidhni nje foto per te ngarkuar!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const changePassword = () => {
    setIsWaiting(true);
    var jwtToken = localStorage.getItem("token");
    ApiManager.put(
      "/User/ChangePassword",
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      {
        params: {
          jwtToken: jwtToken,
        },
      }
    )
      .then((response) => {
        switch (response.data) {
          case -3:
            Swal.fire({
              icon: "error",
              title: "Ka ndodhur nje gabim! Ju lutem provoni perseri!",
              showConfirmButton: false,
              timer: 2000,
            });
            break;
          case -2:
            Swal.fire({
              icon: "error",
              title: "Fjalekalimi i ri duhet te jete me i gjate se 6 karaktere!",
              showConfirmButton: false,
              timer: 2500,
            });
            break;
          case -1:
            Swal.fire({
              icon: "error",
              title: "Fjalekalimi aktual eshte gabim!",
              showConfirmButton: false,
              timer: 2000,
            });
            break;
          case 0:
            Swal.fire({
              icon: "error",
              title: "Fjalekalimi i ri nuk mund te jete i njejte me fjalekalimin aktual!",
              showConfirmButton: false,
              timer: 2500,
            });
            break;
          case 1:
            Swal.fire({
              icon: "success",
              title: "Fjalekalimi u ndryshua me sukses!",
              showConfirmButton: false,
              timer: 2000,
            });
            break;
          default:
            break;
        }
        setIsWaiting(false);
      })
      .catch((error) => {
        setIsWaiting(false);
      });
  };

  const renderProfileImage = () => {
    if (profilePicture) {
      // Make sure the profilePicture is not an object before using it
      return (
        <img
          src={`data:image/jpeg;base64,${profilePicture}`}
          alt="Profile Picture"
          className="w-[8rem]"
        />
      );
    } else {
      return <p>No profile picture available</p>;
    }
  };
  
  return (
    <>
      <Card extra={"w-full h-full p-3 mt-5"}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col items-start justify-center gap-2 rounded-lg bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-base text-gray-800">Emri</p>
            <p className="text-base font-medium text-navy-700">
              {user.fullName}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center gap-2 rounded-lg bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-base text-gray-800">Mbiemri</p>
            <p className="text-base font-medium text-navy-700">
              {user.userName}
            </p>
          </div>
          <div className="flex flex-col justify-center gap-2 rounded-lg bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-base text-gray-800">Email</p>
            <p className="text-base font-medium text-navy-700">
              {user.email || "Nuk eshte dhene"}
            </p>
          </div>
          <div className="flex flex-col justify-center gap-2 rounded-lg bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 ">
            <p className="text-base text-gray-800">Numri i telefonit</p>
            <p className="text-base font-medium text-navy-700">
              {user.phoneNumber || "Nuk eshte dhene"}
            </p>
          </div>
          <div className="flex flex-col justify-center rounded-lg bg-white bg-clip-border px-3 py-4">
            <InputField
              onChange={(e) => setOldPassword(e.target.value)}
              variant="auth"
              extra="mb-1 text-base"
              label="Fjalëkalimi aktual"
              id="oldPassword"
              name="oldPassword"
              type="password"
            />
          </div>
          <div className="flex flex-col justify-center rounded-lg bg-white bg-clip-border px-3 py-4">
            <InputField
              onChange={(e) => setNewPassword(e.target.value)}
              variant="auth"
              extra="mb-1 text-base"
              label="Fjalëkalimi i ri"
              id="newPassword"
              name="newPassword"
              type="password"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 rounded-lg bg-white bg-clip-border">
          <div className="flex flex-col rounded-lg px-3 py-4">
            <InputField
              onChange={handleImageChange}
              variant="auth"
              extra="mb-1 w-full"
              label="Ngarko foton e profilit"
              id="profilePicture"
              name="profilePicture"
              type="file"
            />
          </div>
          <div className="justify-self-center place-self-center">{renderProfileImage()}</div>
        </div>
        <div className="flex gap-4">
          <button
            onClick={uploadProfilePicture}
            className="linear hover-bg-custom active:bg-brand-70 mt-4 flex w-full items-center justify-center rounded-xl 
              bg-brand-500 py-3 text-lg font-medium text-white 
              transition duration-200 hover:bg-brand-600"
          >
            Ngarko foton e profilit
          </button>

          {isWaiting ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <button
              onClick={changePassword}
              className="linear hover-bg-custom active:bg-brand-70 mt-4 flex w-full items-center justify-center rounded-xl 
                 bg-brand-500 py-3 text-lg font-medium text-white 
                 transition duration-200 hover:bg-brand-600"
            >
              Ndrysho fjalëkalimin
            </button>
          )}
        </div>
      </Card>
    </>
  );
};

export default Profile;

