  import React, { useEffect, useState } from "react";
  import StaffTable from "./components/StaffTable";
  import { columnsData } from "./variables/ColumnsData";
  import { ApiManager } from "app/ApiManager";

const EmployeeList = () => {
  const [employeeList, setEmployeeList] = useState([]);
  useEffect(() => {
    ApiManager.get("/User/ListOfEmployees", {
      params: {
        jwtToken: localStorage.getItem("token"),
      }
    })
    .then((response) => {
      // Map isActive boolean to a user-friendly string.
      const employeesWithStatus = response.data.map(employee => ({
        ...employee,
        isActive: employee.isActive ? "Aktiv" : "Jo aktiv", // Convert boolean to string
      }));
      setEmployeeList(employeesWithStatus);
    })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
        <StaffTable
          columnsData={columnsData}
          tableData={employeeList}
          tableName="Lista e punonjësve"
        />
      </div>
    </>
  );
};

  export default EmployeeList;
