import React, { useEffect, useState } from "react";
import Card from "components/card";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiManager } from "app/ApiManager";
import {
  MdOutlineArchive,
  MdOutlineUnarchive,
  MdOutlineEdit,
} from "react-icons/md";
import RecipeInput from "./components/RecipeInput";
import { useStore } from "app/stores/store";
import Swal from "sweetalert2";

const ProductDetails = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { productId, productType } = location.state;
  const [product, setProduct] = useState({});
  const { upsertProductStore } = useStore();
  const [archiveProduct, setArchiveProduct] = useState();

  useEffect(() => {
    let endpoint = "";
    if (productType === "Food") {
      endpoint = `/Food/GetFood/${productId}?requireImg=true`;
    } else if (productType === "Drink") {
      endpoint = `/Drink/GetDrink/${productId}?requireImg=true`;
    } else if (productType === "Dessert") {
      endpoint = `/Dessert/GetDessert/${productId}?requireImg=true`;
    }
    if (endpoint) {
      const requestData = false;
      ApiManager.get(endpoint, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setProduct(response.data);
          setArchiveProduct(response.data.isArchived);
          upsertProductStore.setRowsData(response.data.recipes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleEdit = () => {
    navigation("/admin/product-upsert", {
      state: {
        productId: productId,
        productType: productType,
      },
    });
  };

  const handleArchive = () => {
    let endpoint = "";
    if (productType === "Food") {
      endpoint = `/Food/ArchiveFood/${productId}`;
    } else if (productType === "Drink") {
      endpoint = `/Drink/ArchiveDrink/${productId}`;
    } else if (productType === "Dessert") {
      endpoint = `/Dessert/ArchiveDessert/${productId}`;
    }

    if (endpoint) {
      const requestData = !archiveProduct;
      ApiManager.post(endpoint, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setArchiveProduct(!archiveProduct);
        const titleMessage = archiveProduct ? "Produkti u largua nga arkiva me sukses!" : "Produkti u arkivua me sukses!";
        Swal.fire({
          title: titleMessage,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
          
        }).then((result) => {
            navigation("/admin/product-list"); 
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  const renderProductImage = () => {
    if (product.base64Image) {
      return (
        <img
          src={`data:image/jpeg;base64,${product.base64Image}`}
          alt={`${product.name}`}
          className="h-[20rem] w-[20rem] rounded-xl object-cover"
        />
      );
    } else {
      return <p>No image available</p>;
    }
  };

  const formatPrice = (price) => {
    return parseFloat(price).toFixed(2) + " €";
  }; 

  return (
    <Card extra={"w-full h-full p-3 mt-5"}>
      {/* Header */}
      <div className="flex w-full flex-col">
        <div className="flex flex-col-reverse justify-between md:flex-row">
          <h4 className="px-2 text-xl font-bold text-navy-700">
            {product.name}
          </h4>
          <div className="flex justify-between gap-3 mb-4 lg:mb-0">
            <button
              onClick={handleArchive}
              className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
              bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200"
            >
              {archiveProduct ? (
                <span className="flex items-center">
                  Largo nga arkiva &nbsp;
                  <MdOutlineArchive className="h-5 w-5" />
                </span>
              ) : (
                <span className="flex items-center">
                  Arkivo &nbsp; <MdOutlineUnarchive className="h-5 w-5" />
                </span>
              )}
            </button>
            <button
              onClick={handleEdit}
              className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
              bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200"
            >
              Përditëso
              <MdOutlineEdit className="h-5 w-5 " />
            </button>
          </div>
        </div>
        <p className="px-2 text-base text-gray-700">{product.description}</p>
      </div>
      {/* Cards */}
      <div className="mt-5 grid gap-4 md:grid-cols-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 ">
          <p className="text-sm text-gray-800">Emërtimi</p>
          <p className="text-base font-medium text-navy-700">{product.name}</p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
          <p className="text-sm text-gray-800">Çmimi</p>
          <p className="text-base font-medium text-navy-700">
            {formatPrice(product.price)}{" "}
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
          <p className="text-sm text-gray-800">Koha e përgatitjes</p>
          <p className="text-base font-medium text-navy-700 ">
            {product.cookingTime} min
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
          <p className="text-sm text-gray-800">Kaloritë</p>
          <p className="text-base font-medium text-navy-700">
            {product.calories} kCal
          </p>
        </div>
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
          <p className="text-sm text-gray-800">Përgatitet në</p>
          <p className="text-base font-medium text-navy-700">
            {product.preparedAt}
          </p>
        </div>
        {product.toppings && product.toppings.length > 0 && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Përbërësit</p>
            <div>
              {product.toppings.map((topping, index) => (
                <span
                  className="inline-block text-base font-medium text-navy-700 "
                  key={index}
                >
                  {topping.name}
                  {index !== product.toppings.length - 1 && (
                    <span>,&nbsp;</span>
                  )}
                </span>
              ))}
            </div>
          </div>
        )}
        {product.subcategories && product.subcategories.length > 0 && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 ">
            <p className="text-sm text-gray-800">Nënkategoritë</p>
            <div>
              {product.subcategories.map((subcategory, index) => (
                <span
                  className="inline-block text-base font-medium text-navy-700"
                  key={index}
                >
                  {subcategory.name}
                  {index !== product.subcategories.length - 1 && (
                    <span>,&nbsp;</span>
                  )}
                </span>
              ))}
            </div>
          </div>
        )}
        {product.extraProducts && product.extraProducts.length > 0 && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="mb-4 text-sm text-xl font-medium text-gray-800">
              Produktet shtesë
            </p>
            <div>
              {product.extraProducts.map((extraProduct, index) => (
                <span
                  className="inline-block text-base font-medium text-navy-700"
                  key={index}
                >
                  {extraProduct.name}
                  {index !== product.extraProducts.length - 1 && (
                    <span>,&nbsp;</span>
                  )}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex w-full justify-center">
        {/* Show current image path if product is being edited */}
        {renderProductImage()}
      </div>

      {product.recipes && product.recipes.length > 0 && (
        <div className="w-full p-2">
          <h3 className="my-4 text-xl font-bold text-navy-700">
            Përbërësit e produktit
          </h3>
          <table className="w-full table-auto">
            <thead className="border-b border-gray-200 pb-[10px]">
              <tr className="flex justify-between items-center">
                <th className="text-sm font-medium text-gray-800">
                  Emri i përbërësit
                </th>
                <th className="text-sm font-medium text-gray-800">Sasia</th>
                <th className="text-sm font-medium text-gray-800">
                  Njësia matëse
                </th>
              </tr>
            </thead>
            <tbody>
              {product.recipes.map((data, index) => {
                const { ingredientName, quantity, unitQuantity } = data;
                return (
                  <tr
                    key={index}
                    className="flex items-center justify-between border-b border-gray-200 bg-white bg-white/0"
                  >
                    <td>
                      <RecipeInput
                        type="text"
                        value={ingredientName}
                        name="ingredientName"
                        disabled={true}
                        extraClassNames="font-medium text-navy-700"
                      />
                    </td>
                    <td>
                      <RecipeInput
                        type="text"
                        value={quantity}
                        name="quantity"
                        disabled={true}
                        extraClassNames="font-medium text-navy-700"
                      />
                    </td>
                    <td>
                      <RecipeInput
                        type="text"
                        value={unitQuantity}
                        name="unitQuantity"
                        disabled={true}
                        extraClassNames="font-medium text-navy-700"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
};

export default ProductDetails;
