import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorLabel from "components/error/ErrorLabel";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectList from "views/admin/menu/components/SelectList";
import {
  optionsUnitOfMeasure,
  optionsItemType,
} from "../variables/SelectListOptions";
import { validate } from "../variables/ValidateStock";
import SpinnerButton from "components/buttons/SpinnerButton";
import Swal from "sweetalert2";
import AddProduct from "./AddProduct";
import Supplier from "./Supplier";

const Upsert = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [selectedItemType, setSelectedItemType] = useState(optionsItemType[0]);
  const [selectedUnitOfMeasure, setSelectedUnitOfMeasure] = useState(
    optionsUnitOfMeasure[0]
  );
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(0);
  const { stockType } = location.state;
  const [headerText, setHeaderText] = useState("");
  const [stockItem, setStockItem] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [extraProducts, setExtraProducts] = useState(null);
  const [preparedAt, setPreparedAt] = useState("Kuzhinë");
  const [productType, setProductType] = useState("Ushqim");
  const [stockTypeOptions, setStockTypeOptions] = useState([
    {
      name: "Kuzhinë",
      value: "KitchenStock",
    },
    {
      name: "Bar",
      value: "BarStock",
    },
  ]);
  const [selectedStockType, setSelectedStockType] = useState({
    name: "Kuzhinë",
    value: "KitchenStock",
  });

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const fetchSuppliers = () => {
    ApiManager.get(`/Supplier/GetByBusinessId`, {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    }).then((response) => {
      setSuppliers(response.data);
    })
  }

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await ApiManager.get(`/Supplier/GetByBusinessId`, {
          params: {
            jwtToken: localStorage.getItem("token"),
          },
        })
        setSuppliers(response.data);
      }
      catch(err) {

      }
    }
    fetchSuppliers();
    if (location.state) {
      const { stockId } = location.state;
      if (stockId) {
        setHeaderText("Përditësim");
        let endpoint = "";
        if (stockType === "BarStock") {
          endpoint = `/BarStock/GetBarStockById/${stockId}`;
        } else if (stockType === "KitchenStock") {
          endpoint = `/KitchenStock/GetKitchenStockById/${stockId}`;
        }
        if (endpoint) {
          const fetchData = async () => {
            try {
              const response = await ApiManager.get(endpoint);
              setStockItem(response.data);
            } catch (error) {}
          };
          fetchData();
        }
      } else {
        setHeaderText("Regjistrim");
      }
    }
  }, [location.state, stockType]);

  const formik = useFormik({
    initialValues: {
      itemName: stockItem?.itemName || "",
      description: "",
      quantity: "",
      pricePerUnit: "",
      reorderLevel: stockItem?.reorderLevel || "",
      itemType: selectedItemType.name,
      unitOfMeasure: selectedUnitOfMeasure.name,
      stockId: "",
      supplierId: selectedSupplier,
    },
    validate,
    onSubmit: (values) => {
      values.unitOfMeasure = selectedUnitOfMeasure.name;
      if(values.supplierId === 0)
        return;
      setIsWaiting(true);
      const formData = new FormData();
      if (
        !location.state.stockId &&
        selectedItemType.name === "Lëndë e gatshme"
      ) {
        var productName = document.getElementById("name");
        var price = document.getElementById("price");
        var description = document.getElementById("description");
        var calories = document.getElementById("calories");
        var cookingTime = document.getElementById("cookingTime");
        var isSale = document.querySelector("[type=checkbox]");
        var productPicture = document.getElementById("ImageFile");

        formData.append("name", productName?.value);
        formData.append("price", price?.value);
        formData.append("inDiscount", isSale?.checked);
        formData.append("productDescription", description?.value);
        formData.append("calories", calories?.value);
        formData.append("cookingTime", cookingTime?.value);
        if (isSale.checked) {
          var startDate = document.getElementById("startDate");
          var endDate = document.getElementById("endDate");
          formData.append("startDate", startDate?.value);
          formData.append("endDate", endDate?.value);
          // product.startDate = startDate?.value;
          // product.endDate = endDate?.value;
        }
        if (productPicture.files.length >= 1) {
          //product.imageFile = productPicture.files[0];
          formData.append("imageFile", productPicture.files[0]);
        }
        formData.append("stockType", selectedStockType.value);
        formData.append("preparedAt", preparedAt);
        formData.append("productType", productType);
        const recipeData = [
          {
            ingredientName: values.itemName,
            quantity: 1,
            unitQuantity: selectedUnitOfMeasure?.name,
            recipeId: 0,
            type: selectedStockType.value === "KitchenStock" ? "Kitchen" : "Bar",
            stockId: 0,
          },
        ];
        formData.append("RecipeDataJSON", JSON.stringify(recipeData));
      }
      const jwtToken = localStorage.getItem("token");
      let endpoint;
      if (selectedStockType.value === "KitchenStock") {
        if (stockItem) {
          endpoint = "/KitchenStock/EditInKitchenStock";
        } else {
          endpoint = "/KitchenStock/AddInKitchenStock";
        }
      } else if (selectedStockType.value === "BarStock") {
        if (stockItem) {
          endpoint = "/BarStock/EditInBarStock";
        } else {
          endpoint = "/BarStock/AddInBarStock";
        }
      }
      if (location.state.stockId || selectedItemType.name === "Lëndë e parë") {
        ApiManager.post(endpoint, values, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            jwtToken: jwtToken,
          },
        })
          .then((response) => {
            setIsWaiting(false);
            Swal.fire({
              icon: "success",
              title: `Stoku eshte ${
                stockItem ? "perditësuar" : "shtuar"
              } me sukses`,
              timer: 2000,
              showConfirmButton: false,
            }).then(() => {
              navigation("/admin/stock-list");
            });
          })
          .catch((error) => {
            console.log(error);
            setIsWaiting(false);
          });
      } else {
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        Object.keys(formik.values).forEach((key) => {
          if (key !== "ImageFile") {
            // Exclude the file since it's already added
            formData.append(key, formik.values[key]);
          }
        });
        formData.append("ExtraProductsJSON", JSON.stringify(extraProducts));
        ApiManager.post(`/Stock/AddStockAndProduct`, formData, {
          params: {
            jwtToken: jwtToken,
          },
        })
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: `Stoku u ${
                stockItem ? "perditësuar" : "shtuar"
              } me sukses`,
              timer: 2000,
              showConfirmButton: false,
            }).then(() => {
              navigation("/admin/stock-list");
            });
          })
          .catch((err) => {});
      }
      setIsWaiting(false);
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      unitOfMeasure: selectedUnitOfMeasure?.name || "",
      selectedItemType: selectedItemType?.name || "",
      itemName: stockItem?.itemName || "",
      description: stockItem?.description || "",
      quantity: stockItem?.quantity || "",
      pricePerUnit: stockItem?.pricePerUnit || 0,
      reorderLevel: stockItem?.reorderLevel || "",
      stockId: stockItem?.stockId || "",
      supplierId: stockItem?.suppliers[0]?.id || 0,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockItem]);

  const { touched, errors, handleSubmit } = formik;

  const changeProductName = () => {
    var productName = document.getElementById("name");
    if (productName) {
      productName.value = "12";
    }
  };

  const handleAnulo = () => {
    navigation("/admin/stock-list");
  };

  const changeCurrentSupplier = (value) => {
    setSelectedSupplier(parseInt(value));
  }

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-3 mt-5"}>
        <h3 className="mb-5 text-xl font-bold text-navy-700">
          {headerText} i artikullit në stok
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-between gap-x-3 md:flex-row">
            <div className="w-full items-center">
              <SelectList
                label="Stoku ku do të regjistrohet*"
                selected={selectedStockType}
                setSelected={setSelectedStockType}
                options={stockTypeOptions}
              />
            </div>
            <div className="mb-3 w-full">
              <InputField
                onChange={changeProductName}
                variant="auth"
                extra="mb-1"
                label="Emërtimi*"
                id="itemName"
                name="itemName"
                type="text"
                {...formik.getFieldProps("itemName")}
                state={errors.itemName && touched.itemName ? "error" : null}
              />
              {errors.itemName && touched.itemName ? (
                <ErrorLabel error={errors.itemName} />
              ) : null}
            </div>
          </div>
          <div className="flex flex-col justify-between gap-x-3 md:flex-row">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Sasia*"
                id="quantity"
                name="quantity"
                type="number"
                {...formik.getFieldProps("quantity")}
                state={errors.quantity && touched.quantity ? "error" : null}
              />
              {errors.quantity && touched.quantity ? (
                <ErrorLabel error={errors.quantity} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Përshkrimi*"
                id="description"
                name="description"
                type="text"
                {...formik.getFieldProps("description")}
                state={
                  errors.description && touched.description ? "error" : null
                }
              />
              {errors.description && touched.description ? (
                <ErrorLabel error={errors.description} />
              ) : null}
            </div>
          </div>

          <div className="mt-5 flex flex-col justify-between gap-4 md:flex-row">
            <div className="w-full items-center">
              <SelectList
                label="Njësia matëse e sasisë*"
                selected={selectedUnitOfMeasure}
                setSelected={setSelectedUnitOfMeasure}
                options={optionsUnitOfMeasure}
              />
            </div>
            <div className="w-full items-center">
              <SelectList
                label="Lloji i artikullit*"
                selected={selectedItemType}
                setSelected={setSelectedItemType}
                options={optionsItemType}
              />
            </div>
          </div>
          <div className="mt-5 flex flex flex-col justify-between gap-4 md:flex-row">
            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Çmimi për njësi* (EUR)"
                id="pricePerUnit"
                name="pricePerUnit"
                type="number"
                {...formik.getFieldProps("pricePerUnit")}
                state={
                  errors.pricePerUnit && touched.pricePerUnit ? "error" : null
                }
              />
              {errors.pricePerUnit && touched.pricePerUnit ? (
                <ErrorLabel error={errors.pricePerUnit} />
              ) : null}
            </div>
            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Niveli i sasisë minimale të artikullit*"
                id="reorderLevel"
                name="reorderLevel"
                type="number"
                {...formik.getFieldProps("reorderLevel")}
                state={
                  errors.reorderLevel && touched.reorderLevel ? "error" : null
                }
              />
              {errors.reorderLevel && touched.reorderLevel ? (
                <ErrorLabel error={errors.reorderLevel} />
              ) : null}
            </div>
          </div>
          <div className="mt-5">
          <div class="relative">
              <label>Furnitori</label>
              <select
                id="owner"
                state={errors.name && touched.name ? "error" : null}
                onChange={(e) => {
                  changeCurrentSupplier(e.target.value);
                  openModal();
                }}
                class="focus:shadow-outline block w-full appearance-none rounded border border-gray-300 bg-white px-4 py-2 pr-8 leading-tight shadow hover:border-gray-500 focus:outline-none"
              >
                <option value="0">Shto</option>
                {suppliers?.map((supplier) => {
                  return selectedSupplier === supplier.id ? (
                    <option selected key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ) : (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="h-4 w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 14.707a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L10 12.586l-3.293-3.293a1 1 0 1 0-1.414 1.414l4 4z" />
                </svg>
              </div>
            </div>
            {selectedSupplier === 0 || suppliers.length <= 0 ? (
              <Supplier 
                isOpen={modalOpen}
                onClose={closeModal}
                onSubmitSuccess={fetchSuppliers}
              />
            ) : null}
          </div>
          
          {headerText === "Regjistrim" &&
          selectedItemType &&
          selectedItemType.name === "Lëndë e gatshme" ? (
            <AddProduct
              setExtra={setExtraProducts}
              setPreparedAt={setPreparedAt}
              setType={setProductType}
            />
          ) : null}
          <div className="justify-center gap-5 md:flex">
            <SpinnerButton title={"Ruaj të dhënat"} isWaiting={isWaiting} />
            <SpinnerButton
              title={"Anulo të dhënat"}
              isWaiting={isWaiting}
              secondary={true}
              onClick={() => handleAnulo()}
            />
          </div>
        </form>
      </Card>
    </>
  );
};

export default Upsert;
