import React, { useState, useEffect } from "react";
import Card from "components/card";
import { columnsData } from "../variables/columnsData";
import StockItem from "./StockItem";
import SelectList from "views/admin/menu/components/SelectList";
import { ApiManager } from "app/ApiManager";
import jsPDF from "jspdf";
import { phone } from "utils/phone";
import { mail } from "utils/mail";
import { web } from "utils/web";
import PrintReceipt from "./PrintReceipt";
import Swal from "sweetalert2";

const Receipt = () => {
  const [rowArray, setRowArray] = useState([]);
  const [receiptType, setReceiptType] = useState("Faturë");
  const [stockOptions, setStockOptions] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [headerText, setHeaderText] = useState("Stoku FIFO");
  const [business, setBusiness] = useState(null);
  const [base64Logo, setBase64Logo] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectOptions, setSelectOptions] = useState([
    {
      value: 1,
      name: "Stoku FIFO",
    },
    {
      value: 2,
      name: "Stoku FISKAL",
    },
  ]);
  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    name: "Stoku FIFO",
  });

  const checkForChange = (stocks) => {
    if (rowArray.length !== 0)
      if (rowArray.length === stocks.length)
        setRowArray(
          stocks.map((stock) => {
            return stock.id;
          })
        );
    if (rowArray.length > stocks.length)
      setRowArray(
        stocks.map((stock) => {
          return stock.id;
        })
      );
  };

  const changeReceiptType = (value) => {
    setReceiptType(value);
  };

  useEffect(() => {
    const fetchBase64 = async () => {
      const response = await ApiManager.get("/Business/GetBase64Logo", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setBase64Logo(response.data);
    };
    const fetchBusiness = async () => {
      const response = await ApiManager.get("/Business/GetBusinessByJwt", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setBusiness(response.data);
    };
    const fetchFifoStock = async () => {
      const response = await ApiManager.get(`/FifoStock/GetByBusinessId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setStocks(response.data);
      setStockOptions(
        response.data.map((stock) => ({
          value: stock.id,
          name: stock.itemName,
        }))
      );
      checkForChange(response.data);
    };
    const fetchFiscalStock = async () => {
      const response = await ApiManager.get(`/FiscalStock/GetByBusinessId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setStocks(response.data);
      setStockOptions(
        response.data.map((stock) => ({
          value: stock.id,
          name: stock.itemName,
        }))
      );
      checkForChange(response.data);
    };
    fetchBusiness();
    fetchBase64();
    if (selectedOption.name === "Stoku FIFO") fetchFifoStock();
    else fetchFiscalStock();
  }, [selectedOption]);

  const changeText = (option) => {
    if (option.value === 1) setHeaderText("Stoku FIFO");
    else setHeaderText("Stoku FISKAL");
    setSelectedOption(option);
  };

  const addRow = () => {
    if (rowArray.length === 0) {
      setRowArray((prev) => [...prev, stocks[0].id]);
    } else {
      setRowArray((prev) => [...prev, stocks[prev.length - 1].id + 1]);
      // const updatedStocks = [...stockOptions];
      // updatedStocks.shift();
      // setStockOptions(updatedStocks);
    }
  };

  const deleteRow = (indexToDelete) => {
    setRowArray((prev) => {
      return prev.filter((_, index) => index !== indexToDelete);
    });
  };

  const changeClient = (value) => {
    ApiManager.get(`/Client/GetById`, {
      params: {
        clientId: value,
      },
    })
      .then((response) => {
        setSelectedClient(response.data);
      })
      .catch((err) => {});
  };

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const getLastId = async () => {
    try {
      const response = await ApiManager.get(`/Receipt/GetLastId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      })
      return response.data;
    }
    catch(err) {
      return 0;
    }
  }

  const printPDF = async (
    id,
    name,
    email,
    address,
    phoneNumber,
    accountName,
    accountNumber
  ) => {
    const doc = new jsPDF();

    const pageHeight = doc.internal.pageSize.height; // Typically 297mm for A4
    const margin = 10;

    var lastId = await getLastId();

    // Adding company logo (placeholder for actual logo usage)
    // Note: You will need to add an image URL for a real logo
    // doc.addImage('path/to/logo.png', 'PNG', 15, 10, 30, 30);
    doc.setFillColor(230, 230, 230);
    doc.rect(14, 123, 187, 10, "F");
    doc.rect(8, 8, 194, 35, "F");
    doc.setFillColor(255, 255, 255);
    // Title and business name
    doc.setFontSize(24);
    doc.setFont("helvetica", "bolditalic");
    doc.text(receiptType, 23, 29);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    if (base64Logo) doc.addImage(base64Logo, "PNG", 150, 10, 16, 16);
    // doc.text("LOGO E BIZNESIT", 180, 20, null, null, "right");
    doc.setFont("helvetica", "normal");
    doc.text(business.name, 150, 30);
    doc.text(business.phoneNumber, 150, 35);
    doc.text(business.email, 150, 40);

    if (receiptType === "Faturë") {
      // Invoice metadata
      doc.setFontSize(12);
      doc.text("Numri i faturës: ", 14, 70); // Was 60
      doc.setFontSize(20);
      doc.text(lastId.toString(), 45, 70); // Was 60
    }

    doc.setFontSize(12);

    // Dates
    doc.setFont("helvetica", "bold");
    doc.text("Data: ", 14, 80); // Was 70
    let date = new Date();

    // Extract the day, month, and year
    let day = date.getDate();
    let monthsInAlbanian = [
      "Janar",
      "Shkurt",
      "Mars",
      "Prill",
      "Maj",
      "Qershor",
      "Korrik",
      "Gusht",
      "Shtator",
      "Tetor",
      "Nëntor",
      "Dhjetor",
    ];
    let month = monthsInAlbanian[date.getMonth()];
    // let month = date.toLocaleString("sq-AL", { month: "long" });
    let year = date.getFullYear();

    // Format the date manually
    let formattedDate = `${day} ${month}, ${year}`;
    doc.setFont("helvetica", "normal");
    doc.text(formattedDate, 60, 80, null, null, "right"); // Was 70

    // Bill To
    doc.text("Bill to:", 130, 70); // Was 60
    doc.setFont("helvetica", "bold");
    doc.text(name, 130, 80); // Was 70
    doc.setFont("helvetica", "normal");
    doc.text(address, 130, 90); // Was 80
    doc.text(phoneNumber, 130, 100); // Was 90

    // Table headers
    doc.setFont("helvetica", "bold");
    doc.text("Produktet", 14, 130); // Was 120
    doc.text("Sasia", 70, 130); // Was 120
    doc.text("Çmimi", 90, 130); // Was 120
    doc.text("Zbritja %", 120, 130); // Was 120
    doc.text("Çmimi me zbritje", 150, 130); // Was 120
    doc.text("Total", 190, 130); // Was 120

    // Sample table data
    const startY = 140; // Was 130
    const lineSpacing = 10;
    doc.setFont("helvetica", "normal");
    let y = startY;

    var totalSum = 0;
    var stockList = [];
    var rows = document.getElementsByClassName("rows");
    for (let i = 0; i < rows.length; i++) {
      var stockName = document.getElementById(`optionsName-${i}`).innerText;
      stockList.push(stockName);
      var quantity = document.getElementById(`quantity-${i}`).innerText;
      var buyingPrice = document.getElementById(`price-${i}`).innerText;
      var sellingPrice = document.getElementById(`sellingPrice-${i}`).value;
      var percentage = document.getElementById(`percentage-${i}`).value;
      var salePrice = document.getElementById(`salePrice-${i}`).innerText;
      var sum = document.getElementById(`sum-${i}`).innerText;

      totalSum += parseFloat(sum);

      percentage = 100 - percentage;

      doc.text(`${stockName}`, 14, y);
      doc.text(`${quantity}`, 70, y);
      doc.text(`${buyingPrice}`, 90, y);
      doc.text(`${percentage}%`, 120, y);
      doc.text(`${salePrice} €`, 150, y);
      doc.text(`${sum} €`, 188, y);
      doc.line(14, y + 2, 200, y + 2);

      y += lineSpacing;

      if (y >= pageHeight - margin) {
        // Check if the next line would go beyond the page
        doc.addPage(); // Add a new page
        y = 10; // Reset y coordinate to the top of the new page
      }
    }

    doc.text("Totali pa TVSH", 140, y + 12);
    doc.setFont("helvetica", "bold");
    doc.text(`${totalSum.toFixed(2)} €`, 200, y + 12, null, null, "right");
    doc.setFont("helvetica", "normal");
    doc.text("TVSH(18%)", 140, y + 22);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${(totalSum * 0.18).toFixed(2)} €`,
      200,
      y + 22,
      null,
      null,
      "right"
    );
    doc.line(140, y + 27, 200, y + 27);
    doc.setFont("helvetica", "normal");
    doc.text("Totali", 140, y + 32);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${(totalSum + totalSum * 0.18).toFixed(2)} €`,
      200,
      y + 32,
      null,
      null,
      "right"
    );

    // Footer
    doc.setFontSize(10);
    doc.text("Ose perdorni detajet e meposhtme per transfer:", 14, y + 60);
    doc.setFont("helvetica", "normal");
    doc.text(`Emri i llogarisë: ${business.accountName}`, 14, y + 70);
    doc.text(`Numri i llogarisë: ${business.accountNumber}`, 14, y + 80);
    doc.line(14, y + 90, 20, y + 90);
    doc.setFont("helvetica", "bold");
    doc.text(`Faleminderit qe zgjodhët ${business.name}!`, 14, y + 100);
    if (y >= pageHeight - margin) {
      // Check if the next line would go beyond the page
      doc.addPage(); // Add a new page
      y = 10; // Reset y coordinate to the top of the new page
    }
    doc.line(8, y + 110, 200, y + 110);

    if (y >= 190) {
      // Check if the next line would go beyond the page
      doc.addPage(); // Add a new page
      y = 10; // Reset y coordinate to the top of the new page
      doc.addImage(phone, "PNG", 28, y + 10, 8, 8);
      doc.setFont("helvetica", "normal");
      doc.text("123-456-7890", 38, y + 15);

      doc.setFont("helvetica", "bold");
      doc.addImage(mail, "PNG", 73, y + 10, 8, 8);
      doc.setFont("helvetica", "normal");
      doc.text("hello@reallygreatsite.com", 83, y + 15);

      doc.setFont("helvetica", "bold");
      doc.addImage(web, "PNG", 138, y + 10, 8, 8);
      doc.setFont("helvetica", "normal");
      doc.text("www.reallygreatsite.com", 148, y + 15);
    } else {
      doc.addImage(phone, "PNG", 28, y + 115, 8, 8);
      doc.setFont("helvetica", "normal");
      doc.text("123-456-7890", 38, y + 120);

      doc.setFont("helvetica", "bold");
      doc.addImage(mail, "PNG", 73, y + 115, 8, 8);
      doc.setFont("helvetica", "normal");
      doc.text("hello@reallygreatsite.com", 83, y + 120);

      doc.setFont("helvetica", "bold");
      doc.addImage(web, "PNG", 138, y + 115, 8, 8);
      doc.setFont("helvetica", "normal");
      doc.text("www.reallygreatsite.com", 148, y + 120);
    }

    if (y >= 190) {
      // Check if the next line would go beyond the page
      doc.addPage(); // Add a new page
      y = 10; // Reset y coordinate to the top of the new page
    }
    let findDuplicates = (arr) =>
      arr.filter((item, index) => arr.indexOf(item) !== index);
    if (receiptType === "Faturë") {
      ApiManager.post(
        `/${headerText === "Stoku FIFO" ? "Fifo" : "Fiscal"}Stock/EmptyStock`,
        stockList
      )
        .then((response) => {
          console.log(id);
          ApiManager.post(`/Receipt/PrintReceipt?jwtToken=${localStorage.getItem("token")}&clientId=${id}`)
          .then((resp) => {
            if(resp) {
              Swal.fire({
                title: "Printimi i faturës është bërë me sukses!",
                icon: "success",
                timer: 2500,
                confirmButtonText: "Në rregull",
              }).then(() => {
                doc.save("fatura.pdf");
                window.location.reload();
              });
            }
          })
          if (response.data === -1) {
            Swal.fire({
              title: "Nuk mund te zgjedhni përbërësit e njejtë!",
              icon: "error",
              timer: 2000,
              confirmButtonText: "Në rregull",
            });
          } else if (response.data === 0) {
            Swal.fire({
              title: "Ka ndodhur një gabim, ju lutem provoni përsëri",
              icon: "error",
              timer: 2500,
              confirmButtonText: "Në rregull",
            });
          }
        })
        .catch();
    } else {
      if (findDuplicates(stockList).length >= 1) {
        Swal.fire({
          title: "Nuk mund te zgjedhni përbërësit e njejtë!",
          icon: "error",
          timer: 2000,
          confirmButtonText: "Në rregull",
        });
      } else {
        doc.save("fatura.pdf");
      }
    }
  };

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700">
            <h3>{headerText}</h3>
          </div>
          <div className="flex items-center gap-4">
            <p>Zgjedh stokun</p>
            <SelectList
              selected={selectedOption}
              setSelected={(option) => {
                changeText(option);
              }}
              options={selectOptions}
            />
          </div>
        </header>
        <div className="mt-8 h-[30rem] overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full" variant="simple" color="gray-500" mb="24px">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-16 pb-[10px] text-start ">
                  <div className="text-xs font-medium tracking-wide text-gray-800">
                    STOKU.
                  </div>
                </th>
                {columnsData.map((column, index) => (
                  <th
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                    key={index}
                  >
                    <div className="text-xs font-medium tracking-wide text-gray-800">
                      {column.Header}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowArray.map((_, index) => (
                <StockItem
                  key={index}
                  index={index}
                  stocks={stocks}
                  stockType={headerText}
                  deleteRow={deleteRow}
                  stockOptions={stockOptions}
                />
              ))}
              <tr>
                <td>
                  {rowArray.length < stocks.length ? (
                    <div>
                      <button
                        onClick={() => addRow()}
                        className="linear mt-3 flex items-center justify-center gap-2 rounded-lg bg-blue-600 px-2 text-white transition duration-200 hover:cursor-pointer hover:bg-blue-700"
                      >
                        <span className="px-4 py-2 text-sm font-medium text-white">
                          Shto +
                        </span>
                      </button>
                    </div>
                  ) : null}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {rowArray.length !== 0 ? (
                    <button
                      onClick={() => openModal()}
                      className="linear mt-3 flex items-center justify-center gap-2 rounded-lg bg-green-600 px-2 text-white transition duration-200 hover:cursor-pointer hover:bg-green-700"
                    >
                      <span className="px-4 py-2 text-sm font-medium text-white">
                        Printo
                      </span>
                    </button>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <PrintReceipt
          onChangeType={changeReceiptType}
          print={printPDF}
          stockType={selectedOption}
          isOpen={modalOpen}
          onClose={closeModal}
          onSubmitSuccess={printPDF}
          onChangeClient={changeClient}
        />
      </Card>
    </>
  );
};

export default Receipt;
