export const optionsUnitOfMeasure = [
  { name: "Miligram (mg)" },
  { name: "Kilogram (kg)" },
  { name: "Mililiter (ml)" },
  { name: "Liter (l)" },
  { name: "Copë" },
];

export const optionsItemType = [
  { name: "Lëndë e gatshme" },
  { name: "Lëndë e parë" },
];
