import React, { useState, useEffect } from "react";
import Card from "components/card";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiManager } from "app/ApiManager";
import { MdOutlineEdit } from "react-icons/md";

const OwnerDetails = () => {
  const [employee, setEmployee] = useState(null);
  const location = useLocation();
  const navigation = useNavigate();
  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        let endpoint = `/User/EmployeeById/${location.state.id}`;
        if (endpoint) {
          const fetchBusiness = async () => {
            try {
              const response = await ApiManager.get(endpoint);
              setEmployee(response.data);
            } catch (error) {
              console.log(error);
            }
          };
          fetchBusiness();
        }
      }
    }
  }, [location.state]);

  const handleEdit = () => {
    navigation("/admin/owner-upsert", {
      state: {
        id: location?.state?.id,
      },
    });
  };

  return (
    <Card extra={"w-full h-full p-3 mt-5"}>
      {/* Header */}
      <div className="mt-2 mb-8 flex w-full justify-between">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          {employee?.firstName}
        </h4>

        <div className="flex items-center gap-8">
          <button
            onClick={handleEdit}
            className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
              bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-600 dark:text-white dark:hover:bg-brand-600 dark:active:bg-white/10"
          >
            Përditëso
            <MdOutlineEdit className="h-5 w-5 " />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Emri</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {employee?.firstName}
          </p>
        </div>
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Mbiemri</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {employee?.lastName}
          </p>
        </div>
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Email Adresa</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {employee?.email || "Nuk është dhënë"}
          </p>
        </div>
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Numri i telefonit</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {employee?.phoneNumber || "Nuk është dhënë"}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default OwnerDetails;
