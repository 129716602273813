import React, { useEffect, useState } from "react";
import BusinessTable from "./components/BusinessTable";
import { columnsData } from "./variables/ColumnsData";
import { ApiManager } from "app/ApiManager";

const BusinessList = () => {
  const [businessList, setBusinessList] = useState([]);
  useEffect(() => {
    ApiManager.get("/Business/GetAllBusinesses")
      .then((response) => {
        setBusinessList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
        <BusinessTable
          columnsData={columnsData}
          tableData={businessList}
          tableName="Lista e bizneseve"
        />
      </div>
    </>
  );
};

export default BusinessList;
