import React, { useEffect, useState } from "react";
import { ApiManager } from "app/ApiManager";
import TableItem from "./TableItem";
import { MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const TableList = () => {
  const [ambients, setAmbients] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    // Assuming '/Table/GetTablesByAmbient' is your endpoint
    ApiManager.get("/Table/GetTablesByAmbient", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        // Make sure the response data is what you expect
        setAmbients(response.data || []);
      })
      .catch((error) => {
        console.error("Error fetching tables:", error);
        setAmbients([]);
      });
  }, []);

  const handleCreateTable = () => {
    navigation("/admin/table-upsert");
  };

  return (
    <>
      <div className="mt-5 min-h-[40rem] rounded-xl bg-white ">
        <div className="my-4 p-4 md:flex md:justify-between ">
          <h4 className="text-xl font-bold text-navy-700">
            Paisjet e regjistruara dhe statusi i tyre aktual
          </h4>
          <button
            onClick={handleCreateTable}
            className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg bg-lightPrimary
              px-3 py-2 text-sm text-gray-800 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200"
          >
            <MdOutlineAdd className="h-5 w-5" /> Shto tavolinë
          </button>
        </div>
        <div className="px-2 flex flex-col gap-10 md:p-5">
          {ambients.map((ambient) => (
            <div key={ambient.ambientId} className="flex flex-col gap-2 pb-4">
              <h1 className="text-lg font-medium text-gray-800 ">
                {ambient.ambientName}
              </h1>
              <div className="grid gap-4 grid-cols-2 md:grid-cols-4 xl:grid-cols-6">
                {ambient.tables.map((table) => (
                  <TableItem data={table} key={table.tableId} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TableList;
