import { Spinner } from "flowbite-react";

const SpinnerButton = (props) => {
  const buttonClass = props.secondary
    ? "mx-auto mt-4 flex w-full justify-center rounded-xl bg-white py-[12px] text-base font-medium text-brand-500 border border-brand-500 transition duration-200 w-full md:w-[300px] hover:bg-gray-100 active:bg-gray-200"
    : "linear hover-bg-custom :bg-navy-600 mx-auto mt-4 flex w-full justify-center rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 w-full md:w-[300px] hover:bg-brand-600 active:bg-brand-700";

  return (
    <button
      type="submit"
      className={buttonClass}
      onClick={props.onClick}
      disabled={props.isWaiting}
    >
      {props.isWaiting ? <Spinner /> : props.title}
    </button>
  );
};

export default SpinnerButton;





