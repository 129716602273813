import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorLabel from "components/error/ErrorLabel";
import { useFormik } from "formik";
import { ApiManager } from "../../../app/ApiManager";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { optionsUnitOfMeasure } from "../stock/variables/SelectListOptions";
import SelectList from "../menu/components/SelectList";
import SpinnerButton from "components/buttons/SpinnerButton";

const UpsertExtraProduct = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { extraProductId } = location?.state || 0;
  const [extraProduct, setExtraProduct] = useState({});
  const [headerText, setHeaderText] = useState("");
  const [selectedUnitOfMeasure, setSelectedUnitOfMeasure] = useState(
    optionsUnitOfMeasure[0]
  );
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (extraProductId) {
      ApiManager.get(`/ExtraProduct/GetExtraProduct/${extraProductId}`)
        .then((response) => {
          setExtraProduct(response.data);
          setHeaderText("Përditësim");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setHeaderText("Regjistrim");
    }
  }, [extraProductId]);

  const formik = useFormik({
    initialValues: {
      name: "",
      unitQuantity: 0,
      priceOfExtra: "",
      extraProductId: extraProductId,
      unit: selectedUnitOfMeasure.name,
      calories: "",
      cookingTime: "",
    },
    //  validate,
    onSubmit: async (values) => {
      setIsWaiting(true);
      console.log(values);
      const jwtToken = localStorage.getItem("token");
      const endpoint =
        extraProductId !== 0 && extraProductId !== undefined
          ? "/ExtraProduct/EditExtraProduct"
          : "/ExtraProduct/AddExtraProduct";

      const method =
        extraProductId !== 0 && extraProductId !== undefined ? "put" : "post";

      try {
        if (values.unitQuantity <= 0) {
          throw new Error("Sasia duhet të jetë më e madhe se 0");
        }
        if (values.priceOfExtra < 0.05) {
          throw new Error("Çmimi duhet të jetë më i madh se 0.05");
        }
        if (values.calories < 0) {
          throw new Error("Kaloritë duhet të jenë më të mëdha se 0");
        }
        if (values.cookingTime < 0) {
          throw new Error("Koha e pregatitjes duhet të jetë më e madhe se 0");
        }

        const response = await ApiManager[method](endpoint, values, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            jwtToken: jwtToken,
          },
        });
        setIsWaiting(false);
        navigation("/admin/extraProduct-list");
      } catch (error) {
        setIsWaiting(false);
        console.log(error);
      }
      setIsWaiting(false);
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      name: extraProduct?.name || "",
      unitQuantity: extraProduct?.unitQuantity || "",
      priceOfExtra: extraProduct?.priceOfExtra || "",
      extraProductId: extraProductId,
      unit: selectedUnitOfMeasure.name || "",
      calories: extraProduct?.calories || "",
      cookingTime: extraProduct?.cookingTime || "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraProduct]);

  const { touched, errors, handleSubmit } = formik;
  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <h3 className="mb-4 text-xl font-bold text-navy-700">
          {headerText} i produktit shtesë
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Emërtimi*"
                id="name"
                name="name"
                type="text"
                {...formik.getFieldProps("name")}
                state={errors.name && touched.name ? "error" : null}
              />
              {errors.name && touched.name ? (
                <ErrorLabel error={errors.name} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Sasia*"
                id="unitQuantity"
                name="unitQuantity"
                type="number"
                min="0"
                {...formik.getFieldProps("unitQuantity")}
                state={
                  errors.unitQuantity && touched.unitQuantity ? "error" : null
                }
              />
              {errors.unitQuantity && touched.unitQuantity ? (
                <ErrorLabel error={errors.unitQuantity} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <SelectList
                label="Njësia matëse e sasisë*"
                selected={selectedUnitOfMeasure}
                setSelected={setSelectedUnitOfMeasure}
                options={optionsUnitOfMeasure}
              />
              {errors.unit && touched.unit ? (
                <ErrorLabel error={errors.unit} />
              ) : null}
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Kaloritë* (kCal)"
                id="calories"
                name="calories"
                type="number"
                {...formik.getFieldProps("calories")}
                state={errors.calories && touched.calories ? "error" : null}
              />
              {errors.calories && touched.calories ? (
                <ErrorLabel error={errors.calories} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Koha e përgatitjes (në minuta)"
                id="cookingTime"
                name="cookingTime"
                type="number"
                {...formik.getFieldProps("cookingTime")}
                state={
                  errors.cookingTime && touched.cookingTime ? "error" : null
                }
              />
              {errors.cookingTime && touched.cookingTime ? (
                <ErrorLabel error={errors.cookingTime} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Çmimi* (EUR)"
                id="priceOfExtra"
                name="priceOfExtra"
                type="number"
                {...formik.getFieldProps("priceOfExtra")}
                state={
                  errors.priceOfExtra && touched.priceOfExtra ? "error" : null
                }
              />
              {errors.priceOfExtra && touched.priceOfExtra ? (
                <ErrorLabel error={errors.priceOfExtra} />
              ) : null}
            </div>
          </div>
                <div className="flex ">
                <SpinnerButton type="button" title={"Ruaj të dhënat"} isWaiting={isWaiting} isTable={true}/>
                  <SpinnerButton type="button" title={"Anulo të dhënat"} isWaiting={isWaiting}/>
                </div>

        </form>
      </Card>
    </>
  );
};

export default UpsertExtraProduct;
