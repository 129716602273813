import React, { useState, useEffect } from "react";
import Card from "components/card";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiManager } from "app/ApiManager";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import Radio from "components/radio";

const ClientDetails = () => {
  // const navigation = useNavigate();
  const location = useLocation();
  const navigation = useNavigate();
  const client = location.state;

  const handleEdit = () => {
    navigation("/admin/register-client", {
      state: {
        id: client.id
      }
    })
  }

  const handleDelete = async () =>{
    await ApiManager.post(`/client/archive`,{params:{
      clientId:client.id
    }});
    navigation("/admin/client")
    
  }

  return (
    <>
      <Card extra={"w-full h-full p-3 mt-5"}>
        {/* Header */}
        <div className="mt-2 mb-8 w-full md:flex justify-between">
          <h4 className="px-2 text-xl pb-8 font-bold text-navy-700 md:pb-0">
            {client.name}
          </h4>
          <div className="flex flex-col md:flex-row md:items-center gap-8">
            {/* {canEdit ? ( */}

            <button className="bg-red-500 text-white p-2  rounded-md flex items-center
             hover:bg-red-600 active:bg-red-500 " onClick={()=> handleDelete()}>
              Delete
              <MdDelete className="h-5 w-5 "/>
            </button>

              <button
                onClick={handleEdit}
                className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
            bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
            hover:bg-gray-100 active:bg-gray-200 "
              >
                Përditëso
                <MdOutlineEdit className="h-5 w-5 " />
              </button>
            {/* ) : null} */}
          </div>
        </div>
        <div className="grid grid-cols-1  gap-2 md:grid-cols-2 md:gap-4 md:px-2">
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 overflow-x-auto">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Klienti</p>
            <p className="text-base font-medium text-navy-700  ">
              {client.name}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 overflow-x-auto ">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Adresa</p>
            <p className="text-base font-medium text-navy-700 ">
              {client.address}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  overflow-x-auto">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Numri Telefonit</p>
            <p className="text-base font-medium text-navy-700 ">
              {client.phoneNumber || "Nuk është dhënë"}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  overflow-x-auto">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Email</p>
            <p className="text-base font-medium text-navy-700 ">
              {client.email || "Nuk është dhënë"}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  overflow-x-auto">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Emri i llogarisë</p>
            <p className="text-base font-medium text-navy-700 ">
              {client.accountName || "Nuk është dhënë"}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500  overflow-x-auto">
            <p className="text-sm text-gray-600 sm:block sm:w-max">Numri i llogarisë</p>
            <p className="text-base font-medium text-navy-700 ">
              {client.accountNumber || "Nuk është dhënë"}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ClientDetails;
