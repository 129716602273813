import React, { useEffect, useState } from "react";
import { columnsData } from "./variables/columnsData";
import { ApiManager } from "app/ApiManager";
import OfferTable from "./components/OfferTable";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react";
import OfferNavbar from "./components/OfferNavbar";

export default observer(function OfferList() {
  const [offersList, setOffersList] = useState([]);
  const { upsertProductStore } = useStore();

  useEffect(() => {
    var jwtToken = localStorage.getItem("token");
    ApiManager.get("/Offer/ListOfOffers", {
      params: {
        jwtToken: jwtToken,
        archived: upsertProductStore.showArchivedOffers,
      },
    })
      .then((response) => {
        setOffersList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [upsertProductStore.showArchivedOffers]);
  return (
    <>
      <OfferNavbar />
      <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
        <OfferTable
          columnsData={columnsData}
          tableData={offersList}
          tableName="Lista e ofertave"
        />
      </div>
    </>
  );
});
