export const columnsData = [
  {
    Header: "EMËRTIMI",
    accessor: "name",
  },
  {
    Header: "SASIA",
    accessor: "unitQuantity",
  },
  {
    Header: "NJËSIA MATËSE",
    accessor: "unit",
  },
  {
    Header: "ÇMIMI",
    accessor: "priceOfExtra",
  },
];
