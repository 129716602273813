/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import { adminRoutes } from "routes.js";
import { ownerRoutes } from "routes.js";
import { printerRoutes } from "routes.js";
import { ReturnRole } from "utils/CheckRole";

const Sidebar = ({ open, onClose }) => {

  const role = ReturnRole();

  return (
    // <div
    //   className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all  md:!z-50 lg:!z-50 xl:!z-0 ${
    //     open ? "translate-x-0" : "-translate-x-96"
    //   }`}
    // >
    <div
  className={`fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all duration-175 linear ${
    open ? "translate-x-0" : "-translate-x-96"
  }`}
>
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700">
          RECTEC
        </div>
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 " />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={role === "SuperAdmin" ? adminRoutes : role === "Drejtor" ? ownerRoutes : role === "Faturues" ? printerRoutes : routes} />
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">{/* <SidebarCard /> */}</div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
