import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ApiManager } from "app/ApiManager";
import jwtDecode from "jwt-decode";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const getHoursOfDay = () => {
  const hoursArray = [];
  for (let hour = 0; hour < 24; hour++) {
    const formattedHour = (hour < 10 ? "0" : "") + hour + ":00"; // Format hour to "00:00" format
    hoursArray.push(formattedHour);
  }
  return hoursArray;
};

const labels = getHoursOfDay();

const DailyBar = ({ selectedBusiness }) => {
  const [sales, setSales] = useState([]);
  useEffect(() => {
    const AllSalesByHours = async () => {
      const response = await ApiManager.get(`/Sales/HourlyByUserId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setSales(response.data);
    };
    const CertainSalesByHours = async () => {
      const response = await ApiManager.get(
        `Sales/HourlyByBusinessId/${selectedBusiness}`
      );
      setSales(response.data);
    };
    const SalesByDHours = async () => {
      const response = await ApiManager.get("/Sales/SalesByHours", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setSales(response.data);
    };
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if (role === "Drejtor") {
      var selectedBusiness = localStorage.getItem("selectedBusiness");
      if (selectedBusiness === "0") {
        AllSalesByHours();
      } else {
        CertainSalesByHours();
      }
    } else {
      SalesByDHours();
    }
  }, [selectedBusiness]);

  const data = {
    labels,
    datasets: [
      {
        label: "Shitjet",
        data: labels.map((_, i) => sales[i]),
        backgroundColor: "rgba(66, 42, 251, 0.8)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default DailyBar;
