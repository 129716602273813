import React, { useState, useEffect } from "react";
import FinanceList from "./components/FinanceList";

const WeeklyFinance = () => {
  return (
    <>
      <FinanceList tableName="Financat ditore" />
    </>
  );
};

export default WeeklyFinance;
