import React, { useMemo, useEffect, useState } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { supplierColumns } from "../variables/supplierColumns";
import SupplierCard from "./SupplierCard";
import { supplierdata } from "../variables/supplierData";
import { ApiManager } from "app/ApiManager";

const Supplier = () => {
  const columns = useMemo(() => supplierColumns, [supplierColumns]);
  const data = useMemo(() => supplierdata, [supplierdata]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    navigate("/admin/supplier-details", { state: rowData });
  };

  useEffect(() => {
    const fetchSuppliers = async () => {
      const response = await ApiManager.get(`/Supplier/GetByBusinessId`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setSuppliers(response.data);
    };
    fetchSuppliers();
  }, []);

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700">{data.name}</div>
          <SupplierCard />
        </header>

        <div className="mt-8 overflow-x-scroll ">
          <table className="w-full" variant="simple" color="gray-500" mb="24px">
            <thead>
              <tr>
                <th className="border-b border-gray-200 py-4 pr-16 pb-[10px] text-start">
                  <div className="text-xs font-medium tracking-wide text-gray-800">
                    NR.
                  </div>
                </th>
                {columns.map((column, index) => (
                  <th className="border-b border-gray-200 py-4 pr-16 pb-[10px] text-start">
                    <div className="text-xs block w-max font-medium tracking-wide text-gray-800">
                      {column.Header}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {suppliers && suppliers.length >= 1 ? (
                suppliers.map((supplier, index) => (
                  <tr
                    key={index}
                    onClick={() => handleRowClick(supplier)}
                    className="cursor-pointer hover:bg-blue-200"
                  >
                    <td
                      className="border-b  border-gray-200 py-4 pr-16 pb-[10px] text-start "
                      key={index}
                    >
                      <div className="text-xs block w-max font-medium font-bold tracking-wide text-gray-800">
                        {++index}
                      </div>
                    </td>

                    <td
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                      key={index}
                    >
                      <div className="text-xs block font-medium font-bold tracking-wide text-gray-800">
                        {supplier.name}
                      </div>
                    </td>

                    <td
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                      key={index}
                    >
                      <div className="text-xs font-medium font-bold tracking-wide text-gray-800">
                        {supplier.address}
                      </div>
                    </td>

                    <td
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                      key={index}
                    >
                      <div className="text-xs font-medium font-bold tracking-wide text-gray-800">
                        {supplier.phoneNumber}
                      </div>
                    </td>

                    <td
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                      key={index}
                    >
                      <div className="text-xs font-medium font-bold tracking-wide text-gray-800">
                        {supplier.email}
                      </div>
                    </td>
                    <td
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                      key={index}
                    >
                      <div className="text-xs font-medium font-bold tracking-wide text-gray-800">
                        {supplier.personName}
                      </div>
                    </td>

                    <td
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                      key={index}
                    >
                      <div className="text-xs font-medium font-bold tracking-wide text-gray-800">
                        {supplier.accountName}
                      </div>
                    </td>

                    <td
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                      key={index}
                    >
                      <div className="text-xs font-medium font-bold tracking-wide text-gray-800">
                        {supplier.accountNumber}
                      </div>
                    </td>
                    <td
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start"
                      key={index}
                    >
                      <div className="text-xs font-medium font-bold tracking-wide text-gray-800">
                        {supplier.nub}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center font-bold">
                    Nuk ka të dhëna
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <Pagination
          currentPage={pageIndex + 1}
          totalPages={pageCount}
          handlePrevious={() => previousPage()}
          handleNext={() => nextPage()}
          handlePageClick={(page) => gotoPage(page - 1)}
          canPrevious={canPreviousPage}
          canNext={canNextPage}
        /> */}
      </Card>
    </>
  );
};

export default Supplier;
