import { useState } from 'react';
import Card from "components/card";
import { MdOutlineCalendarToday } from "react-icons/md";
import YearlyBar from "components/charts/bars/YearlyBar";
import MonthlyBar from "components/charts/bars/MonthlyBar";
import DailyBar from "components/charts/bars/DailyBar";

const Revenue = ({selectedBusiness}) => {
  const [selectedValue, setSelectedValue] = useState("Yearly")

  const ChangeValue = () => {
    if (selectedValue === "Yearly") {
      setSelectedValue("Monthly")
    } else if (selectedValue === "Monthly") {
      setSelectedValue("Daily")
    }  else {
      setSelectedValue("Yearly")
    }
  }
  return (
    <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
      <div className="flex justify-between">
        <button onClick={ChangeValue} className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-800 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
          <MdOutlineCalendarToday />
          <span className="text-sm font-medium text-gray-800">
            {selectedValue === "Yearly" ? 
            "Të ardhurat vjetore" :
            selectedValue === "Monthly" ?
            "Të ardhurat mujore" :
            "Të ardhurat ditore"
            }
          </span>
        </button>
      </div>
      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          {
            selectedValue === "Yearly" ? <YearlyBar selectedBusiness={selectedBusiness}/> :
            selectedValue === "Monthly" ? <MonthlyBar selectedBusiness={selectedBusiness}/> :
            <DailyBar selectedBusiness={selectedBusiness}/>
          }
        </div>
      </div>
    </Card>
  );
};

export default Revenue;
