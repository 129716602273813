import React, { useContext, useEffect, useState } from "react";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react";
import Item from "./Item";
import handClickIcon from "../../../assets/img/layout/hand-click-icon.png";
import WaiterOrderAssist from "./WaiterOrderAssist";
import ProcessPaymentBtn from "./ProcessPaymentBtn";
import { SignalRConnectionContext } from "app/SignalRConnectionProvider";
import { ApiManager } from "app/ApiManager";

const OrdersTable = observer(() => {
  const { ordersStore } = useStore();

  const { connection, connectionStatus } = useContext(SignalRConnectionContext);

  const orders = ordersStore.currentOrderItemsStatus;
  const table = ordersStore.currentSelectedTable;
  const [updatedItems, setUpdatedItems] = useState({});
  var totalPrice = 0;
  orders.forEach((element) => {
    element.items?.forEach((item) => {
      totalPrice += item.price * item.quantity;
    });
  });
  useEffect(() => {
    if (connection && connectionStatus === "connected") {
      try {
        connection.on('ReceiveOrderStatus', (newStatus, id) => {
          setUpdatedItems(prev => ({ ...prev, [id]: newStatus }));
          if(newStatus === "Përfunduar") {
            ApiManager.get("/Order/PlayKitchen")
            .catch(() => {});
          }
          ordersStore.refreshUI("table"); 
        });
      } catch (error) {
        console.error("Error handling real-time order:", error);
      }

      return () => {
        connection.off("ReceiveOrderStatus");
      };
    }
  }, [connection, connectionStatus, ordersStore]);

  return (
    <>
      <div className="h-[37rem] rounded-xl bg-white">
        {orders?.length > 0 ? (
          <div className="flex h-full flex-col justify-between">
            <table className="table w-full">
              <thead>
                <tr className="text-center text-base text-gray-800">
                  <th className="hidden md:table-cell">Nr.</th>
                  <th className="table-cell">Porosia</th>
                  <th className="table-cell">Sasia</th>
                  <th className="table-cell">Pagesa</th>
                  <th className="table-cell">Statusi</th>
                </tr>
              </thead>
              <tbody>
                {orders?.map(
                  (order, index1) =>
                    order.items &&
                    order.items.map((item) => (
                      <Item
                        key={`order-item-${order.orderId}-${item.id}`}
                        item={item}
                        index={index1}
                        statusUpdate={updatedItems[item.id]}
                      />
                    ))
                )}
              </tbody>
            </table>
            <ProcessPaymentBtn
              totalPrice={totalPrice}
              table={table}
              orderIds={orders.map((o) => o.orderId)}
            />
          </div>
        ) : (
          <div className="flex h-full flex-col items-center justify-center gap-y-5 p-5">
            {table && table.name ? (
              <WaiterOrderAssist table={table} />
            ) : (
              <>
                <p className="text-md text-center font-semibold text-blue-800">
                  Klikoni mbi një tavolinë me statusin "E zënë" për të parë
                  detajet e porosisë. Ndërsa, nëse klikoni mbi një tavolinë me
                  statusin "E lirë," ju keni mundësinë të asistoni klientët duke
                  bërë porosinë për ta.
                </p>
                <img src={handClickIcon} className="w-24" alt="icon" />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
});

export default OrdersTable;
