import React from "react";
import { observer } from "mobx-react";
import { useStore } from "app/stores/store";
import { MdClear, MdOutlineAdd, MdOutlineRemove } from "react-icons/md";

const CartItem = observer(({ cartItem, tableId, setForceRender, forceRender, changeChanged }) => {
  const { cartStore } = useStore();
  const { id, productType } = cartItem;

  const handleIncrement = () => {
    cartStore.incrementQuantity(tableId, id, productType);
    setForceRender(!forceRender);
  };

  const handleDecrement = () => {
    cartStore.decrementQuantity(tableId, id, productType);
    setForceRender(!forceRender);
  };

  const handleDelete = () => {
    cartStore.deleteCartItem(tableId, productType, id);
    changeChanged(prev => prev + 1);
  };

  return (
    <div className="my-2 flex items-center justify-between rounded-md border border-gray-300 p-1 md:flex-row md:p-2">
      <div className="mb-2 flex items-center md:mb-0 md:mr-4">
        <p className="md:text-md text-sm font-medium text-gray-800">
          {cartItem.name}
        </p>
      </div>
      <div className="flex items-center gap-3 lg:gap-5">
        <div className="flex items-center gap-3">
          <button
            type="button"
            onClick={handleDecrement}
            className="flex items-center justify-center rounded-md border border-[#e9ad3f] text-xl text-[#e9ad3f] focus:outline-none md:h-10 md:w-10 md:text-4xl"
          >
            <span className="text-yellow-500">
              <MdOutlineRemove className="md:h-6 md:w-6" />
            </span>
          </button>
          <span className="text-sm md:text-lg">{cartItem.quantity}</span>
          <button
            type="button"
            onClick={handleIncrement}
            className="flex items-center justify-center rounded-md border border-[#e9ad3f] text-xl text-[#e9ad3f] focus:outline-none md:h-10 md:w-10 md:text-4xl"
          >
            <span className="text-yellow-500">
              <MdOutlineAdd className="md:h-6 md:w-6" />
            </span>
          </button>
        </div>
        <button
          type="button"
          onClick={handleDelete}
          className="flex items-center justify-center focus:outline-none"
        >
          <span className="text-red-600">
            <MdClear className="md:h-6 md:w-6" />
          </span>
        </button>
      </div>
    </div>
  );
});

export default CartItem;
