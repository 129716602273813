import { ApiManager } from "app/ApiManager";
import React, { useEffect, useState } from "react";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const Navbar = () => {
  const navigation = useNavigate();
  const [fullName, setFullName] = useState("");
  
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigation("/auth/sign-in");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const role = decodedToken.Role;
      if (role === "Menaxher") {
        navigation("/admin/panel");
      } else if (role === "Kamarier") {
        navigation("/waiter/panel");
      } else if (role === "Kuzhinier") {
        navigation("/chef/panel");
      }
    } else {
      navigation("/auth/sign-in");
    }
    ApiManager.get("/User/GetCurrentUserFullName", {
      params: {
        jwtToken: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setFullName(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigation]);

  return (
    <>
      <div
        className="bg-lighPrimary 
        mt-2 flex items-center justify-between rounded-2xl bg-white p-4 text-xl"
      >
        <div>
          <h3 className="text-md font-medium text-gray-800">{fullName}</h3>
        </div>
        <div className="flex justify-between gap-x-3">
          <button className="btn btn-outline btn-error" onClick={handleLogout}>
            <MdLogout className="h-6 w-6 " />
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
