import React, { useState, useEffect } from "react";
import OnlineOrderItem from "./OnlineOrderItem";
import { ApiManager } from "app/ApiManager";

const OnlineOrder = ({ statusUpdate, order, accordionSwitch, payOrder }) => {

  const [finishedOrders, setFinishedOrders] = useState(0);

  useEffect(() => {
    const fetchFinishedOrders = async () => {
      const response = await ApiManager.get(`/OnlineOrder/GetFinishedCount`, {
        params: {
          onlineOrderId: order.onlineOrderId,
        },
      })
      setFinishedOrders(response.data);
    }
    fetchFinishedOrders();
  }, [order]);

  return (
    <div key={order.onlineOrderId} className="accordion bg-base-200">
      <input
        type="checkbox"
        id={`accordion-${order.onlineOrderId}`}
        className="hidden"
        onClick={() => accordionSwitch(order.onlineOrderId)}
      />
      <label
        htmlFor={`accordion-${order.onlineOrderId}`}
        className="accordion-title flex cursor-pointer items-center justify-between rounded-t-lg bg-gray-100 p-4"
      >
        <div className="font-medium text-gray-800 text-base">
          Porosia: {order.onlineOrderId}
        </div>
        <div>
          {finishedOrders}/{order.items.length}
        </div>
      </label>
      <div
        id={`switch-${order.onlineOrderId}`}
        className="accordion-content overflow-y-auto bg-gray-50 p-4"
      >
        <ul className="list-none">
          {order.items.map((orderItem, j) => (
            <OnlineOrderItem key={orderItem.id} index={j} statusUpdate={statusUpdate} item={orderItem}/>
          ))}
        </ul>
        <div className="mt-6 flex items-center justify-between">
          <p className="text-base font-semibold text-gray-800">
            Totali: <span className="font-semibold">{order.totalPrice} €</span>{" "}
          </p>
          <div className="flex gap-5">
          <button
            onClick={(e) => payOrder(order.onlineOrderId, false)}
            className="rounded bg-blue-500 py-1 px-4 text-base font-medium text-white hover:bg-blue-700"
          >
            Paguaj
          </button>
          <button
            onClick={(e) => payOrder(order.onlineOrderId, true)}
            className="rounded bg-blue-500 py-1 px-4 text-base font-medium text-white hover:bg-blue-700"
          >
            Fifo
          </button>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default OnlineOrder;
