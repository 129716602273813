import React, { useState, useEffect } from 'react';
import InputField from 'components/fields/InputField';
import Card from 'components/card';
import { useFormik } from "formik";
import SpinnerButton from 'components/buttons/SpinnerButton';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from "react-router-dom";
import { ApiManager } from 'app/ApiManager';
import { validate } from '../variables/ValidateForm';
import ErrorLabel from 'components/error/ErrorLabel';

function RegisterClient() {

  const navigation = useNavigate();
  const location = useLocation();
  const [clientId, setClientId] = useState(0);
  const [client, setClient] = useState({});
  const [headerText, setHeaderText] = useState("Regjistrim");
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    console.log(location.state, "state");
    if(location.state) {
      if(location.state.id) {
        const fetchClient = async () => {
          const response = await ApiManager.get(`/Client/GetById`, {
            params: {
              clientId: location.state.id,
            },
          });
          setClientId(location.state.id);
          setClient(response.data);
        }
        fetchClient();
        setHeaderText("Përditësim")
      }
    }
  }, [location.state])

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      address: "",
      phoneNumber: "",
      email: "",
      accountName: "",
      accountNumber: "",
      businessId: "",
    },
    validate: (values) => validate(values),
    onSubmit: (values) => {
      setIsWaiting(true);

      let endpoint;
      if (clientId !== 0) {
        endpoint = "/Client/Edit";
      } else {
        endpoint = "/Client/Create";
      };

      ApiManager.post(endpoint, values, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          setIsWaiting(false);
          if (response.data.isCreated >= 1) {
            Swal.fire({
              icon: "success",
              title: `Klienti është ${headerText
                .slice(0, -2)
                .toLowerCase()}uar me sukses`,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              navigation("/admin/client-page");
            });
          } else if (response.data.isCreated === 0) {
            Swal.fire({
              icon: "error",
              title: `Ndodhi nje gabim, provoni perseri me vone`,
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data.isCreated === -1) {
            Swal.fire({
              icon: "error",
              title: `Ekziston nje klient me kete email!`,
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data.isCreated === -2) {
            Swal.fire({
              icon: "error",
              title: `Ekziston nje klient me kete numer telefoni`,
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data.isCreated === -3) {
            Swal.fire({
              icon: "error",
              title: `Ekziston nje klient me kete numer të llogarisë`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          setIsWaiting(false);
        });
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      id: client?.id || "",
      name: client?.name || "",
      address: client?.address || "",
      phoneNumber: client?.phoneNumber || "",
      email: client?.email || "",
      accountName: client?.accountName || "",
      accountNumber: client?.accountNumber || "",
      businessId: client?.businessId || "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const { touched, errors, handleSubmit } = formik;

    return(
        <>
        <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <h3 className=" my-2 mb-4 text-xl font-bold text-navy-700">
          {headerText} i klientit
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="md:flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Emri i klientit"
                id="name"
                name="name"
                type="text"
                {...formik.getFieldProps("name")}
                state={errors.name && touched.name ? "error" : null}
              />
              {errors.name && touched.name ? (
                <ErrorLabel error={errors.name} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Adresa"
                type="text"
                id="address"
                name="address"
                {...formik.getFieldProps("address")}
                state={errors.address && touched.address ? "error" : null}
              />
              {errors.address && touched.address ? (
                <ErrorLabel error={errors.address} />
              ) : null}
            </div>
          </div>
          
          <div className="md:flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Numri Telefonit"
                type="number"
                id="phoneNumber"
                name="phoneNumber"
                {...formik.getFieldProps("phoneNumber")}
                state={errors.phoneNumber && touched.phoneNumber ? "error" : null}
              />
              {errors.phoneNumber && touched.phoneNumber ? (
                <ErrorLabel error={errors.phoneNumber} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Email"
                type="email"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
                state={errors.email && touched.email ? "error" : null}
              />
              {errors.email && touched.email ? (
                <ErrorLabel error={errors.email} />
              ) : null}
            </div>
          </div>
          <div className="md:flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Emri i llogarisë bankare"
                id="accountName"
                name="accountName"
                type="text"
                {...formik.getFieldProps("accountName")}
                state={errors.accountName && touched.accountName ? "error" : null}
              />
              {errors.accountName && touched.accountName ? (
                <ErrorLabel error={errors.accountName} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Numri i llogarisë bankare"
                type="text"
                id="accountNumber"
                name="accountNumber"
                {...formik.getFieldProps("accountNumber")}
                state={errors.accountNumber && touched.accountNumber ? "error" : null}
              />
              {errors.accountNumber && touched.accountNumber ? (
                <ErrorLabel error={errors.accountNumber} />
              ) : null}
            </div>
          </div>

          <div className="md:flex justify-around">
          <SpinnerButton title={"Ruaj të dhënat"} isWaiting={isWaiting}/>
          </div>
        </form>
      </Card>
        </>
    )
}

export default RegisterClient