import { useState, useEffect } from "react";
import { ApiManager } from "app/ApiManager";
import jwtDecode from "jwt-decode";

const DrinkCount = ({ selectedBusiness }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const GetAllDrinks = async () => {
      try {
        const response = await ApiManager.get(`/Drink/DrinkByUserId`, {
          params: {
            jwtToken: localStorage.getItem("token"),
          },
        });
        setTotal(response.data);
      } catch (error) {
        console.log(error, "err1");
      }
    };
    const GetCertainDrinks = async () => {
      const response = await ApiManager.get(
        `/Drink/DrinkByBusinessId/${selectedBusiness}`
      );
      setTotal(response.data);
    };
    const GetDrinks = async () => {
      const response = await ApiManager.get(`/Drink/TotalDrinks`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setTotal(response.data);
    };
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if (role === "Drejtor") {
      var selectedBusiness = localStorage.getItem("selectedBusiness");
      if (selectedBusiness === "0") {
        GetAllDrinks();
      } else {
        GetCertainDrinks();
      }
    } else {
      GetDrinks();
    }
  }, [selectedBusiness]);
  return total;
};

export default DrinkCount;
