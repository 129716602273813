export const columnsFiskalData = [
  {
    Header: "EMËRTIMI",
    accessor: "name",
  },
  {
    Header: "SASIA",
    accessor: "quantity",
  },
  {
    Header: "NJËSIA MATËSE",
    accessor: "measure",
  },
  {
    Header: "CMIMI",
    accessor: "price",
  },
  {
    Header: "PËRQINDDJA",
    accessor: "percentage",
  }
];
