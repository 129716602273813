import React, { useState, useEffect } from "react";
import Card from "components/card";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { ApiManager } from "app/ApiManager";

const FinanceList = (props) => {
  const { tableName } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const yearlyOrders = async () => {
      const response = await ApiManager.get("/Order/YearlyOrders", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setOrders(response.data);
    };
    const monthlyOrders = async () => {
      const response = await ApiManager.get("/Order/MonthlyOrders", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setOrders(response.data);
    };
    const dailyOrders = async () => {
      const response = await ApiManager.get("/Order/DailyOrders", {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setOrders(response.data);
    };
    if (tableName === "Financat vjetore") yearlyOrders();
    else if (tableName === "Financat mujore") monthlyOrders();
    else dailyOrders();
  }, [tableName]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return [`${year}/${month}/${day}`,   `${hours}:${minutes}`];
  };

  return (
    <>
      <Card
        style={{ minHeight: "22rem" }}
        extra={"w-full sm:overflow-auto p-5"}
      >
        <header className="relative md:flex items-center justify-between">
          <div className="text-lg font-semibold text-gray-800 ">
            {tableName}
          </div>
          <div className="flex space-x-2">
            <div className="relative">
              <FaCalendarAlt
                className="absolute top-0 right-0  m-0 mt-2 cursor-pointer text-gray-700 md:m-2"
                onClick={toggleDatePicker}
              />
              {isDatePickerOpen && (
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="MMMM d, yyyy"
                  className="rounded-md border-2 border-gray-200  py-1 text-sm outline-gray-200 "
                  placeholderText="Zgjedh datën"
                />
              )}
            </div>
            <input
              type="text"
              placeholder="Kërko..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="rounded-md border-2 border-gray-200  py-1 text-sm outline-gray-200 ml-10px md:px-2"
            />
          </div>
        </header>

        <div className="mt-16 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full" variant="simple" color="gray-500" mb="24px">
            <thead>
              <tr>
                <th
                  className="border-b border-gray-200 pr-16 pb-[10px] text-start 
                 "
                >
                  <div className="text-xs font-medium tracking-wide text-gray-800 block w-max uppercase">
                    NR.
                  </div>
                </th>
                <th
                  className="border-b border-gray-200 pr-16 pb-[10px] text-start uppercase
                 "
                >
                  <div className="text-xs font-medium tracking-wide text-gray-800 block w-max">
                    Kamarieri
                  </div>
                </th>
                <th
                  className="border-b border-gray-200 pr-16 pb-[10px] text-start uppercase
                 "
                >
                  <div className="text-xs font-medium tracking-wide text-gray-800 block w-max">
                    Tavolina
                  </div>
                </th>
                <th
                  className="border-b border-gray-200 pr-16 pb-[10px] text-start uppercase
                 "
                >
                  <div className="text-xs font-medium tracking-wide text-gray-800 block w-max">
                    Shuma e paguar{" "}
                  </div>
                </th>
                <th
                  className="border-b border-gray-200 pr-16 pb-[10px] text-start uppercase
                 "
                >
                  <div className="text-xs font-medium tracking-wide text-gray-800 block w-max">
                    Shuma totale
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {orders?.length !== 0 ? (
                orders?.map((order, index) => (
                  <tr className="cursor-pointer hover:bg-blue-50 ">
                    <td>
                      <div className="flex items-center gap-2">
                        <p className="text-sm font-medium text-navy-700 block w-max">
                          {index + 1}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        <p className="text-sm font-medium text-navy-700 block w-max">
                          {order.paidBy.fullName}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        <p className="text-sm font-medium text-navy-700 block w-max">
                          {order.tableName}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                      <p className="text-sm font-medium text-navy-700 block w-max">
                        {formatDate(order.paidAt)[0]}
                        </p>
                        <p className="text-sm font-medium text-navy-700 block w-max">
                          {formatDate(order.paidAt)[1]}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        <p className="text-sm font-medium text-navy-700 block w-max">
                          {order.totalPrice} €
                        </p>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="w-full pt-4 text-center text-sm font-bold text-navy-700 dark:text-white"
                    colSpan="5"
                  >
                    Nuk ka të dhëna të paraqitura!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
};

export default FinanceList;
