export const validate = (values, file, switchChecked) => {
    const requiredErrorMessage = "Kjo fushë është e kërkuar!";
    const errors = {};
    if (!values.name) {
        errors.name = requiredErrorMessage;
      } else if (values.name.length < 3 || values.name.length > 20) {
        errors.name = "Numri i karaktereve duhet të jetë mes 3 dhe 20!";
      } else if (!/^[a-zA-Z\s]+$/.test(values.name)) {
        errors.name = "Vlera e shënuar duhet të përmbajë vetëm shkronja!";
      }
    if(!values.address) {
        errors.address = requiredErrorMessage;
    }
    if(!values.phoneNumber) {
        errors.phoneNumber = requiredErrorMessage;
    }
    else if(!/^\d{9}$/.test(values.phoneNumber)){
        errors.phoneNumber = "Vlera e shënuar duhet të përmbajë 9 numra!";
    }
    if(!values.email){
        errors.email = requiredErrorMessage;
    }
    else if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.email)) {
        errors.email = "Ju lutemi vendosni një email adresë të vlefshme!";
    }
    if(!values.accountName) {
        errors.accountName = requiredErrorMessage;
    }
    if(!values.accountNumber) {
        errors.accountNumber = requiredErrorMessage;
    }
    else if(!/^\d{16}$/.test(values.accountNumber)) {
        errors.accountNumber = "Vlera e shënuar duhet të përmbajë vetëm 16 numra!";
    }
    return errors;
}