import { useState, useEffect } from "react";
import { ApiManager } from "app/ApiManager";
import jwtDecode from "jwt-decode";

const FoodCount = ({ selectedBusiness }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const GetAllFoods = async () => {
      try {
        const response = await ApiManager.get(`/Food/FoodByUserId`, {
          params: {
            jwtToken: localStorage.getItem("token"),
          },
        });
        setTotal(response.data);
      } catch (error) {
        console.log(error, "err1");
      }
    };
    const GetCertainFoods = async () => {
      const response = await ApiManager.get(
        `/Food/FoodByBusinessId/${selectedBusiness}`
      );
      setTotal(response.data);
    };
    const GetFoods = async () => {
      const response = await ApiManager.get(`/Food/TotalFoods`, {
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      });
      setTotal(response.data);
    };
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const role = decodedToken.Role;
    if (role === "Drejtor") {
      var selectedBusiness = localStorage.getItem("selectedBusiness");
      if (selectedBusiness === "0") {
        GetAllFoods();
      } else {
        GetCertainFoods();
      }
    } else {
      GetFoods();
    }
  }, [selectedBusiness]);
  return total;
};

export default FoodCount;
