import React, { useState, useEffect } from "react";
import AddProductsNavbar from "./components/AddProductsNavbar";
import ProductTable from "./components/ProductTable";
import { ApiManager } from "app/ApiManager";
import { columnsData } from "./variables/columnsData";
import { useStore } from "app/stores/store";
import { autorun } from "mobx";
import { useLocation } from "react-router-dom";

const ProductList = () => {
  const [desserts, setDesserts] = useState([]);
  const [foods, setFoods] = useState([]);
  const [drinks, setDrinks] = useState([]);
  const { upsertProductStore } = useStore();
  
  const location = useLocation();

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    autorun(() => {
      ApiManager.get(`/Menu/GetMenu`, {
        params: {
          jwtToken: jwtToken,
          archived: upsertProductStore.showArchivedProducts,
        },
      })
        .then((response) => {
          const flattenDataDessert = [];
          response.data.desserts.forEach((item) => {
            const dessert = item.dessert;
            flattenDataDessert.push({
              name: dessert.name,
              description: dessert.description,
              calories: dessert.calories,
              price: parseFloat(dessert.price).toFixed(2),
              cookingTime: dessert.cookingTime,
              productType: dessert.productType,
              productId: dessert.dessertId,
            });
          });
          setDesserts(flattenDataDessert);

          const flattenDataFood = [];
          response.data.foods.forEach((item) => {
            const food = item.food;
            flattenDataFood.push({
              name: food.name,
              description: food.description,
              calories: food.calories,
              price: parseFloat(food.price).toFixed(2),
              cookingTime: food.cookingTime,
              productType: food.productType,
              productId: food.foodId,
            });
          });
          setFoods(flattenDataFood);

          const flattenDataDrinks = [];
          response.data.drinks.forEach((item) => {
            const drink = item.drink;
            flattenDataDrinks.push({
              name: drink.name,
              description: drink.description,
              calories: drink.calories,
              price: parseFloat(drink.price).toFixed(2),
              cookingTime: drink.cookingTime,
              productType: drink.productType,
              productId: drink.drinkId,
            });
          });
          setDrinks(flattenDataDrinks);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [upsertProductStore.showArchivedProducts]);
  }, []);
  
  return (
    <>
      <AddProductsNavbar isArchived={location.state?.isArchived}/>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
        <ProductTable
          columnsData={columnsData}
          tableData={foods}
          tableName="Lista e ushqimeve"
        />
      </div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <ProductTable
          columnsData={columnsData}
          tableData={drinks}
          tableName="Lista e pijeve"
        />
      </div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <ProductTable
          columnsData={columnsData}
          tableData={desserts}
          tableName="Lista e ëmbëlsirave"
        />
      </div>
    </>
  );
};

export default ProductList;
