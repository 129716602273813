export const columnsClientData = [
    {
      Header: "EMRI",
      accessor: "name",
    },
    {
      Header: "ADRESA",
      accessor: "address",
    },
    {
      Header: "NR.TEL",
      accessor: "phoneNumber",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "EMRI I LLOGARISË",
      accessor: "accountName",
    },
    {
      Header: "NUMRI I LLOGARISË",
      accessor: "accountNumber",
    }
  ];
  