export const validate = (values) => {
  const requiredErrorMessage = "Kjo fushë është e kërkuar!";
  const errors = {};
  if (!values.itemName) {
    errors.itemName = requiredErrorMessage;
  } else if (values.itemName.length < 4 || values.itemName.length > 40) {
    errors.name = "Numri i karaktereve duhet te jetë mes 3 dhe 40!";
  }
  if (values.description.length < 10 || values.description.length > 150) {
    errors.description = "Numri i karaktereve duhet te jetë mes 10 dhe 150!";
  }
  if (!values.quantity) {
    errors.quantity = requiredErrorMessage;
  } else if (values.quantity < 0) {
    errors.quantity = "Sasia nuk mund të jetë më e vogel se zero!";
  } else if (!/^[0-9.]+$/.test(values.quantity)) {
    errors.quantity = "Vlera e shënuar duhet të përmbajë vetëm numra!";
  }
  if (!values.reorderLevel) {
    errors.reorderLevel = requiredErrorMessage;
  } else if (!/^[0-9.]+$/.test(values.reorderLevel)) {
    errors.reorderLevel = "Vlera e shënuar duhet të përmbajë vetëm numra!";
  }
  if (!values.pricePerUnit) {
    errors.pricePerUnit = requiredErrorMessage;
  } else if (values.pricePerUnit < 0) {
    errors.pricePerUnit = "Çmimi nuk mund të jetë më i vogel se zero!";
  } else if (!/^[0-9.]+$/.test(values.pricePerUnit)) {
    errors.pricePerUnit = "Vlera e shënuar duhet të përmbajë vetëm numra!";
  }
  return errors;
};
