import React, { useMemo, useEffect } from "react";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Pagination from "components/pagination"; 

const RatingTable = (props) => {
  const { columnsData, tableData, tableName } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  // Using usePagination hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 }, // Adjust pageSize as needed
    },
    useGlobalFilter,
    useSortBy,
    usePagination // Enable pagination
  );


  // Set the page size when the component mounts
  useEffect(() => {
    setPageSize(25); // Adjust pageSize as needed
  }, [setPageSize]);


  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {tableName}
          </div>
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table
            {...getTableProps()}
            className="w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  <th
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start 
                  "
                  >
                    <div className="text-xs font-medium tracking-wide text-gray-800">
                      NR.
                    </div>
                  </th>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pr-16 pb-[10px] text-start 
                      "
                      key={index}
                    >
                      <div className="text-xs font-medium tracking-wide text-gray-800 uppercase">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {data.length > 0 ? (
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={index}
                      className="cursor-pointer hover:bg-blue-50 "
                      {...row.getRowProps()}
                    >
                      <td>
                        <div className="flex items-center gap-2 overflow-y-auto  block  w-[200px]  lg:w-full ">
                          <p className="text-sm font-bold text-navy-700 ">
                            {index + 1}
                          </p>
                        </div>
                      </td>
                      {row.cells.map((cell, index) => {
                        let data = "";
                        data = (
                          <div className="flex items-center gap-2 overflow-y-auto  block w-[200px] lg:w-full">
                            <p className=" block text-sm font-bold  text-navy-700 overflow-x-auto text-start" >
                              {cell?.value || "Nuk është dhënë"}
                            </p>
                          </div>
                        );

                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="pt-[14px] pb-[16px] sm:text-[14px]"
                          >
                            {data}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan={columns.length + 1}
                    className="text-center text-sm font-bold text-navy-700 "
                  >
                    Nuk ka të dhëna të paraqitura!
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </Card>
    </>
  );
};

export default RatingTable;
