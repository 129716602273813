import { ApiManager } from "app/ApiManager";
import React from "react";
import { useStore } from "app/stores/store";

const ProcessPaymentBtn = ({ table, totalPrice, orderIds }) => {
  const { ordersStore } = useStore();

  const ProcessPayment = (isFifo) => {
    ApiManager.post("/Order/ProcessPayment", {
      TotalPrice: parseFloat(totalPrice),
      TableId: table.tableId,
      OrderIds: orderIds,
    }, {
      params: {
        jwtToken: localStorage.getItem("token"),
        isFifo: isFifo,
      }
    })
      .then((response) => {
        ordersStore.processPaymentAndClearOrders(table.tableId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <button
        className="btn btn-outline btn-success btn-md mb-5 md:mb-2 mx-2"
        onClick={() => window.my_modal_1.showModal()}
      >
        Paguaj porosinë
      </button>
      <dialog id="my_modal_1" className="modal">
        <form method="dialog" className="modal-box">
          <h3 className="text-center text-lg font-bold">
            Jeni të sigurt që dëshironi të paguani për {table.name}?
          </h3>
          <p className="py-4 text-center">Totali për pagesë: {totalPrice.toFixed(2)} €</p>
          <div className="mt-3 flex justify-center gap-x-5">
            <button className="btn">Anulo</button>
            <button className="btn" onClick={() => ProcessPayment(false)}>
              Paguaj
            </button>
            <button className="btn" onClick={() => ProcessPayment(true)}>FIFO</button>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default ProcessPaymentBtn;
