export const columnsData = [
  {
    Header: "EMRI",
    accessor: "name",
  },
  {
    Header: "LLOJI PRODUKTIT",
    accessor: "productType",
  },
  {
    Header: "PËRSHKRIMI",
    accessor: "description",
  },
  {
    Header: "ÇMIMI",
    accessor: "price",
  },
  {
    Header: "KALORITË",
    accessor: "calories",
  },
  {
    Header: "KOHA PËRGATITJES",
    accessor: "cookingTime",
  },
];
