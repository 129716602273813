import React, { useEffect, useContext, useState } from "react";
import { MdNotifications, MdOutlineDeleteOutline } from "react-icons/md";
import { ApiManager } from "app/ApiManager";
import { observer } from "mobx-react";
import { SignalRConnectionContext } from "app/SignalRConnectionProvider";

const Notification = observer(() => {
  const { connection, connectionStatus } = useContext(SignalRConnectionContext);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [soundPlayed, setSoundPlayed] = useState(false);

  const playSound = () => {
    // if(!soundPlayed) {
      ApiManager.get("/Order/PlaySound")
    .then(() => {
      // setSoundPlayed(!soundPlayed);
    })
    .catch(() => {});
    // }
  }

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await ApiManager.get(
          "/Notification/GetNotificationForPayment",
          {
            params: {
              jwtToken: localStorage.getItem("token"),
            },
          }
        );
        setNotifications(response.data);
        if (connection && connectionStatus === "connected") {
          connection.on("ReceiveNotification", (notification) => {
            playSound();
            fetchCount();
            if (notification && notification.id) {
              setNotifications((prevNotifications) => [
                ...prevNotifications,
                { ...notification },
              ]);
              return;
            } else {
              console.error(
                "Received notification is null or missing id",
                notification
              );
            }
          });

          return () => {
            connection.off("ReceiveNotification");
          };
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchCount = async () => {
      try {
        const response = await ApiManager.get(`/Notification/GetCount?jwtToken=${localStorage.getItem("token")}`)
      setNotificationCount(response.data);
      }
      catch(err) { }
    }
    fetchNotifications();
    fetchCount();
  }, [connection, connectionStatus]);

  const handleDeleteNotification = (id) => {
    ApiManager.delete(`/Notification/DeleteNotification/${id}`)
      .then(() => {
        const updatedNotifications = notifications.filter(
          (notification) => notification.id !== id
        );
        setNotifications(updatedNotifications);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const readNotifications = () => {
    ApiManager.post(`/Notification/ReadNotifications?jwtToken=${localStorage.getItem("token")}`)
    .then((response) => {
      if(response.data) {
        ApiManager.get(`/Notification/GetCount?jwtToken=${localStorage.getItem("token")}`)
        .then((resp) => {
          setNotificationCount(resp.data);
        }).catch((err) => {})
      }
    }).catch((err) => {

    })
      
    }

  return (
    <button onClick={() => readNotifications()} className="dropdown-end btn dropdown relative">
      <div className="flex items-center">
        <MdNotifications className="relative h-6 w-6 text-gray-700" />
        <span className="absolute -top-1 -right-1 rounded-full bg-yellow-500 px-2 py-1 text-xs text-white">
          {notificationCount ? notificationCount : 0}
        </span>
      </div>
        <ul className="menu dropdown-content rounded-box z-[1] mt-2 bg-white p-2 shadow-lg">
          {notifications.map((notification, index) => (
            <li key={index} className="border-b border-gray-200 py-2">
              <div className="flex justify-between text-sm font-normal text-gray-700">
                <p>{notification.message}</p>
                <button onClick={() => handleDeleteNotification(notification.id)}>
                  <MdOutlineDeleteOutline className="h-6 w-6 text-red-300 hover:text-red-500" />
                </button>
              </div>
            </li>
          ))}
        </ul>
    </button>
  );
});

export default Notification;
