import React, { useMemo,useEffect } from "react";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate } from "react-router-dom";
import CardMenu from "./CardMenu";
import Pagination from "components/pagination";

const OfferTable = (props) => {
  const navigation = useNavigate();
  const { columnsData, tableData, tableName } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Set initial page size
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(10); // Adjust pageSize as needed
  }, [setPageSize]);

  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700">{tableName}</div>
          <CardMenu />
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table
            {...getTableProps()}
            className="w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  <th
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start 
                 "
                  >
                    <div className="text-xs font-medium tracking-wide text-gray-800">
                      NR.
                    </div>
                  </th>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pr-16 pb-[10px] 
                      text-start"
                      key={index}
                    >
                      <div className="text-xs font-medium tracking-wide text-gray-800">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data.length !== 0 ? (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        navigation("/admin/offer-details", {
                          state: {
                            offerId: row.original.offerId,
                          },
                        });
                      }}
                      className="cursor-pointer hover:bg-blue-50"
                      {...row.getRowProps()}
                    >
                      <td>
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700">
                            {index + 1}
                          </p>
                        </div>
                      </td>
                      {row.cells.map((cell, index) => {
                        let data = "";
                        if (cell.column.Header === "ÇMIMI") {
                          data = (
                            <div className="flex items-center gap-2">
                              <p className="text-center text-sm font-bold text-navy-700">
                                {cell.value} €
                              </p>
                            </div>
                          );
                        } else {
                          data = (
                            <div className="flex items-center gap-2">
                              <p className="text-sm font-bold text-navy-700">
                                {cell.value}
                              </p>
                            </div>
                          );
                        }

                        return (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className="pt-[14px] pb-[16px] sm:text-[14px]"
                          >
                            {data}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td 
                    colSpan={columns.length + 1}
                    class="md:text-center text-sm font-bold text-navy-700 py-3"
                  >
                    Nuk ka produkte në këtë kategori
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={pageIndex + 1}
          totalPages={pageCount}
          handlePrevious={() => previousPage()}
          handleNext={() => nextPage()}
          handlePageClick={(page) => gotoPage(page - 1)}
          canPrevious={canPreviousPage}
          canNext={canNextPage}
        />
      </Card>
    </>
  );
};

export default OfferTable;
