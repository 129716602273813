import React, { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import Card from "components/card";
import ErrorLabel from "components/error/ErrorLabel";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import { useNavigate, useLocation } from "react-router-dom";
import { validate } from "./variables/ValidateForm";
import SpinnerButton from "components/buttons/SpinnerButton";
import Swal from "sweetalert2";

const UpsertOwner = () => {
  const navigation = useNavigate();
  const [headerText, setHeaderText] = useState("Regjistrim");
  const location = useLocation();
  const [employee, setEmployee] = useState({});
  const [employeeId, setEmployeeId] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);


  useEffect(() => {
    if (location.state) {
        setEmployeeId(location.state.id);
      if (location.state.id) {
        setHeaderText("Përditësim");
        let endpoint = `/User/EmployeeById/${location.state.id}`;

        if (endpoint) {
          const fetchData = async () => {
            try {
              const response = await ApiManager.get(endpoint);
              setEmployee(response.data);
            } catch (error) {
              console.log(error);
            }
          };
          fetchData();
        }
      } else {
        setHeaderText("Regjistrim");
      }
    }
  }, [location.state]);

  const formik = useFormik({
    initialValues: {
      id: location?.state?.id,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },

    validate: (values) => validate(values),
    onSubmit: (values) => {
      setIsWaiting(true);

      let endpoint;
      if (employeeId !== 0) {
        endpoint = "/User/EditEmployee";
      } else {
        endpoint = "/User/UpsertEmployee";
      }
      const obj = {
        ...values,
        jobPosition: "Drejtor",
      };
      
      ApiManager.post(endpoint, obj, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          jwtToken: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          setIsWaiting(false);
          if (response.data === 1) {
            Swal.fire({
              icon: "success",
              title: `Drejtori është ${headerText
                .slice(0, -2)
                .toLowerCase()}uar me sukses`,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              navigation("/admin/manager-list");
            });
          } else if (response.data === 0) {
            Swal.fire({
              icon: "error",
              title: `Ndodhi nje gabim, provoni perseri me vone`,
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data === -1) {
            Swal.fire({
              icon: "error",
              title: `Ekziston nje drejtor me kete email!`,
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data === -2) {
            Swal.fire({
              icon: "error",
              title: `Ekziston nje drejtor me kete numer telefoni`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setIsWaiting(false);
        });
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      firstName: employee?.firstName || "",
      lastName: employee?.lastName || "",
      email: employee?.email || "",
      phoneNumber: employee?.phoneNumber || "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const { touched, errors, handleSubmit } = formik;
  const handleAnulo = () =>{
    navigation("/admin/manager-list");
 }
  return (
    <>
      <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
        <h3 className=" my-2 mb-4 text-xl font-bold text-navy-700 dark:text-white">
          {headerText} i drejtorit
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Emri*"
                id="firstName"
                name="firstName"
                type="text"
                {...formik.getFieldProps("firstName")}
                state={errors.firstName && touched.firstName ? "error" : null}
              />
              {errors.firstName && touched.firstName ? (
                <ErrorLabel error={errors.firstName} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                extra="mb-1"
                label="Mbiemri*"
                type="text"
                id="lastName"
                name="lastName"
                {...formik.getFieldProps("lastName")}
                state={errors.lastName && touched.lastName ? "error" : null}
              />
              {errors.lastName && touched.lastName ? (
                <ErrorLabel error={errors.lastName} />
              ) : null}
            </div>
          </div>
          <div className=" w-full">
            {/* <SelectList
              label="Pozita e punës"
              selected={selectedManager}
              setSelected={setSelectedManager}
            /> */}
          </div>
          <div className="flex justify-between gap-x-3">
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Email*"
                type="email"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
                state={errors.email && touched.email ? "error" : null}
              />
              {errors.email && touched.email ? (
                <ErrorLabel error={errors.email} />
              ) : null}
            </div>
            <div className="mb-3 w-full">
              <InputField
                variant="auth"
                label="Numri i telefonit"
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                {...formik.getFieldProps("phoneNumber")}
                state={errors.phoneNumber && touched.phoneNumber ? "error" : null}
              />
              {errors.phoneNumber && touched.phoneNumber ? (
                  <ErrorLabel error={errors.phoneNumber} />
                ) : null}
            </div>
          </div>
          <div>
          </div>
          <div className="flex justify-between">
          <SpinnerButton title={"Ruaj të dhënat"} isWaiting={isWaiting} />
          <SpinnerButton title={"Anulo të dhënat"} isWaiting={isWaiting}  secondary={true} onClick={()=>handleAnulo()} />
          </div>
        </form>
      </Card>
    </>
  );
};

export default UpsertOwner;
