import React, { useState, useEffect } from "react";
import SelectList from "views/admin/menu/components/SelectList";
import { ApiManager } from "app/ApiManager";
import InputField from "components/fields/InputField";

const StockItem = ({ index, stocks, stockType, deleteRow, stockOptions }) => {
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [sellingPrice, setSellingPrice] = useState(selectedStock?.pricePerUnit);
  const [percentage, setPercentage] = useState(100);
  const [salePrice, setSalePrice] = useState(null);
  const [sum, setSum] = useState(null);

  useEffect(() => {
    const fetchStock = async (type) => {
      const response = await ApiManager.get(
        `/${type === "Stoku FIFO" ? "Fifo" : "Fiscal"}Stock/GetById`,
        {
          params: {
            id: stockOptions[0].value,
          },
        }
      );
      setSelectedOption({
        value: response.data.id,
        name: response.data.itemName,
      });
      setSelectedStock(response.data);
    };
    setSellingPrice(selectedStock?.pricePerUnit);
    fetchStock(stockType);
  }, [stockType, stockOptions]);

  const changeSelectedOption = (option) => {
    setSelectedOption(option);
    setSelectedStock(stocks.find((stock) => stock.id === option.value));
  };

  const changeSellingPrice = (input) => {
    var value = input.target.value;
    if(value <= selectedStock.pricePerUnit) {
      if(value.length <= 10)
      setSellingPrice(input.target.value);
    }
  }

  const changePercentage = (input) => {
    const value = input.target.value;
    if(value <= 100 && value >= 0) {
      setPercentage(value);
      if(sellingPrice) setSalePrice((sellingPrice * value / 100).toFixed(2));
      else {
        setSellingPrice(selectedStock?.pricePerUnit);
      }
      setSum(selectedStock.quantity * (sellingPrice * value / 100).toFixed(2))
    }
  }

  return (
    <tr
      //   onClick={() => handleRowClick(stock)}
      className="py-2 rows"
    >
      <td>
        {selectedStock && stockOptions && stockOptions.length >= 1 ? (
          <SelectList
            id={`optionsName-${index}`}
            selected={selectedOption}
            setSelected={(option) => {
              changeSelectedOption(option);
            }}
            options={stockOptions}
          />
        ) : null}
      </td>
      <td id={`quantity-${index}`}>{selectedStock && selectedStock.quantity}</td>
      <td id={`price-${index}`}>{selectedStock && selectedStock.pricePerUnit?.toFixed(2)} €</td>
      <td id={`sellingPrice-${index}`}>
        {sellingPrice ? sellingPrice?.toFixed(2) : selectedStock?.pricePerUnit?.toFixed(2)} €
        {/* <InputField
          onChange={(e) => changeSellingPrice(e)}
          variant="auth"
          extra="mb-1 flex w-1/2"
          id={`sellingPrice-${index}`}
          name="sellingPrice"
          type="text"
          value={sellingPrice ? sellingPrice : selectedStock?.pricePerUnit?.toFixed(2)}
        /> */}
      </td>
      <td>
        <InputField
          onChange={changePercentage}
          variant="auth"
          extra="mb-1 flex w-2/5"
          id={`percentage-${index}`}
          name="percentage"
          type="text"
          value={percentage && percentage}
        />
      </td>
      <td id={`salePrice-${index}`}>{salePrice ? salePrice : selectedStock?.pricePerUnit?.toFixed(2)}</td>
      <td id={`sum-${index}`}>{sum ? sum?.toFixed(2) : (selectedStock?.pricePerUnit * selectedStock?.quantity).toFixed(2)}</td>
      <td>
        <button
          onClick={(e) => deleteRow(index)}
          className="linear flex items-center justify-center gap-2 rounded-lg bg-red-500 px-2 text-white transition duration-200 hover:cursor-pointer hover:bg-red-400  "
        >
          <span className="px-4 py-2 text-sm font-medium text-white">
            Fshij
          </span>
        </button>
      </td>
    </tr>
  );
};

export default StockItem;
