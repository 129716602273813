export const validate = (values, file, switchChecked) => {
    const requiredErrorMessage = "Kjo fushë është e kërkuar!";
    const errors = {};
    if (!values.name) {
      errors.name = requiredErrorMessage;
    } else if (values.name.length < 3 || values.name.length > 20) {
      errors.name = "Numri i karaktereve duhet të jetë mes 3 dhe 10!";
    } else if (!/^[a-zA-Z\s]+$/.test(values.name)) {
      errors.name = "Vlera e shënuar duhet të përmbajë vetëm shkronja!";
    }
    if (!values.nub) {
      errors.nub = requiredErrorMessage;
    } else if (!/^\d{9}$/.test(values.nub)) {
      errors.nub = "Vlera e shënuar duhet të përmbajë vetëm 9 numra!";
    }
    if (!values.email) {
      errors.email = requiredErrorMessage;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.email)
    ) {
      errors.email = "Emaili i dhënë nuk është valid!";
    }
    if(!values.accountName) {
      errors.accountName = requiredErrorMessage;
    }

    if(!values.accountNumber){
      errors.accountNumber = requiredErrorMessage;
    }
    else if(!/^\d{16}$/.test(values.accountNumber)){
      errors.accountNumber = "Vlera e shënuar duhet të përmbajë vetëm 16 numra!";
    }
    // if (!values.managerId) {
    //   errors.managerId = requiredErrorMessage;
    // } else if (!/^(?!0$)[0-9]+$/.test(values.managerId)) {
    //   errors.managerId = "Vlera e shënuar duhet të përmbajë vetëm shkronja!";
    // }
    return errors;
  };
  