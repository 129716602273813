import { makeAutoObservable } from "mobx";

export default class UpsertProductStore {
  rowsData = [
    { ingredientName: "", quantity: 0, unitQuantity: "", recipeId: 0, stockId: 0, type: "" },
  ];
  showArchivedProducts = false;
  showArchivedOffers = false;

  constructor() {
    makeAutoObservable(this);
  }

  setRowsData(newData) {
    this.rowsData = newData;
  }

  getRowsData() {
    return [...this.rowsData];
  }
  resetState() {
    this.rowsData = [
      { ingredientName: "", quantity: 0, unitQuantity: "", recipeId: 0 },
    ];
  }
}
