import React from "react";

const RecipeInput = ({
  value,
  onChange,
  name,
  placeholder,
  type,
  state,
  autoComplete,
  disabled,
  extraClassNames
}) => {
  return (
    <div>
      <input
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        name={name}
        autoComplete={autoComplete}
        placeholder={placeholder}
        className={`${extraClassNames} border-bottom-gray-300 my-1 h-8 w-20 md:w-48 items-center justify-center rounded-xl p-2 text-sm outline-none outline-offset-0 focus:outline-blue-500 ${
          disabled === true
            ? "!border-none !bg-gray-100 "
            : state === "error"
            ? "border border-red-400 bg-white/0 text-red-500 outline-none placeholder:text-red-500 focus:outline focus:outline-0 "
            : "border border-gray-200 "
        }`}
      />
    </div>
  );
};

export default RecipeInput;
