import React, { useState } from 'react';
import InputField from "components/fields/InputField";

const UpsertOwner = () => {
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const changeInput = (fn, value) => {
        fn(value);
    }

  return (
    <>
      <div className="flex justify-between gap-x-3">
        <div className="mb-3 w-full">
          <InputField
            onChange={(e) => changeInput(setName, e.target.value)}
            value={name}
            variant="auth"
            label="Emri i drejtorit*"
            type="text"
            id="owner-firstName"
            name="owner-firstName"
          />
          {!/^[a-zA-Z\s]+$/.test(name) ? (
            <div className="owner-error text-sm text-red-400">
                Vlera e shënuar duhet të përmbajë vetëm shkronja!
            </div>
          ) : null}
        </div>
        <div className="mb-3 w-full">
          <InputField
            onChange={(e) => changeInput(setLastName, e.target.value)}
            value={lastName}
            variant="auth"
            label="Mbiemri i drejtorit*"
            type="text"
            id="owner-lastName"
            name="owner-lastName"
          />
          {!/^[a-zA-Z\s]+$/.test(lastName) ? (
            <div className="owner-error text-sm text-red-400">
                Vlera e shënuar duhet të përmbajë vetëm shkronja!
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex justify-between gap-x-3">
        <div className="mb-3 w-full">
          <InputField
            onChange={(e) => changeInput(setEmail, e.target.value)}
            value={email}
            variant="auth"
            label="Email i drejtorit*"
            type="email"
            id="owner-email"
            name="owner-email"
          />
          {!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) ? (
            <div className="owner-error text-sm text-red-400">
                Emaili i dhënë nuk është valid!
            </div>
          ) : null}
        </div>
        <div className="mb-3 w-full">
          <InputField
            onChange={(e) => changeInput(setPhoneNumber, e.target.value)}
            value={phoneNumber}
            variant="auth"
            label="Numri i telefonit i drejtorit"
            type="text"
            id="owner-phoneNumber"
            name="owner-phoneNumber"
          />
          {!/^\d{9}$/.test(phoneNumber) ? (
            <div className="owner-error text-sm text-red-400">
                Vlera e shënuar duhet të përmbajë vetëm 9 numra!
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UpsertOwner;
