export const columnsData = [

  {
    Header: "EMRI I PLOTË",
    accessor: "name",
  },
  {
    Header: "PËRSHKRIMI",
    accessor: "description",
  },
];
