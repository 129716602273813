import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const CheckRole = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (token) {
    console.log("token");
    const decodedToken = jwt_decode(token);
    const role = decodedToken.Role;
    if (role === "Menaxher") {
      navigate("/admin/panel");
    } else if (role === "Kamarier") {
      navigate("/waiter/panel");
    } else if (role === "Kuzhinier") {
      navigate("/chef/panel");
    }
  } else {
    console.log("No token");
    navigate("/auth/sign-in");
  }
};

export const ReturnRole = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = jwt_decode(token);
    const role = decodedToken.Role;
    return role;
  }
  return null;
}

export default CheckRole;
