import Revenue from "views/admin/default/components/Revenue";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import Widget from "components/widget/Widget";
import FoodCount from "components/product/FoodCount";
import DrinkCount from "components/product/DrinkCount";
import DessertCount from "components/product/DessertCount";
import OfferCount from "components/product/OfferCount";
import UserCount from "components/user/UserCount";
import TotalSales from "components/sales/TotalSales";
import StockCount from "components/stock/StockCount";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = ({ selectedBusiness }) => {
  const navigation = useNavigate();

  useEffect(() => {}, [selectedBusiness]);

  const goToPage = (page) => {
    navigation(page);
  };

  return (
    <div>
      {/* Card widget */}

      <div
        className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 
      2xl:grid-cols-3 3xl:grid-cols-6"
      >
        <div
          onClick={() => goToPage("/admin/product-list")}
          className="cursor-pointer"
        >
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Numri i artikujve ushqimorë"}
            subtitle={<FoodCount selectedBusiness={selectedBusiness} />}
          />
        </div>

        <div
          onClick={() => goToPage("/admin/product-list")}
          className="cursor-pointer"
        >
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Numri i pijeve"}
            subtitle={<DrinkCount selectedBusiness={selectedBusiness} />}
          />
        </div>

        <div
          onClick={() => goToPage("/admin/product-list")}
          className="cursor-pointer"
        >
          <Widget
            icon={<MdDashboard className="h-6 w-6" />}
            title={"Numri i ëmbëlsirave"}
            subtitle={<DessertCount selectedBusiness={selectedBusiness} />}
          />
        </div>

        <div
          onClick={() => goToPage("/admin/offers-list")}
          className="cursor-pointer"
        >
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Numri i ofertave"}
            subtitle={<OfferCount selectedBusiness={selectedBusiness} />}
          />
        </div>

        <div
          onClick={() => goToPage("/admin/employee-list")}
          className="cursor-pointer"
        >
          <Widget
            icon={<IoDocuments className="h-6 w-6" />}
            title={"Numri i punëtorëve"}
            subtitle={<UserCount selectedBusiness={selectedBusiness} />}
          />
        </div>

        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Shitjet totale"}
          subtitle={<TotalSales selectedBusiness={selectedBusiness} />}
        />
        <div
          onClick={() => goToPage("/admin/stock-list")}
          className="cursor-pointer"
        >
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Stoku i përgjithshëm"}
            subtitle={<StockCount selectedBusiness={selectedBusiness} />}
          />
        </div>
      </div>
      <div className="mt-5">
        <Revenue selectedBusiness={selectedBusiness} />
      </div>
    </div>
  );
};

export default Dashboard;
