import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    if(error.message === "Invalid token specified") {
      window.location.href = "/auth/sign-in";
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any fallback UI here
      return (
        <div>
        </div>
      );
    }

    // If there's no error, render the children as usual
    return this.props.children;
  }
}

export default ErrorBoundary;