import React, { useState, useEffect } from "react";
import MonthlyFinance from "./MonthlyFinance";
import YearlyFinance from "./YearlyFinance";
import WeeklyFinance from "./WeeklyFinance";
import { MdOutlineCalendarToday } from "react-icons/md";

const Finance = () => {
  const [selectedValue, setSelectedValue] = useState("Daily");

  const changeValue = () => {
    if (selectedValue === "Daily") {
      setSelectedValue("Monthly")
    } else if (selectedValue === "Monthly") {
      setSelectedValue("Yearly")
    }  else {
      setSelectedValue("Daily")
    }
  }

  return (
    <div>
      <div className="flex justify-between">
        <button onClick={changeValue} className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-800 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 ">
          <MdOutlineCalendarToday />
          <span className="text-sm font-medium text-gray-800">
            {selectedValue === "Daily" ? 
            "Të ardhurat ditore" :
            selectedValue === "Monthly" ?
            "Të ardhurat mujore" :
            "Të ardhurat vjetore"
            }
          </span>
        </button>
      </div>
      <div className="mt-8 flex flex-col gap-5">
      <div className="">
        {
          selectedValue === "Yearly" ? <YearlyFinance tableName="Financat vjetore" /> :
          selectedValue === "Monthly" ? <MonthlyFinance tableName="Financat mujore" /> :
          <WeeklyFinance tableName="Financat ditore" />
        }
        
      </div>
      <div className="">
        
      </div>
      <div className="">
        
      </div>
    </div>
    </div>
    
  );
};

export default Finance;
