import React, { useEffect, useContext } from "react";
import { useStore } from "app/stores/store";
import Navbar from "./components/Navbar";
import TablesList from "./components/TablesList";
import OrdersTable from "./components/OrdersTable";
import { SignalRConnectionContext } from "app/SignalRConnectionProvider";
import { ApiManager } from "app/ApiManager";

const WaiterDashboard = () => {
  const { ordersStore } = useStore();
  const { connection, connectionStatus } = useContext(SignalRConnectionContext);

  useEffect(() => {
    if (ordersStore.isUserLoggedIn()) {
      ordersStore.fetchInitialData();
    }
    if (connection && connectionStatus === "connected") {
      const playSound = () => {
        ApiManager.get("/Order/PlaySound")
        .catch();
      }
      const handleOrder = (orderVM) => {
        try {
          playSound();
          ordersStore.handleRealTimeOrder(orderVM);
        } catch (error) {
          console.error("Error handling real-time order:", error);
        }
      };
      const handleOnlineOrder = (orderVM) => {
        try {
          ordersStore.handleRealTimeOnlineOrder(orderVM)
        }
        catch(error) {

        }
      }

      connection.on("ReceiveOrder", handleOrder);
      connection.on("ReceiveOnlineOrder", handleOnlineOrder);

      return () => {
        connection.off("ReceiveOrder", handleOrder);
        connection.off("ReceiveOnlineOrder", handleOnlineOrder);
      };
    }
  }, [connection, connectionStatus, ordersStore.isUserLoggedIn]);

  return (
    <div className="min-h-screen bg-lightPrimary md:p-2">
      <div className="fixed top-0 left-0 right-0 z-50 bg-white lg:static">
        <Navbar />
      </div>
      <div className="mt-5">
        <div class="flex w-full flex-col flex-col-reverse gap-3 lg:flex-row">
          <div class="order-2 lg:order-1 lg:w-2/3">
            <TablesList />
          </div>
          <div class="order-1 lg:order-2 lg:w-1/3">
            <OrdersTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaiterDashboard;
