import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorLabel from "components/error/ErrorLabel";
import { useFormik } from "formik";
import { ApiManager } from "app/ApiManager";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SpinnerButton from "components/buttons/SpinnerButton";
import baseURL from "app/ApiManager";
import axios from "axios";
import Swal from "sweetalert2";
import SelectList from "views/admin/menu/components/SelectList";

const UpsertTable = () => {
  const printQrCode = (e, tableId, businessId) => {
    e.preventDefault();

    const fetchImage = axios.create({
      baseURL: baseURL,
      responseType: "json",
    });
    fetchImage
      .get(`/TableQr/Tavolina${tableId}-${businessId}.png`, {
        responseType: "blob",
      })
      .then((response) => {
        var blob = response.data;
        if (blob.size === 0) return;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `Tavolina${tableId}-${businessId}.jpeg`;
        a.click();
        a.remove();
      });
  };

  const [headerText, setHeaderText] = useState("");
  const [table, setTable] = useState({});
  const navigation = useNavigate();
  const location = useLocation();
  const [tableId, setTableId] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log("upsert Table");


  const handleButtonClick = (isEdit) => {
    setIsModalOpen(true);
    Swal.fire({
      icon: "success",
      title: isEdit ? "Paisja u përditësua me sukses!" : "Paisja u shtua me sukses!",
      showConfirmButton: false,
      timer: 2000
    }).then((result) => {
        navigation("/admin/table-list");
    });
  };

  const handleAnulo = () =>{
     navigation("/admin/table-list");
  }
  
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (location.state) {
      setTableId(location.state.tableId);
      if (location.state.tableId) {
        setHeaderText("Përditësim");
        let endpoint = `/Table/GetTableById/${location.state.tableId}`;
        const fetchData = async () => {
          try {
            const response = await ApiManager.get(endpoint);
            setTable(response.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }
    } else {
      setHeaderText("Regjistrim");
    }
  }, [location.state]);

  useEffect(() => {
    ApiManager.get("/Ambient/GetAmbientsByBusinessId", {
      params: { jwtToken: localStorage.getItem("token") },
    })
      .then((response) => {
        setSelectOptions(
          response.data.map((ambient) => ({
            value: ambient.ambientId,
            name: ambient.name,
          }))
        );
      })
      .catch((error) => console.error(error));
  }, []);
  const formik = useFormik({
    initialValues: {
      name: table?.name || "",
      capacity: table?.capacity || "",
      ambientId: table?.ambientId || "",
    },
    //validate,
    onSubmit: (values) => {
      setIsWaiting(true);
    
      // Ensure ambientId is in the correct format
      const formattedValues = {
        ...values,
        ambientId: typeof values.ambientId === 'object' ? values.ambientId.value : values.ambientId,
        ...(tableId && { tableId })
      };
      
    
      const jwtToken = localStorage.getItem("token");
      let endpoint = tableId ? "/Table/EditTable" : "/Table/AddTable";
    
      if (formattedValues.capacity < 1) {
        // Handle capacity error
        setIsWaiting(false);
        return;
      }
      ApiManager.post(endpoint, formattedValues, {
        headers: {
          "Content-Type": "application/json",
        },
        
        params: {
          jwtToken: jwtToken,
        },
      })
      .then((response) => {
        setIsWaiting(false);
        handleButtonClick(!!tableId);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsWaiting(false);
      });
    },
    
  });

  useEffect(() => {
    if (table) {
      formik.setValues({
        name: table.name || "",
        capacity: table.capacity || "",
        ambientId: selectOptions.find((o) => o.value === table.ambientId) || "",
      });
    }
  }, [table, selectOptions]);

  const { touched, errors, handleSubmit } = formik;
  return (
    <Card extra={"w-full sm:overflow-auto p-4 mt-5"}>
      <h3 className=" my-2 mb-4 text-xl font-bold text-navy-700 ">
        {headerText} i paisjes
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="md:flex justify-between gap-x-3">
          <div className="mb-3 w-full">
            <InputField
              variant="auth"
              extra="mb-1"
              label="Emërtimi*"
              placeholder="P.sh tavolina 1"
              id="name"
              name="name"
              type="text"
              {...formik.getFieldProps("name")}
              state={errors.name && touched.name ? "error" : null}
            />
            {errors.name && touched.name ? (
              <ErrorLabel error={errors.name} />
            ) : null}
          </div>
          <div className="mb-3 w-full">
            <InputField
              variant="auth"
              extra="mb-1"
              label="Kapaciteti*"
              placeholder="P.sh 4"
              id="capacity"
              name="capacity"
              type="number"
              {...formik.getFieldProps("capacity")}
              state={errors.capacity && touched.capacity ? "error" : null}
            />
            {errors.capacity && touched.capacity ? (
              <ErrorLabel error={errors.capacity} />
            ) : null}
          </div>
          <div className="w-full items-center">
            <SelectList
              label="Ambienti*"
              selected={formik.values.ambientId}
              setSelected={(value) => {
                formik.setFieldValue("ambientId", value);
              }}
              options={selectOptions}
            />
          </div>
        </div>
        <div className="md:flex md:flex-row items-center  justify-center gap-4">
          {table.tableId ? (
            <button
              onClick={(e) => printQrCode(e, table.tableId, table.businessId)}
              className=" linear mx-auto mt-4 flex w-1/2 justify-center rounded-xl 
                bg-brand-500 py-[12px] text-base font-medium text-white transition 
                duration-200 w-full md:w-[300px] hover:bg-brand-600 active:bg-brand-700  "
              >
                Printo QrCode
              </button>
        ) : null
        }
        {/* <div className="md:flex justify-around"> */}
        <SpinnerButton type="button" title={"Ruaj të dhënat"} isWaiting={isWaiting} onClick={()=>handleButtonClick()} isTable={true}/>
        <SpinnerButton type="button" title={"Anulo të dhënat"} isWaiting={isWaiting} isTable={true}  secondary={true}  onClick={()=>handleAnulo()} />
        {/* </div> */}
          
        </div>
      </form>
    </Card>
  );
};

export default UpsertTable;
