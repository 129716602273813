import React, { useState } from "react";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react";
import { useRef } from "react";

const TableItem = observer(({ data, openModal }) => {
  const targetDivRef = useRef(null);
  const { ordersStore } = useStore();
  const borderClassName =
    data.status === "E lirë"
      ? "border-green-300 hover:bg-green-50"
      : "bg-red-50 border-red-300 hover:bg-red-50";
  const [tableStatus, setTableStatus] = useState("DINE-IN");


  const handleTableSelect = () => {
    if (data.status === "E zënë") {
      var orderItems = ordersStore.getOrdersBasedOnTableId(data.tableId);
      if(orderItems.length >= 1)
        setTableStatus(orderItems[0].type);
      ordersStore.setCurrentSelectedTable(data.tableId, data.name);
      ordersStore.setCurrentOrderItemsStatus(orderItems, data.tableId);
    } else {
      ordersStore.setCurrentSelectedTable(data.tableId, data.name);
      ordersStore.clearCurrentOrderItemsStatus();
    }
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    
    // Call the openModal function passed from OrdersTable component
    openModal();

 

  };

  return (
    <div id={`tableItem_${data.tableId}`} ref={targetDivRef}>
      <button
        onClick={handleTableSelect}
        className={`w-full h-32 md:w-32 flex flex-col justify-center items-center gap-2 rounded-xl border border-2 ${borderClassName}`}
      >
        {data.status !== "E lirë" ? (
          <div>
            {tableStatus}
          </div>
        ): null}
          <h4 className="text-sm md:text-lg font-medium text-navy-700">{data.name}</h4>
          <p className="text-sm font-medium text-gray-700 md:text-base">{data.status}</p>
      </button>
    </div>
  );
});

export default TableItem;
