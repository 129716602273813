import React from "react";
import Dropdown from "components/dropdown";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function CardMenu(props) {
  const { transparent } = props;
  const [open, setOpen] = React.useState(false);
  const navigation = useNavigate();

  const handleAddManager = () => {
    navigation("/admin/manager-upsert");
  };
  return (
        <div className="w-max rounded-xl bg-white py-3 px-4 text-sm cursor-pointer bg-gray-100 hover:bg-gray-300">
          <p
            className="hover:text-black flex items-center gap-2 text-gray-800"
            onClick={() => handleAddManager()}
          >
            <span>
              <AiOutlinePlus />
            </span>
            Regjistro menaxher
          </p>
        </div>
  );
}

export default CardMenu;
