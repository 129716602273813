import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const customStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: "48px",
    borderRadius: "0.72rem",
    marginTop: "2px",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: "120px",
  }),
};

const Multiselect = ({ options, setSelectedExtraProducts, defaultValue, onChange }) => {
  let optionsFormated = [];
  const [selectedOptions, setSelectedOptions] = useState([]);
  if (options) {
    optionsFormated = options.map((extraProduct) => ({
      value: extraProduct.extraProductId,
      label: extraProduct.name,
    }));
  }

  useEffect(() => {
    if (defaultValue) {
      const formattedOptions = defaultValue.map((option) => ({
        value: option.value,
        label: option.label,
      }));
      setSelectedOptions(formattedOptions);
      setSelectedExtraProducts(formattedOptions);
    } else {
      setSelectedOptions([]);
    }
  }, [defaultValue]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedExtraProducts(selectedOptions);
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <div className="mt-2">
      <Select
        components={animatedComponents}
        options={optionsFormated}
        isMulti
        onChange={handleSelectChange}
        styles={customStyles}
        classNames={{
          control: (base) =>
            `${base}`,
          menuList: (base) => `${base} `,
          option: (state) =>
            state.isFocused ?  "lightblue" :""
        }}
        value={selectedOptions}
      />
    </div>
  );
};

export default Multiselect;
