export const supplierColumns = [
  {
    Header: "EMRI",
    accessor: "name",
  },
  {
    Header: "ADRESA",
    accessor: "address",
  },
  {
    Header: "NR.TEL",
    accessor: "phoneNumber",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "MANAGER",
    accessor: "manager",
  },
  {
    Header: "EMRI I LLOGARISË",
    accessor: "accountName",
  },
  {
    Header: "NUMRI I LLOGARISË",
    accessor: "accountNumber",
  },
  {
    Header: "NUMRI UNIK I BIZNESIT",
    accessor: "nub",
  },
];
