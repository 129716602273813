import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const customStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: "48px",
    borderRadius: "0.72rem",
    marginTop: "2px",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: "120px",
  }),
};

const Multiselect = ({
  options,
  setSelectedProducts,
  defaultValue,
  productType,
}) => {
  let optionsFormated = [];
  const [selectedOptions, setSelectedOptions] = useState([]);
  if (options) {
    optionsFormated = options.map((product) => ({
      value: product.foodId || product.drinkId || product.dessertId,
      label: product.name,
    }));
  }

  useEffect(() => {
    if (defaultValue) {
      setSelectedOptions(defaultValue);
      setSelectedProducts(defaultValue);
    } else {
      setSelectedOptions([]);
    }
  }, [defaultValue]);

  const handleSelectChange = (selectedOptions) => {
    let optionsFormated = selectedOptions.map((product) => ({
      value: product.value,
      label: product.label,
    }));
    setSelectedProducts(optionsFormated);
    setSelectedOptions(selectedOptions);
  };

  return (
    <div className="mt-2">
      <Select
        components={animatedComponents}
        options={optionsFormated}
        isMulti
        onChange={handleSelectChange}
        styles={customStyles}
        classNames={{
          control: (base) =>
            `${base}`,
          menuList: (base) => `${base}`,
          option: (state) =>
            state.isFocused ? "lightblue" : "lightblue",
        }}
        value={selectedOptions}
      />
    </div>
  );
};

export default Multiselect;
