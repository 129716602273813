import React, { useState, useEffect } from "react";
import Card from "components/card";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiManager } from "app/ApiManager";
import { MdOutlineEdit } from "react-icons/md";

const StockItemDetails = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { stockId, stockType } = location.state;
  const [stock, setStock] = useState({});
  useEffect(() => {
    let endpoint = "";
    if (stockType === "BarStock") {
      endpoint = `/BarStock/GetBarStockById/${stockId}`;
    } else if (stockType === "KitchenStock") {
      endpoint = `/KitchenStock/GetKitchenStockById/${stockId}`;
    }

    if (endpoint) {
      ApiManager.get(endpoint)
        .then((response) => {
          setStock(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleEdit = () => {
    navigation("/admin/stock-upsert", {
      state: {
        stockId: stockId,
        stockType: stockType,
      },
    });
  };
  return (
    <>
      <Card extra={"w-full h-full p-3 mt-5"}>
        {/* Header */}
        <div className="w-full flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <h4 className="px-2 text-xl font-bold text-navy-700">
              {stock.itemName}
            </h4>
            <button
              onClick={handleEdit}
              className="!linear z-[1] flex items-center justify-center gap-2 rounded-lg
              bg-lightPrimary px-3 py-2 text-brand-500 !transition !duration-200 
              hover:bg-gray-100 active:bg-gray-200"
            >
              Përditëso
              <MdOutlineEdit className="h-5 w-5" />
            </button>
          </div>
          <p className="px-2 text-base text-gray-700">
            {stock.description}
          </p>
        </div>
        {/* Cards */}
        <div className="grid md:grid-cols-2 gap-4 mt-5">
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Emërtimi</p>
            <p className="text-base font-medium text-navy-700">
              {stock.itemName}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Çmimi</p>
            <p className="text-base font-medium text-navy-700">
              {stock.pricePerUnit} €
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Sasia</p>
            <p className="text-base font-medium text-navy-700">
              {stock.quantity}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">Njësia matëse</p>
            <p className="text-base font-medium text-navy-700">
              {stock.unitOfMeasure}
            </p>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500">
            <p className="text-sm text-gray-800">
              Niveli i sasisë minimale të përcaktuar
            </p>
            <p className="text-base font-medium text-navy-700">
              {stock.reorderLevel}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
};

export default StockItemDetails;
