export const validate = (values) => {
  const requiredErrorMessage = "Kjo fushë është e kërkuar!";
  const errors = {};
  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 4 || values.name.length > 40) {
    errors.name = "Numri i karaktereve duhet te jetë mes 3 dhe 40!";
  }
  if (values.description.length < 10 || values.description.length > 150) {
    errors.description = "Numri i karaktereve duhet te jetë mes 10 dhe 150!";
  }
  if (!values.price) {
    errors.price = requiredErrorMessage;
  } else if (values.price < 0) {
    errors.price = "Çmimi nuk mund të jetë më i vogel se zero!";
  } else if (!/^[0-9.]+$/.test(values.price)) {
    errors.price = "Vlera e shënuar duhet të përmbajë vetëm numra!";
  }
  return errors;
};
