import React from "react";
import { observer } from "mobx-react";
import { useStore } from "app/stores/store";
import { MdClear, MdOutlineAdd, MdOutlineRemove } from "react-icons/md";

const OnlineItem = observer(({ cartItem, businessId, setForceRender, forceRender, changeChanged }) => {
  const { cartStore } = useStore();
  const { id, productType } = cartItem;

  const handleIncrement = () => {
    cartStore.incrementQuantity(businessId, id, productType);
    setForceRender(!forceRender);
  };

  const handleDecrement = () => {
    cartStore.decrementQuantity(businessId, id, productType);
    setForceRender(!forceRender);
  };

  const handleDelete = () => {
    cartStore.deleteCartItem(businessId, productType, id);
    changeChanged(prev => prev + 1);
  };

  return (
    <div className="my-2 flex items-center justify-between rounded-md border border-gray-300 p-1 md:p-2 md:flex-row">
      <div className="mb-2 flex items-center md:mb-0 md:mr-4">
        <p className="text-sm md:text-md font-medium text-gray-800">{cartItem.name}</p>
      </div>
      <div className="flex gap-3 lg:gap-5">
        <div className="flex items-center gap-1">
          <button
            type="button"
            onClick={handleDecrement}
            className="focus:outline-none"
          >
            <span className="text-yellow-500">
              <MdOutlineRemove className="md:h-6 md:w-6" />
            </span>
          </button>
          <span className="text-sm md:text-lg">{cartItem.quantity}</span>
          <button
            type="button"
            onClick={handleIncrement}
            className="focus:outline-none"
          >
            <span className="text-yellow-500">
              <MdOutlineAdd className="md:h-6 md:w-6" />
            </span>
          </button>
        </div>
        <button
          type="button"
          onClick={handleDelete}
          className="focus:outline-none"
        >
          <span className="text-red-600">
            <MdClear className="md:h-6 md:w-6" />
          </span>
        </button>
      </div>
    </div>
  );
});

export default OnlineItem;
